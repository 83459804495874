import { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import { matchPath, useLocation } from 'react-router';

import { RoutesLink } from '@socialbrothers/constants';

function useBreadcrumbs(routes: RoutesLink[], title?: string) {
	const [breadcrumbs, setBreadcrumbs] = useState<RoutesLink[]>([]);
	const location = useLocation();

	useEffect(() => {
		const crumbs = cloneDeep(routes).reduce<RoutesLink[]>((total, route: RoutesLink) => {
			const path = matchPath(location.pathname, { path: route.path });

			if (path) {
				for (const [key, value] of Object.entries(path.params)) {
					route.path = (route.path || '').replace(`:${key}`, '' + value);
				}

				total.push({ ...path, ...route });
			}

			return total;
		}, []);

		if (title) crumbs[crumbs.length - 1].label = title;

		setBreadcrumbs(crumbs);
	}, [location.pathname, title, routes]);

	return breadcrumbs;
}

export default useBreadcrumbs;
