import { cloneElement } from 'react';
import cn from 'classnames';

import { BaseActionFieldProps } from './BaseActionField.props';
import styles from './BaseActionField.module.scss';

const BaseActionField = ({ record, children, className, ...props }: BaseActionFieldProps) => {
	return (
		<div className={cn([styles.Actions, className])} {...props}>
			{children &&
				[].concat(children).map((child: JSX.Element, key: number) => {
					return cloneElement(child, { key, record });
				})}
		</div>
	);
};

BaseActionField.displayName = 'BaseActionField';

export default BaseActionField;
