import { useState } from 'react';

function useToggle(initialValue: boolean) {
	const [isToggle, setIsToggle] = useState(initialValue);

	const toggle = () => setIsToggle((value) => !value);
	const setToggle = (value: boolean) => setIsToggle(value);

	return {
		toggle,
		isToggle,
		setToggle,
	};
}

export default useToggle;
