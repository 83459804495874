import { BaseModel } from '@socialbrothers/constants';
import { ResourceService } from '@socialbrothers/services';

import { IUser, MenselyService } from '@Services/index';
import { IQuestionnaire } from './QuestionnaireService';
import { ICourse } from './CourseService';
import { downloadFile, getFilenameFromContentDisposition } from '@Root/socialbrothers/utils';

export enum COMPANY_MODULES {
	LNI = 'lni',
	AOM = 'aom',
	OOM = 'oom',
	PNI = 'pni',
}

export interface ICompanyModules extends BaseModel {
	name: string;
	slug: COMPANY_MODULES;
}

export interface ICompanyAOMSettings extends BaseModel {
	company_id: number;
	consecutive_sick_days: number;
	employee_questionnaire_id: number;
	external_service_type: number;
	file_lifetime: number;
	id: number;
	supervisor_questionnaire_id: number;
	survey_expire_days: number;
	survey_reminder_interval: number;
}

export interface ICompanyPNISettings extends BaseModel {
	vos_enabled: boolean;
	vos_questionnaire_id: number;
}

export interface ICompanyOOMSettings extends BaseModel {
	company_id: number;
	survey_expire_days: number;
}

export interface ICompanyOOMRoles extends BaseModel {
	company_id: number;
	oom_role_id: number;
	questionnaire: IQuestionnaire;
	questionnaire_id: string;
	role: ICompanyOOMRole;
}

export interface ICompanyOOMRole extends BaseModel {
	name: string;
	slug: string;
	base_questionnaire: IQuestionnaire;
}

export interface ICompanyLNISettings extends BaseModel {
	moments_enabled: boolean;
	advisor_enabled: boolean;
	mail_welcome_intro: string;
}

export interface ICompanyLNIAccess extends BaseModel {
	company_assignment: any;
	company_assignment_id: number;
	company_id: number;
	course: ICourse;
	course_id: number;
	trainer_email: string;
}

export interface ICompanyLNITeam extends BaseModel {
	licence_code?: string;
	name: string;
	team_access: ICompanyLNITeamAccess[];
	users: IUser[];
}

export interface ICompanyLNITeamAccess extends BaseModel {
	access_id: number;
	access: ICompanyLNIAccess;
	mail_intro: string;
	mail_body: string;
	mail_outro: string;
}

export interface ICompanyPermissions {
	aom: boolean;
	lni: boolean;
	lni_settings: boolean;
	oom: boolean;
	pni: boolean;
}

export interface ICompany extends BaseModel {
	name: string;
	slug: string;
	access: ICompanyLNIAccess[];
	teams: ICompanyLNITeam[];
	logo_path: string;
	modules: ICompanyModules[];
	company_doctor?: IUser;
	advisor?: IUser;
	pob?: IUser;
	account_manager?: IUser;
	lni_settings?: ICompanyLNISettings;
	contacts?: ICompanyContact[];
	aom_settings?: ICompanyAOMSettings;
	oom_settings?: ICompanyOOMSettings;
	pin_settings?: ICompanyPNISettings;
	oom_roles?: ICompanyOOMRoles[];
	permissions: ICompanyPermissions;
}

export interface ICompanyContact extends BaseModel {
	first_name: string;
	last_name: string;
	email: string;
	phone_number: string;
	function: string;
	avatar: string;
}

export interface CompanyInviteSupervisorsRequest {
	mail_body: string;
	emails: string[];
}

export class CompanyServiceImplementation extends ResourceService<ICompany> {
	createModule = async (companyId: number, moduleId: number) => {
		const params = {
			module_id: moduleId,
		};

		const response = await this.client.post<ICompany>(
			`${this.endpoint}/${companyId}/modules`,
			params,
		);

		return response.data;
	};

	updateModule = async (companyId: number, moduleId: number, params: any) => {
		const response = await this.client.putWithPost<ICompany>(
			`${this.endpoint}/${companyId}/modules/${moduleId}`,
			params,
		);

		return response.data;
	};

	deleteModule = async (companyId: number, moduleId: number) => {
		const params = {
			module_id: moduleId,
		};

		const response = await this.client.delete<ICompany>(
			`${this.endpoint}/${companyId}/modules`,
			params,
		);

		return response.data;
	};

	createRole = async (companyId: number, values: any) => {
		const response = await this.client.post<ICompany>(
			`${this.endpoint}/${companyId}/oomroles`,
			values,
		);

		return response.data;
	};

	updateRole = async (companyId: number, oomCompanyRoleId: number, values: any) => {
		const response = await this.client.putWithPost<ICompany>(
			`${this.endpoint}/${companyId}/oomroles/${oomCompanyRoleId}`,
			values,
		);

		return response.data;
	};

	deleteRole = async (companyId: number, roleId: number) => {
		const response = await this.client.delete<ICompany>(
			`${this.endpoint}/${companyId}/oomroles/${roleId}`,
		);

		return response.data;
	};

	createCourse = async (companyId: number, values: any) => {
		const response = await this.client.post<ICompany>(
			`${this.endpoint}/${companyId}/courses`,
			values,
		);

		return response.data;
	};

	updateCourse = async (companyId: number, accessId: number, values: any) => {
		const response = await this.client.putWithPost<ICompany>(
			`${this.endpoint}/${companyId}/courses/${accessId}`,
			values,
		);

		return response.data;
	};

	deleteCourse = async (companyId: number, accessId: number) => {
		const response = await this.client.delete<ICompany>(
			`${this.endpoint}/${companyId}/courses/${accessId}`,
		);

		return response.data;
	};

	createTeamInvite = async (companyId: number, teamId: number, emails: string[]) => {
		const response = await this.client.post<ICompany>(
			`${this.endpoint}/${companyId}/teams/${teamId}/invite`,
			{
				emails,
			},
		);

		return response.data;
	};

	createTeam = async (companyId: number, values: any) => {
		const response = await this.client.post<ICompany>(
			`${this.endpoint}/${companyId}/teams`,
			values,
		);

		return response.data;
	};

	updateTeam = async (companyId: number, userId: number, values: any) => {
		const response = await this.client.putWithPost<ICompany>(
			`${this.endpoint}/${companyId}/teams/${userId}`,
			values,
		);

		return response.data;
	};

	deleteTeam = async (companyId: number, userId: number) => {
		const response = await this.client.delete<ICompany>(
			`${this.endpoint}/${companyId}/teams/${userId}`,
		);

		return response.data;
	};

	createTeamAccessInvite = async (companyId: number, teamId: number, teamAccessId: number) => {
		const response = await this.client.post<ICompany>(
			`${this.endpoint}/${companyId}/teams/${teamId}/access/${teamAccessId}/invite`,
		);

		return response.data;
	};

	createTeamAccess = async (companyId: number, teamId: number, values: any) => {
		const response = await this.client.post<ICompany>(
			`${this.endpoint}/${companyId}/teams/${teamId}/access`,
			values,
		);

		return response.data;
	};

	updateTeamAccess = async (companyId: number, teamId: number, accessId: number, values: any) => {
		const response = await this.client.putWithPost<ICompany>(
			`${this.endpoint}/${companyId}/teams/${teamId}/access/${accessId}`,
			values,
		);

		return response.data;
	};

	deleteTeamAccess = async (companyId: number, teamId: number, accessId: number) => {
		const response = await this.client.delete<ICompany>(
			`${this.endpoint}/${companyId}/teams/${teamId}/access/${accessId}`,
		);

		return response.data;
	};

	deleteTeamUser = async (companyId: number, teamId: number, userId: number) => {
		const response = await this.client.delete<ICompany>(
			`${this.endpoint}/${companyId}/teams/${teamId}/users/${userId}`,
		);

		return response.data;
	};

	exportQuestionnaire = async (companyId: number, values: any) => {
		const response = await this.client.post<any>(
			`${this.endpoint}/${companyId}/export/questionnaires`,
			values,
		);

		const fileName = getFilenameFromContentDisposition(response.headers['content-disposition']);
		fileName && downloadFile(fileName, response.data);

		return response.data;
	};

	createContact = async (companyId: number, values: any) => {
		const response = await this.client.post<ICompany>(
			`${this.endpoint}/${companyId}/contacts`,
			values,
		);

		return response.data;
	};

	updateContact = async (companyId: number, userId: number, values: any) => {
		const response = await this.client.putWithPost<ICompany>(
			`${this.endpoint}/${companyId}/contacts/${userId}`,
			values,
		);

		return response.data;
	};

	deleteContact = async (companyId: number, userId: number) => {
		const response = await this.client.delete<ICompany>(
			`${this.endpoint}/${companyId}/contacts/${userId}`,
		);

		return response.data;
	};

	inviteSupervisors = async (companyId: number, data: CompanyInviteSupervisorsRequest) => {
		await this.client.post<void>(`${this.endpoint}/${companyId}/supervisors/invite`, data);
	};
}

export const CompanyService = new CompanyServiceImplementation(MenselyService, '/admin/companies');
