import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { useResource } from '@socialbrothers/hooks';
import { Page } from '@socialbrothers/components/Layout';
import { isCreate } from '@socialbrothers/utils';
import { Card } from '@socialbrothers/components/UI';

import { QUESTIONNAIRE_TYPE, QuestionService } from '@Services/index';
import { QuestionForm } from '@Components/forms';
import { QuestionAnswers } from '@Components/modules';
import { shouldShowAnswers } from '@Utils/index';

export const AOMQuestionDetailPage = () => {
	const { t } = useTranslation();
	const { id } = useParams<any>();
	const { data } = useResource(QuestionService, id);

	const label = data?.title || t('QUESTION.SINGLE');

	return (
		<Page title={label}>
			<Card title={label}>
				<QuestionForm module={QUESTIONNAIRE_TYPE.AOM} />
			</Card>

			{!isCreate(id) && data?.type && shouldShowAnswers(data.type) && (
				<QuestionAnswers
					module={QUESTIONNAIRE_TYPE.AOM}
					answers={data?.answers || []}
					questionId={id}
				/>
			)}
		</Page>
	);
};
