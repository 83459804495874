import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import config from '@Config';

import { AuthWrapperProps } from './AuthWrapper.props';
import styles from './AuthWrapper.module.scss';

const AuthWrapper = ({ title, children, imageUri, ...props }: AuthWrapperProps) => {
	const { t } = useTranslation();

	return (
		<div className={styles.AuthWrapper} {...props}>
			<div className="container">
				<div className={cn('card', styles.Wrapper)}>
					<div className={styles.Column}>
						<img src={imageUri} className={cn(styles.Image, 'fill')} alt="Login" />
						<img src={config.logo} className={styles.Logo} alt={t('GLOBAL.SITE_NAME')} />
					</div>

					<div className={styles.Column}>
						<div className={styles.Body}>
							<h1 className={cn('h1', 'text-center', 'mb-4')}>{title}</h1>
							{children}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AuthWrapper;
