import config from '@Config';
import { ThemeProvider, useSentry } from '@socialbrothers/hooks';
import './App.scss';

import { AccountService, AuthService } from '@Services/index';
import { RootNavigator } from './routes';

export const context = {
	authService: AuthService,
	accountService: AccountService,
	config: config,
};

const App = () => {
	useSentry();

	return (
		<ThemeProvider context={context}>
			<RootNavigator />
		</ThemeProvider>
	);
};

export default App;
