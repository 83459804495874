import { ResourceService } from '@socialbrothers/services';
import MenselyService from './MenselyService';
import { IQuestionnaire } from './QuestionnaireService';

export type IAliiQuestionnaire = IQuestionnaire;

export class AliiQuestionnaireServiceImplementation extends ResourceService<IAliiQuestionnaire> {}

export const AliiQuestionnaireService = new AliiQuestionnaireServiceImplementation(
	MenselyService,
	'/admin/alii_questionnaires',
);
