import { forwardRef, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import styles from './MultilineInput.module.scss';
import { MultilineInputProps } from './MultilineInput.props';

const MultilineInput = ({ ...props }: MultilineInputProps, ref?: any) => {
	const { register, setValue } = useFormContext();

	useEffect(() => {
		setValue(props.name, props.defaultValue);
	}, [setValue, props.name, props.defaultValue]);

	return <textarea className={styles.MultilineInput} ref={register()} {...props} />;
};

export default forwardRef(MultilineInput);
