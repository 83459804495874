import { Service, ChildResourceService, ResourceService } from '@socialbrothers/services';
import { BaseModel } from '@socialbrothers/constants';

import MenselyService from './MenselyService';
import { IQuestion } from './QuestionService';
import { ITheme } from './ThemeService';
import { StepContentFormFormProps } from '@Components/modules/QuestionnaireConfigurator/internal/StepForm/StepForm.props';

export enum QUESTIONNAIRE_TYPE {
	AOM = 'AOM', // Advies op Maat
	LNI = 'LNI', // Leiderschap en Inzetbaarheid
	ONB = 'ONB', // Onboarding
	OOM = 'OOM', // Organisatieadvies op Maat
}

export enum QUESTIONNAIRE_FOR {
	FOR_SUPERVISOR = 'SUPERVISOR',
	FOR_EMPLOYEE = 'EMPLOYEE',
	FILE_MANAGER = 'FILE_MANAGER',
}

export enum QUESTIONNAIRE_SECTION_STEP_EFFECT {
	STEP = 'STEP',
	SECTION = 'SECTION',
	END = 'END',
}

export enum QUESTIONNAIRE_SECTION_STEP_TYPE {
	QUESTION = 'QUESTION',
	SCORE = 'SCORE',
	CONTENT = 'CONTENT',
}
export interface IQuestionnaire extends BaseModel {
	title: string;
	text: string;
	type: QUESTIONNAIRE_TYPE;
	show_section_progress: boolean;
	show_score_summary: boolean;
	for: QUESTIONNAIRE_FOR;
	is_clone: boolean;
	allow_download: boolean;
	thanks_text: string;
	information: string;
	publishable: boolean;
	published: boolean;
	sections: IQuestionnaireSection[];
	additional_questionnaires: IQuestionnaireAdditional[];
	employee_mail_intro: string;
	employee_mail_body: string;
	employee_mail_outro: string;
}

export interface IQuestionnaireSection extends BaseModel {
	title: string;
	icon: any;
	questionnaire_id: number;
	order: number;
	steps: IQuestionnaireSectionStep[];
}

export interface IQuestionnaireSectionStep extends BaseModel {
	order: number;
	questionnaire_question_id: number;
	questionnaire_section_id: number;
	question: IQuestion;
	section: IQuestionnaireSection;
	effects: IQuestionnaireSectionStepEffect[];
	type: QUESTIONNAIRE_SECTION_STEP_TYPE;
	has_in_depth_questions: boolean;
	in_depth_theme_id: number;
	in_depth_minimum_score: number;
	score_thresholds: IQuestionnaireSectionStepThreshold[];
}

export interface IQuestionnaireSectionStepThreshold extends BaseModel {
	min: number;
	max: number;
	text: string;
	theme_id: number;
	theme: ITheme;
}

export interface IQuestionnaireAdditional extends BaseModel {
	theme_id: number;
	theme: ITheme;
	min_threshold: number;
	max_threshold: number;
	additional_questionnaire_id: number;
	additional_questionnaire: IQuestionnaire;
}

export interface IQuestionnaireSectionStepEffect extends BaseModel {
	next_section?: any;
	next_section_id?: number;
	next_step?: any;
	next_step_id?: number;
	questionnaire_answer_id?: number;
	questionnaire_step_id: number;
	type: QUESTIONNAIRE_SECTION_STEP_EFFECT;
}

export class QuestionnaireServiceImplementation extends ResourceService<IQuestionnaire> {
	getOnboarding = async () => {
		const response = await this.client.get<IQuestionnaire>(`${this.endpoint}/onboarding`);

		return response.data;
	};

	duplicate = async (questitonnaire: IQuestionnaire, title: string) => {
		const response = await this.client.post<IQuestionnaire>(
			`${this.endpoint}/${questitonnaire.id}/duplicate`,
			{
				title: title,
			},
		);

		return response.data;
	};

	publish = async (questitonnaireId: number) => {
		const response = await this.client.post<IQuestionnaire>(
			`${this.endpoint}/${questitonnaireId}/publish`,
		);

		return response.data;
	};
}

export const QuestionnaireService = new QuestionnaireServiceImplementation(
	MenselyService,
	'/admin/questionnaires',
);

export class QuestionnaireSectionServiceImplementation extends ChildResourceService<
	IQuestionnaireSection,
	IQuestionnaire
> {
	moveUp = async (questitonnaireId: number, sectionId: number) => {
		const response = await this.client.post<IQuestionnaireSection>(
			`${this.parentEndpoint}/${questitonnaireId}/${this.endpoint}/${sectionId}/up`,
		);

		return response.data;
	};

	moveDown = async (questitonnaireId: number, sectionId: number) => {
		const response = await this.client.post<IQuestionnaireSection>(
			`${this.parentEndpoint}/${questitonnaireId}/${this.endpoint}/${sectionId}/down`,
		);

		return response.data;
	};
}

export const QuestionnaireSectionService = new QuestionnaireSectionServiceImplementation(
	MenselyService,
	'/admin/questionnaires',
	'sections',
);

export class QuestionnaireSectionStepServiceImplementation extends Service {
	childEndpoint = 'sections';

	create = async (questitonnaireId: number, sectionId: number, values: any) => {
		const response = await this.client.post<IQuestionnaireSectionStep>(
			`${this.endpoint}/${questitonnaireId}/${this.childEndpoint}/${sectionId}/steps`,
			values,
		);

		return response.data;
	};

	delete = async (questitonnaireId: number, sectionId: number, stepId: number) => {
		const response = await this.client.delete<IQuestionnaireSectionStep>(
			`${this.endpoint}/${questitonnaireId}/${this.childEndpoint}/${sectionId}/steps/${stepId}`,
		);

		return response.data;
	};

	update = async (questitonnaireId: number, sectionId: number, stepId: number, values: any) => {
		const response = await this.client.putWithPost<IQuestionnaireSectionStep>(
			`${this.endpoint}/${questitonnaireId}/${this.childEndpoint}/${sectionId}/steps/${stepId}`,
			values,
		);

		return response.data;
	};

	updateEffect = async (
		questitonnaireId: number,
		sectionId: number,
		stepId: number,
		values: any,
	) => {
		return Promise.all(
			values.effects.map((value: any) => {
				return this.client.post<IQuestionnaireSectionStep>(
					`${this.endpoint}/${questitonnaireId}/${this.childEndpoint}/${sectionId}/steps/${stepId}/effects/${value.id}`,
					value,
				);
			}),
		);
	};

	moveUp = async (questitonnaireId: number, sectionId: number, stepId: number) => {
		const response = await this.client.post<IQuestionnaireSection>(
			`${this.endpoint}/${questitonnaireId}/${this.childEndpoint}/${sectionId}/steps/${stepId}/up`,
		);

		return response.data;
	};

	moveDown = async (questitonnaireId: number, sectionId: number, stepId: number) => {
		const response = await this.client.post<IQuestionnaireSection>(
			`${this.endpoint}/${questitonnaireId}/${this.childEndpoint}/${sectionId}/steps/${stepId}/down`,
		);

		return response.data;
	};

	createScore = async (questitonnaireId: number, sectionId: number, values: any) => {
		const response = await this.client.post<IQuestionnaireSectionStep>(
			`${this.endpoint}/${questitonnaireId}/${this.childEndpoint}/${sectionId}/steps/score`,
			values,
		);

		return response.data;
	};

	updateScore = async (
		questitonnaireId: number,
		sectionId: number,
		stepId: number,
		values: any,
	) => {
		const response = await this.client.putWithPost<IQuestionnaireSectionStep>(
			`${this.endpoint}/${questitonnaireId}/${this.childEndpoint}/${sectionId}/steps/${stepId}`,
			values,
		);

		return response.data;
	};

	createContent = async (
		questitonnaireId: number,
		sectionId: number,
		values: StepContentFormFormProps,
	) => {
		const response = await this.client.post<IQuestionnaireSectionStep>(
			`${this.endpoint}/${questitonnaireId}/${this.childEndpoint}/${sectionId}/steps/content`,
			values,
		);

		return response.data;
	};

	updateContent = async (
		questitonnaireId: number,
		sectionId: number,
		stepId: number,
		values: any,
	) => {
		const response = await this.client.putWithPost<IQuestionnaireSectionStep>(
			`${this.endpoint}/${questitonnaireId}/${this.childEndpoint}/${sectionId}/steps/${stepId}`,
			values,
		);

		return response.data;
	};

	createScoreThreshold = async (
		questitonnaireId: number,
		sectionId: number,
		stepId: number,
		values: any,
	) => {
		const response = await this.client.post<IQuestionnaireSectionStep>(
			`${this.endpoint}/${questitonnaireId}/${this.childEndpoint}/${sectionId}/steps/${stepId}/thresholds`,
			values,
		);

		return response.data;
	};

	updateScoreThreshold = async (
		questitonnaireId: number,
		sectionId: number,
		stepId: number,
		thresholdId: number,
		values: any,
	) => {
		const response = await this.client.putWithPost<IQuestionnaireSectionStep>(
			`${this.endpoint}/${questitonnaireId}/${this.childEndpoint}/${sectionId}/steps/${stepId}/thresholds/${thresholdId}`,
			values,
		);

		return response.data;
	};

	deleteScoreThreshold = async (
		questitonnaireId: number,
		sectionId: number,
		stepId: number,
		thresholdId: number,
	) => {
		const response = await this.client.delete<IQuestionnaireSectionStep>(
			`${this.endpoint}/${questitonnaireId}/${this.childEndpoint}/${sectionId}/steps/${stepId}/thresholds/${thresholdId}`,
		);

		return response.data;
	};
}

export const QuestionnaireSectionStepService = new QuestionnaireSectionStepServiceImplementation(
	MenselyService,
	'/admin/questionnaires',
);

export class QuestionnaireAdditionalServiceImplementation extends ChildResourceService<
	IQuestionnaireAdditional,
	IQuestionnaire
> {}

export const QuestionnaireAdditionalService = new QuestionnaireAdditionalServiceImplementation(
	MenselyService,
	'/admin/questionnaires',
	'additional',
);
