import { useTranslation } from 'react-i18next';

import { Page } from '@socialbrothers/components/Layout';

import styles from './NotFound.module.scss';

const NotFoundPage = () => {
	const { t } = useTranslation();
	return (
		<Page breadcrumbs={false}>
			<div className={styles.NotFound}>
				<div className={styles.Title}>{t('GLOBAL.404.TITLE')}</div>
				<div className={styles.Subtitle}>{t('GLOBAL.404.SUBTITLE')}</div>

				<p>{t('GLOBAL.404.DESCRIPTION')}</p>
			</div>
		</Page>
	);
};

export default NotFoundPage;
