import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';

import { useEscape } from '@socialbrothers/hooks/index';

import styles from './Modal.module.scss';
import { ModalProps } from './Modal.props';

const Modal = ({ title, isVisible, onClose, children, ...props }: ModalProps) => {
	useEscape(onClose && isVisible ? onClose : () => undefined);

	return ReactDOM.createPortal(
		<div {...props}>
			<CSSTransition unmountOnExit={true} in={isVisible} timeout={200} classNames="fade">
				<div className={styles.Overlay} />
			</CSSTransition>

			<CSSTransition unmountOnExit={true} in={isVisible} timeout={200} classNames="slideTop">
				<div className={styles.Wrapper}>
					<div className={styles.Modal}>
						<div className={styles.Header}>
							<h3 className="mb-0">{title}</h3>

							{onClose && (
								<div className={styles.Close} onClick={onClose}>
									<i className="far fa-times" />
								</div>
							)}
						</div>

						<div className={styles.Body}>{children}</div>
					</div>
				</div>
			</CSSTransition>
		</div>,
		document.body,
	);
};

export default Modal;
