import { createContext, useContext } from 'react';
import { Instance } from 'mobx-state-tree';

import { TableStoreInstance } from '@socialbrothers/stores/Table/TableStore';

export interface TableInstance {
	fields: any[];
	data: any[];
	filter: TableStoreInstance;
	service?: any;
}

export const TableContext = createContext<null | TableInstance>(null);
export const TableProvider = TableContext.Provider;

export function useTable(): Instance<TableInstance> {
	const context = useContext(TableContext);

	if (context === null) {
		throw new Error('TableContext cannot be null, please add a context provider');
	}

	return context;
}
