import { get } from 'lodash';
import { useQueryClient, useMutation } from 'react-query';
import { forwardRef, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Form, Resource } from '@socialbrothers/components/Containers';
import { useResource } from '@socialbrothers/hooks';
import { Spinner } from '@socialbrothers/components/UI';
import { getMainRoute, isCreate, serialize } from '@socialbrothers/utils';

import styles from './ResourceForm.module.scss';
import { ResourceFormProps } from './ResourceForm.props';

const ResourceForm = (
	{
		id,
		service,
		submitLabel,
		deleteConfig,
		children,
		validationSchema,
		label,
		preSubmitFormatter,
		afterFetchFormatter,
		onDataFetched,
		onError,
		onSuccess,
	}: ResourceFormProps,
	ref: any,
) => {
	const queryClient = useQueryClient();
	const query = useResource(service, id);
	const { t } = useTranslation();
	const history = useHistory();

	const getData = useCallback(() => {
		if (afterFetchFormatter) {
			return afterFetchFormatter(query.data);
		}

		return query.data;
	}, [afterFetchFormatter, query.data]);

	useEffect(() => {
		if (query.data && onDataFetched) {
			onDataFetched(query.data);
		}
	}, [onDataFetched, query.data]);

	const mutateCreate = useMutation(service.create, {
		onSuccess: (response: any) => {
			queryClient.invalidateQueries(service.endpoint);

			if (response.id) {
				const currentPath = getMainRoute(history.location.pathname, -1);

				history.push(`/${currentPath}/${response.id}`);
			}

			toast.success(t('GLOBAL.CREATED_SUCCESSFUL', { name: label }));
			queryClient.invalidateQueries();

			onSuccess && onSuccess(response?.response?.data);
		},
		onError: (response: any) => {
			toast.error(t('GLOBAL.CREATED_UNSUCCESSFUL', { name: label }));

			onError && onError(response.response.data);
		},
	});

	const mutateUpdate = useMutation((values) => service.update(id as number, values), {
		onSuccess: (response: any) => {
			toast.success(t('GLOBAL.UPDATED_SUCCESSFUL', { name: label }));
			queryClient.invalidateQueries(service.endpoint);

			onSuccess && onSuccess(response?.response?.data);
		},
		onError: (response: any) => {
			toast.error(t('GLOBAL.UPDATED_UNSUCCESSFUL', { name: label }));

			onError && onError(response.response.data);
		},
	});

	const defaultSubmitLabel = isCreate(id) ? t('GLOBAL.CREATE') : t('GLOBAL.UPDATE');

	const handleSubmit = (values: any) => {
		const params = preSubmitFormatter ? preSubmitFormatter(values, !!id) : values;

		if (isCreate(id)) {
			return mutateCreate.mutateAsync(serialize(params));
		} else {
			return mutateUpdate.mutateAsync(serialize(params));
		}
	};

	return (
		<>
			{!query.isFetched && !isCreate(id) ? (
				<Spinner size={40} />
			) : (
				<Form.Base
					ref={ref}
					submitLabel={submitLabel || defaultSubmitLabel}
					initialValues={getData()}
					validationSchema={validationSchema}
					onSubmit={handleSubmit}>
					{children}

					{!isCreate(id) && id && deleteConfig && !deleteConfig.disabled && (
						<div className={styles.Delete}>
							<Resource.Delete
								id={id}
								hasLabel
								service={service}
								redirect={deleteConfig.redirect}
								name={get(getData(), deleteConfig.name)}
							/>
						</div>
					)}
				</Form.Base>
			)}
		</>
	);
};

export default forwardRef(ResourceForm);
