import { useQuery } from 'react-query';
import { RequestConfig } from '@socialbrothers/constants';
import { ResourceInterface } from '@socialbrothers/services';

export function useList<T>(service: ResourceInterface, filters?: RequestConfig<T>, enabled = true) {
	return useQuery([service.endpoint, JSON.stringify(filters)], () => {
		if (enabled) {
			return service.getList(filters as any);
		}
	});
}
