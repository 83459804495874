import { EmptyResourceService } from '@socialbrothers/services';
import { BaseModel } from '@socialbrothers/constants';

import MenselyService from './MenselyService';
import { IQuestionnaire } from './QuestionnaireService';

export interface IConfiguration extends BaseModel {
	vos_questionnaire: IQuestionnaire;
	vos_questionnaire_id: number;
}

export class AOMServiceImplementation extends EmptyResourceService {
	getConfiguration = async () => {
		const response = await this.client.get<IConfiguration>(`${this.endpoint}/configuration`);

		return response.data;
	};

	updateConfiguration = async (values: any) => {
		const response = await this.client.putWithPost<IConfiguration>(
			`${this.endpoint}/configuration`,
			values,
		);

		return response.data;
	};
}

export const AOMService = new AOMServiceImplementation(MenselyService, '/admin/aom');
