import { useTranslation } from 'react-i18next';

import { Card } from '@socialbrothers/components/UI';

import { ModulesCreate } from './internal/ModulesCreate/ModulesCreate';
import { ModulesTable } from './internal/ModulesTable/ModulesTable';

import { CompanyModulesProps } from './CompanyModules.props';

export const CompanyModules = ({ id }: CompanyModulesProps) => {
	const { t } = useTranslation();

	return (
		<Card title={t('COMPANY.MODULE.PLURAL')}>
			<ModulesCreate id={id} />
			<ModulesTable id={id} />
		</Card>
	);
};
