import * as Yup from 'yup';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import { Form } from '@socialbrothers/components/Containers';
import { enumToOptions } from '@socialbrothers/utils';
import { QuestionnaireService, QUESTIONNAIRE_FOR, QUESTIONNAIRE_TYPE } from '@Services/index';

import { QuestionnaireFormProps } from './QuestionnaireForm.props';

export const QuestionnaireForm = ({ questionnaireId, type, ...props }: QuestionnaireFormProps) => {
	const { id } = useParams<any>();
	const { t } = useTranslation();

	const validationSchema = Yup.object().shape({
		title: Yup.string().required(),
		text: Yup.string().required(),
		type: Yup.string().required(),

		for: type === QUESTIONNAIRE_TYPE.AOM ? Yup.string().required() : Yup.string(),
	});

	return (
		<Form.Resource
			service={QuestionnaireService}
			validationSchema={validationSchema}
			label={t('QUESTIONNAIRE.SINGLE')}
			deleteConfig={{
				redirect: `/questionnaires/${type.toLowerCase()}`,
				name: 'title',
			}}
			id={questionnaireId || id}>
			<Form.Layout.Field label={t('QUESTIONNAIRE.LABELS.TITLE')}>
				<Form.Input.Text name="title" />
			</Form.Layout.Field>

			<Form.Layout.Field label={t('QUESTIONNAIRE.LABELS.TEXT')}>
				<Form.Input.Text name="text" />
			</Form.Layout.Field>

			<Form.Layout.Field label={t('QUESTIONNAIRE.LABELS.INFORMATION')}>
				<Form.Input.RichText name="information" />
			</Form.Layout.Field>

			<Form.Layout.Field label={t('QUESTIONNAIRE.LABELS.THANKS_TEXT')}>
				<Form.Input.RichText name="thanks_text" />
			</Form.Layout.Field>

			<Form.Layout.Field label={t('QUESTIONNAIRE.LABELS.ALLOW_DOWNLOAD')}>
				<Form.Input.Checkbox
					name="allow_download"
					options={[
						{
							key: 1,
							value: '' + t('QUESTIONNAIRE.LABELS.ALLOW_DOWNLOAD_LABEL'),
						},
					]}
				/>
			</Form.Layout.Field>

			<Form.Layout.Field label={t('QUESTIONNAIRE.LABELS.SHOW_SECTION_PROGRESS')}>
				<Form.Input.Checkbox
					name="show_section_progress"
					options={[
						{
							key: 1,
							value: '' + t('QUESTIONNAIRE.LABELS.SHOW_SECTION_PROGRESS_LABEL'),
						},
					]}
				/>
			</Form.Layout.Field>

			<Form.Layout.Field label={t('QUESTIONNAIRE.LABELS.SHOW_SCORE_SUMMARY')}>
				<Form.Input.Checkbox
					name="show_score_summary"
					options={[
						{
							key: 1,
							value: '' + t('QUESTIONNAIRE.LABELS.SHOW_SCORE_SUMMARY_LABEL'),
						},
					]}
				/>
			</Form.Layout.Field>

			{type === QUESTIONNAIRE_TYPE.AOM && (
				<Form.Layout.Group label={t('GLOBAL.MODULES.AOM')}>
					<Form.Layout.Field label={t('QUESTIONNAIRE.LABELS.FOR')}>
						<Form.Input.Select
							name="for"
							options={enumToOptions(QUESTIONNAIRE_FOR, 'QUESTIONNAIRE_FOR')}
						/>
					</Form.Layout.Field>

					<Form.Layout.Field label={t('QUESTIONNAIRE.LABELS.IS_PREVENTIVE')}>
						<Form.Input.Checkbox
							name="is_preventive"
							options={[{ key: 1, value: String(t('QUESTIONNAIRE.LABELS.IS_PREVENTIVE_LABEL')) }]}
						/>
					</Form.Layout.Field>

					<Form.Layout.Field label={t('QUESTIONNAIRE.LABELS.EMPLOYEE_MAIL_INTRO')}>
						<Form.Input.RichText name="employee_mail_intro" />
					</Form.Layout.Field>

					<Form.Layout.Field label={t('QUESTIONNAIRE.LABELS.EMPLOYEE_MAIL_BODY')}>
						<Form.Input.RichText name="employee_mail_body" />
					</Form.Layout.Field>

					<Form.Layout.Field label={t('QUESTIONNAIRE.LABELS.EMPLOYEE_MAIL_OUTRO')}>
						<Form.Input.RichText name="employee_mail_outro" />
					</Form.Layout.Field>
				</Form.Layout.Group>
			)}

			<Form.Input.Hidden name="type" defaultValue={type} />
		</Form.Resource>
	);
};
