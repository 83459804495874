import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import { COLOR } from '@socialbrothers/constants';
import { useResource } from '@socialbrothers/hooks';
import { ButtonWithPopup, Table } from '@socialbrothers/components/Containers';

import {
	CompanyService,
	COMPANY_MODULES,
	ICompany,
	ICompanyModules,
	QUESTIONNAIRE_TYPE,
} from '@Services/index';

import { ModulesLNI } from '../ModulesLNI/ModulesLNI';

import { ModulesTableProps } from './ModulesTable.props';
import { ModulesPNI } from '../ModulesPNI/ModulesPNI';
import { ModulesAOM } from '../ModulesAOM/ModulesAOM';
import { ModulesOOM } from '../ModulesOOM/ModulesOOM';
import { ModulesOOMRoles } from '../ModulesOOMRoles/ModulesOOMRoles';
import { ModulesLNIAccess } from '../ModulesLNIAccess/ModulesLNIAccess';
import { ModulesLNITeam } from '../ModulesLNITeam/ModulesLNITeam';
import { ModulesExport } from '../ModulesExport/ModulesExport';
import { UserInviteForm } from '@Components/forms';

export const ModulesTable = ({ id }: ModulesTableProps) => {
	const { t } = useTranslation();
	const { data } = useResource(CompanyService, id);
	const queryClient = useQueryClient();

	const mutateDeleteModule = useMutation(
		(moduleId: number) => CompanyService.deleteModule(data.id, moduleId),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(CompanyService.endpoint);
				queryClient.invalidateQueries([CompanyService.endpoint, id]);

				toast.success(t('GLOBAL.DELETED_SUCCESSFUL', { name: t('COMPANY.MODULE.SINGLE') }));
			},
		},
	);

	return (
		<Table.Base<ICompany> data={data?.modules}>
			<Table.Field.Text label={t('COMPANY.MODULE.SINGLE')} source="name" />

			<Table.Field.BaseAction>
				<Table.Action.Field
					render={(record: ICompanyModules) => {
						switch (record.slug) {
							case COMPANY_MODULES.LNI:
								return (
									<>
										<UserInviteForm companyId={id} />

										<ModulesExport
											id={id}
											type={[QUESTIONNAIRE_TYPE.LNI, QUESTIONNAIRE_TYPE.ONB]}
										/>
										<ModulesLNITeam id={id} record={record} />
										<ModulesLNIAccess id={id} record={record} />
										<ModulesLNI id={id} record={record} />
									</>
								);
							case COMPANY_MODULES.AOM:
								return (
									<>
										<ModulesAOM id={id} record={record} />
									</>
								);
							case COMPANY_MODULES.PNI:
								return (
									<>
										<ModulesPNI id={id} record={record} />
									</>
								);
							case COMPANY_MODULES.OOM:
								return (
									<>
										<ModulesOOMRoles id={id} record={record} />
										<ModulesOOM id={id} record={record} />
									</>
								);
						}

						return <></>;
					}}
				/>

				<Table.Action.Field
					render={(record: ICompany) => {
						return (
							<ButtonWithPopup
								button={{
									icon: 'trash-alt',
									color: COLOR.DANGER,
								}}
								submit={{
									label: t('GLOBAL.DELETE'),
									color: COLOR.DANGER,
									onClick: () => {
										return mutateDeleteModule.mutateAsync(record.id);
									},
								}}
								popup={{
									title: record.name,
								}}>
								<p>
									{t('RESOURCE.DELETE.MODAL.DESCRIPTION', {
										name: record.name,
									})}
								</p>
							</ButtonWithPopup>
						);
					}}
				/>
			</Table.Field.BaseAction>
		</Table.Base>
	);
};
