import { useEffect } from 'react';
import * as Sentry from '@sentry/react';

export const useSentry = (): void => {
	useEffect(() => {
		if (process.env.NODE_ENV !== 'development') {
			Sentry.init({
				dsn: process.env.REACT_APP_SENTRY_URL,
				environment: process.env.NODE_ENV,
			});
		}
	}, []);
};

export default useSentry;
