import BaseService from './BaseService';
import { Service } from './Service';

export interface ChildResourceInterface {
	delete: (parentId: number, id: number) => Promise<any>;
	update: (parentId: number, id: number, values: Partial<any>) => Promise<any>;
	create: (parentId: number, data: Partial<any>) => Promise<any>;
}

export class ChildResourceService<T, X> extends Service implements ChildResourceInterface {
	parentEndpoint: string;

	constructor(baseService: BaseService, parentEndpoint: string, endpoint: string) {
		super(baseService, endpoint);
		this.parentEndpoint = parentEndpoint;
	}

	delete = async (parentId: number, id: number) => {
		const response = await this.client.delete<X>(
			`${this.parentEndpoint}/${parentId}/${this.endpoint}/${id}`,
		);

		return response.data;
	};

	update = async (parentId: number, id: number, data: Partial<T>) => {
		const response = await this.client.putWithPost<X>(
			`${this.parentEndpoint}/${parentId}/${this.endpoint}/${id}`,
			{
				...data,
				id,
			},
		);

		return response.data;
	};

	create = async (parentId: number, data: Partial<T>) => {
		const response = await this.client.post<T>(
			`${this.parentEndpoint}/${parentId}/${this.endpoint}`,
			data,
		);

		return response.data;
	};
}
