import { useTranslation } from 'react-i18next';

import { Card } from '@socialbrothers/components/UI';

import { IPollAnswer } from '@Services/index';

import { PollAnswerTable } from './internal/PollAnswerTable/PollAnswerTable';
import { PollAnswerForm } from './internal/PollAnswerForm/PollAnswerForm';

interface Props {
	pollId: number;
	answers: IPollAnswer[];
}

export const PollAnswers = ({ answers, pollId }: Props) => {
	const { t } = useTranslation();

	const label = t('POLL.ANSWERS.PLURAL');

	return (
		<Card title={label}>
			<div className="mb-sm text-right">
				<PollAnswerForm pollId={pollId} />
			</div>

			<PollAnswerTable answers={answers} pollId={pollId} />
		</Card>
	);
};
