import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { Form } from '@socialbrothers/components/Containers';
import { Button } from '@socialbrothers/components/UI';
import { useTheme, useCountdown } from '@socialbrothers/hooks';
import { API_ERRORS } from '@socialbrothers/constants';

import { useStores } from '@Hooks/index';

import {
	LoginFormProps,
	LoginFormValues,
	TokenFormValues,
	ResendFormValues,
} from './LoginForm.props';
import styles from './LoginForm.module.scss';

const LoginForm = ({ ...props }: LoginFormProps) => {
	const { t } = useTranslation();
	const { authService } = useTheme();
	const [error, setError] = useState('');
	const { AuthStore } = useStores();

	const countdown = useCountdown(10);

	const [smsToken, setSmsToken] = useState<string>();

	const validationSchemaLogin = Yup.object().shape({
		email: Yup.string().email().required(),
		password: Yup.string().required(),
	});

	const validationSchemaToken = Yup.object().shape({
		sms_token: Yup.string().required(),
		code: Yup.string().required(),
	});

	const handleLogin = async (values: LoginFormValues) => {
		try {
			const response = await authService.login(values);
			setSmsToken(response.sms_token);
			setError('');
		} catch (e) {
			if (e.response.data.code === API_ERRORS.INVALID_CREDENTIALS) {
				setError(t('VALIDATION.LOGIN.INCORRECT_CREDENTIALS'));
			} else {
				setError(t('VALIDATION.COMMON.UNKNOWN_ERROR'));
			}
		}
	};

	const handleToken = async (values: TokenFormValues) => {
		try {
			await authService.token(values);
			AuthStore.setIsAuthenticated(true);
			setError('');
		} catch (e) {
			setError(t('VALIDATION.COMMON.UNKNOWN_ERROR'));
		}
	};

	const handleResend = async (values: ResendFormValues) => {
		countdown.start();

		try {
			const response = await authService.resend(values);
			setSmsToken(response.sms_token);
			setError('');
		} catch (e) {
			setError(t('VALIDATION.COMMON.UNKNOWN_ERROR'));
		}
	};

	if (smsToken) {
		return (
			<Form.Base
				className={styles.LoginForm}
				onSubmit={handleToken}
				validationSchema={validationSchemaToken}>
				<Form.Layout.Field className="mb-0" label={t('AUTHENTICATION.LOGIN_PAGE.CODE')}>
					<Form.Input.Text key="code" name="code" defaultValue="" />
				</Form.Layout.Field>

				<Form.Input.Hidden name="sms_token" defaultValue={smsToken} />

				<Button
					type="button"
					link
					disabled={countdown.count !== 0}
					size="small"
					onClick={() => handleResend({ sms_token: smsToken })}>
					{t('AUTHENTICATION.LOGIN_PAGE.RESEND')} {countdown.count > 0 && `(${countdown.count})`}
				</Button>

				{!!error && <div className={styles.Error}>{error}</div>}

				<div className={styles.Footer}>
					<Button type="submit">{t('AUTHENTICATION.LOGIN_PAGE.SUBMIT_LABEL')}</Button>
					<hr className={styles.Seperator} />
					<div className={styles.FooterLinks}>
						<Link to="forgot-password">{t('AUTHENTICATION.FORGOT_PASSWORD')}</Link>
					</div>
				</div>
			</Form.Base>
		);
	} else {
		return (
			<Form.Base
				className={styles.LoginForm}
				onSubmit={handleLogin}
				validationSchema={validationSchemaLogin}
				{...props}>
				<Form.Layout.Field label={t('FORMS.COMMON_LABELS.EMAIL')}>
					<Form.Input.Text name="email" />
				</Form.Layout.Field>
				<Form.Layout.Field label="Wachtwoord">
					<Form.Input.Text type="password" name="password" />
				</Form.Layout.Field>

				{!!error && <div className={styles.Error}>{error}</div>}

				<div className={styles.Footer}>
					<Button type="submit">{t('AUTHENTICATION.LOGIN_PAGE.SUBMIT_LABEL')}</Button>
					<hr className={styles.Seperator} />
					<div className={styles.FooterLinks}>
						<Link to="forgot-password">{t('AUTHENTICATION.FORGOT_PASSWORD')}</Link>
					</div>
				</div>
			</Form.Base>
		);
	}
};

export default LoginForm;
