import { Button } from '@socialbrothers/components/UI';
import { COLOR } from '@socialbrothers/constants';

import { ButtonActionProps } from './ButtonAction.props';

const ButtonAction = ({
	record,
	color = COLOR.PRIMARY,
	icon,
	label,
	className,
	tooltip,
	onClick,
	...props
}: ButtonActionProps) => {
	return (
		<Button color={color} tooltip={tooltip} icon={icon} onClick={() => onClick(record)}>
			{label}
		</Button>
	);
};

export default ButtonAction;
