import { ResourceService } from '@socialbrothers/services';
import { BaseModel, IImage } from '@socialbrothers/constants';

import MenselyService from './MenselyService';
import { IPoll } from './PollService';
import { IArticle } from './ArticleService';

export enum MOMENT_MOMENTABLE_TYPE {
	ARTICLE = 'App\\Models\\Article',
	TEXT = 'text',
	VIDEO = 'video',
	POLL = 'App\\Models\\Questions\\Polls\\Poll',
	IMAGE = 'App\\Models\\Image',
}

export interface IMoment extends BaseModel {
	title: string;
	description: string;
	momentable_type: MOMENT_MOMENTABLE_TYPE;

	contents?: string;
	momentable_id?: number;
	prepr_article_id?: string;

	poll?: IPoll;
	article?: IArticle;
	image?: IImage;
}

export class MomentServiceImplementation extends ResourceService<IMoment> {}

export const MomentService = new MomentServiceImplementation(MenselyService, '/admin/moments');
