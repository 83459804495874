import { EmptyResourceService } from '@socialbrothers/services';

import MenselyService from './MenselyService';

export interface ImportCompanyUsersRequest {
	file: File;
}

export class ImportServiceImplementation extends EmptyResourceService {
	companyUsers = async (values: ImportCompanyUsersRequest) => {
		await this.client.post<void>(`${this.endpoint}/companyusers`, values);
	};
}

export const ImportService = new ImportServiceImplementation(MenselyService, '/admin/import');
