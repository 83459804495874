import { useTranslation } from 'react-i18next';

import { Page } from '@socialbrothers/components/Layout';
import { Button, Card } from '@socialbrothers/components/UI';
import { COLOR } from '@socialbrothers/constants';

import { ThemeGroupTable } from '@Components/tables';

export const ThemeGroupOverviewPage = () => {
	const { t } = useTranslation();

	const buttons = (
		<Button icon="plus" to="/themegroups/create" color={COLOR.SECONDARY}>
			{t('THEMEGROUP.CREATE')}
		</Button>
	);

	return (
		<Page title={t('THEMEGROUP.PLURAL')} buttons={buttons}>
			<Card title={t('THEMEGROUP.PLURAL')}>
				<ThemeGroupTable />
			</Card>
		</Page>
	);
};
