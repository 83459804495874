import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import { ButtonWithPopup, Form } from '@socialbrothers/components/Containers';
import { useList } from '@socialbrothers/hooks';
import { COLOR } from '@socialbrothers/constants';

import {
	QuestionnaireSectionStepService,
	QuestionnaireService,
	ITheme,
	ThemeService,
	IThemeGroup,
	ThemeGroupService,
} from '@Services/index';

import { ThresholdFormProps, ThresholdFormFormProps } from './ThresholdForm.props';
import { Control } from 'react-hook-form';
import { useState } from 'react';
import { modelToOptions } from '@Root/socialbrothers/utils';

export const ThresholdForm = ({ id, section, step, threshold }: ThresholdFormProps) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const [themeGroupId, setThemeGroupId] = useState<number>();

	const themes = useList<ITheme>(ThemeService, {
		perPage: Number.MAX_SAFE_INTEGER,
	});

	const themegroups = useList<IThemeGroup>(ThemeGroupService, {
		perPage: Number.MAX_SAFE_INTEGER,
	});

	const mutateCreate = useMutation(
		(values: ThresholdFormFormProps) =>
			QuestionnaireSectionStepService.createScoreThreshold(id, section.id, step.id, values),
		{
			onSuccess: () => {
				queryClient.invalidateQueries([QuestionnaireService.endpoint, id]);

				toast.success(
					t('GLOBAL.CREATED_SUCCESSFUL', {
						name: t('QUESTIONNAIRE.CONFIGURATOR.THRESHOLD.SINGLE'),
					}),
				);
			},

			onError: () => {
				toast.error(
					t('GLOBAL.CREATED_UNSUCCESSFUL', {
						name: t('QUESTIONNAIRE.CONFIGURATOR.THRESHOLD.SINGLE'),
					}),
				);
			},
		},
	);

	const mutateEdit = useMutation(
		(values: ThresholdFormFormProps) =>
			QuestionnaireSectionStepService.updateScoreThreshold(
				id,
				section.id,
				step.id,
				threshold?.id || 0,
				values,
			),
		{
			onSuccess: () => {
				queryClient.invalidateQueries([QuestionnaireService.endpoint, id]);

				toast.success(
					t('GLOBAL.UPDATED_SUCCESSFUL', {
						name: t('QUESTIONNAIRE.CONFIGURATOR.THRESHOLD.SINGLE'),
					}),
				);
			},
		},
	);

	const handleThemeGroupIdChange = (value: string, control: Control<Record<string, any>>) => {
		const formatted = parseInt(value);

		if (value !== '' && !themeGroupId && threshold) {
			const activeTheme = themes.data?.data.find(
				(theme: ITheme) => theme.id === threshold.theme_id,
			);

			if (activeTheme) {
				control.setValue('theme_group_id', activeTheme.theme_group_id);
			}
		}

		if (formatted !== themeGroupId) {
			setThemeGroupId(formatted);
		}
	};

	const validationSchema = Yup.object().shape({
		theme_group_id: Yup.string().required(),
		theme_id: Yup.string().required(),
		min: Yup.number().required(),
		max: Yup.number().required().min(Yup.ref('min')),
		text: Yup.string().required(),
	});

	return (
		<ButtonWithPopup
			withForm
			validationSchema={validationSchema}
			initialValues={threshold}
			button={
				threshold
					? {
							icon: 'pencil',
							color: COLOR.PRIMARY,
					  }
					: {
							icon: 'plus',
							color: COLOR.SECONDARY,
							label: t('GLOBAL.CREATE_MODEL', {
								name: t('QUESTIONNAIRE.CONFIGURATOR.THRESHOLD.SINGLE'),
							}),
					  }
			}
			submit={{
				label: threshold ? t('GLOBAL.UPDATE') : t('GLOBAL.CREATE'),
				onClick: (values: ThresholdFormFormProps) => {
					if (threshold) {
						return mutateEdit.mutateAsync(values);
					} else {
						return mutateCreate.mutateAsync(values);
					}
				},
			}}
			popup={{
				title: t(`${step ? 'GLOBAL.UPDATE_MODEL' : 'GLOBAL.CREATE_MODEL'}`, {
					name: t('QUESTIONNAIRE.CONFIGURATOR.THRESHOLD.SINGLE'),
				}),
			}}>
			{themes?.data?.data && themegroups?.data?.data && (
				<>
					<Form.Layout.Field
						label={t('QUESTIONNAIRE.CONFIGURATOR.THRESHOLD.LABELS.THEME_GROUP')}
						onChange={handleThemeGroupIdChange}>
						<Form.Input.Select
							name="theme_group_id"
							options={modelToOptions(themegroups.data.data, 'name')}
						/>
					</Form.Layout.Field>

					{!!themeGroupId && (
						<Form.Layout.Field label={t('QUESTIONNAIRE.CONFIGURATOR.THRESHOLD.LABELS.THEME')}>
							<Form.Input.Select
								name="theme_id"
								options={modelToOptions(
									themes.data.data.filter((theme: ITheme) => theme.theme_group_id === themeGroupId),
									'name',
								)}
							/>
						</Form.Layout.Field>
					)}
				</>
			)}

			<Form.Layout.Field label={t('QUESTIONNAIRE.CONFIGURATOR.THRESHOLD.LABELS.MIN')}>
				<Form.Input.Number name="min" min={0} />
			</Form.Layout.Field>

			<Form.Layout.Field label={t('QUESTIONNAIRE.CONFIGURATOR.THRESHOLD.LABELS.MAX')}>
				<Form.Input.Number name="max" min={0} />
			</Form.Layout.Field>

			<Form.Layout.Field label={t('QUESTIONNAIRE.CONFIGURATOR.THRESHOLD.LABELS.TEXT')}>
				<Form.Input.RichText name="text" />
			</Form.Layout.Field>
		</ButtonWithPopup>
	);
};
