import { Control } from 'react-hook-form';
import { useState } from 'react';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import { ButtonWithPopup, Form } from '@socialbrothers/components/Containers';
import { useList } from '@socialbrothers/hooks';
import { modelToOptions, YupUtils } from '@socialbrothers/utils';
import { COLOR, IMAGE_FORMATS, OPERATORS } from '@socialbrothers/constants';

import {
	IQuestion,
	QuestionService,
	QuestionnaireSectionStepService,
	QuestionnaireService,
	ITheme,
	ThemeService,
	IThemeGroup,
	ThemeGroupService,
} from '@Services/index';

import { useQuestionnaireConfigurator } from '@Hooks/index';

import {
	StepContentFormFormProps,
	StepFormFormProps,
	StepFormProps,
	StepScoreFormFormProps,
} from './StepForm.props';

export const StepFormContent = ({ id, section, step }: StepFormProps) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();

	const validationSchema = Yup.object().shape({
		heading: Yup.string().required(),
		text: Yup.string().required(),
		image: Yup.mixed()
			.test(YupUtils.FileExtension([...Object.values(IMAGE_FORMATS)]))
			.test(YupUtils.FileSize(10)),
	});

	const mutateCreate = useMutation(
		(values: StepContentFormFormProps) =>
			QuestionnaireSectionStepService.createContent(id, section.id, values),
		{
			onSuccess: () => {
				queryClient.invalidateQueries([QuestionnaireService.endpoint, id]);

				toast.success(
					t('GLOBAL.CREATED_SUCCESSFUL', { name: t('QUESTIONNAIRE.CONFIGURATOR.CONTENT.SINGLE') }),
				);
			},
			onError: () => {
				toast.error(
					t('GLOBAL.CREATED_UNSUCCESSFUL', {
						name: t('QUESTIONNAIRE.CONFIGURATOR.CONTENT.SINGLE'),
					}),
				);
			},
		},
	);

	const mutateEdit = useMutation(
		(values: StepContentFormFormProps) =>
			QuestionnaireSectionStepService.updateScore(id, section.id, step?.id || 0, values),
		{
			onSuccess: () => {
				queryClient.invalidateQueries([QuestionnaireService.endpoint, id]);

				toast.success(
					t('GLOBAL.UPDATED_SUCCESSFUL', { name: t('QUESTIONNAIRE.CONFIGURATOR.CONTENT.SINGLE') }),
				);
			},
			onError: () => {
				toast.error(
					t('GLOBAL.CREATED_UNSUCCESSFUL', {
						name: t('QUESTIONNAIRE.CONFIGURATOR.CONTENT.SINGLE'),
					}),
				);
			},
		},
	);

	const handleSubmit = (values: StepContentFormFormProps) => {
		if (step) {
			return mutateEdit.mutateAsync(values);
		} else {
			return mutateCreate.mutateAsync(values);
		}
	};

	return (
		<ButtonWithPopup
			withForm
			initialValues={step}
			validationSchema={validationSchema}
			button={{
				color: step ? COLOR.PRIMARY : COLOR.SECONDARY,
				icon: step ? 'pencil' : 'plus',
				link: !!step,
				label: step ? '' : t('QUESTIONNAIRE.CONFIGURATOR.CONTENT.CREATE'),
			}}
			submit={{
				label: step ? t('GLOBAL.UPDATE') : t('GLOBAL.CREATE'),
				onClick: (values: StepContentFormFormProps) => handleSubmit(values),
			}}
			popup={{
				title: t(`${step ? 'GLOBAL.UPDATE_MODEL' : 'GLOBAL.CREATE_MODEL'}`, {
					name: t('QUESTIONNAIRE.CONFIGURATOR.CONTENT.SINGLE'),
				}),
			}}>
			<Form.Layout.Field label={t('QUESTIONNAIRE.CONFIGURATOR.CONTENT.LABELS.NEXT_QUESTION_LABEL')}>
				<Form.Input.Text name="next_question_label" />
			</Form.Layout.Field>

			<Form.Layout.Field label={t('QUESTIONNAIRE.CONFIGURATOR.CONTENT.LABELS.HEADING')}>
				<Form.Input.Text name="heading" />
			</Form.Layout.Field>

			<Form.Layout.Field label={t('QUESTIONNAIRE.CONFIGURATOR.CONTENT.LABELS.TEXT')}>
				<Form.Input.RichText name="text" />
			</Form.Layout.Field>

			<Form.Layout.Field label={t('QUESTIONNAIRE.CONFIGURATOR.CONTENT.LABELS.IMAGE')}>
				<Form.Input.File name="image" />
			</Form.Layout.Field>
		</ButtonWithPopup>
	);
};

export const StepFormScore = ({ id, section, step }: StepFormProps) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const [themeGroupId, setThemeGroupId] = useState<number>();
	const [hasInDepthQuestions, sethasInDepthQuestions] = useState(false);

	const themes = useList<ITheme>(ThemeService, {
		perPage: Number.MAX_SAFE_INTEGER,
	});

	const themegroups = useList<IThemeGroup>(ThemeGroupService, {
		perPage: Number.MAX_SAFE_INTEGER,
	});

	const mutateCreate = useMutation(
		(values: StepScoreFormFormProps) =>
			QuestionnaireSectionStepService.createScore(id, section.id, values),
		{
			onSuccess: () => {
				queryClient.invalidateQueries([QuestionnaireService.endpoint, id]);

				toast.success(
					t('GLOBAL.CREATED_SUCCESSFUL', { name: t('QUESTIONNAIRE.CONFIGURATOR.SCORE.SINGLE') }),
				);
			},
			onError: () => {
				toast.error(
					t('GLOBAL.CREATED_UNSUCCESSFUL', { name: t('QUESTIONNAIRE.CONFIGURATOR.SCORE.SINGLE') }),
				);
			},
		},
	);

	const mutateEdit = useMutation(
		(values: StepScoreFormFormProps) =>
			QuestionnaireSectionStepService.updateScore(id, section.id, step?.id || 0, values),
		{
			onSuccess: () => {
				queryClient.invalidateQueries([QuestionnaireService.endpoint, id]);

				toast.success(
					t('GLOBAL.UPDATED_SUCCESSFUL', { name: t('QUESTIONNAIRE.CONFIGURATOR.SCORE.SINGLE') }),
				);
			},
			onError: () => {
				toast.error(
					t('GLOBAL.UPDATED_UNSUCCESSFUL', { name: t('QUESTIONNAIRE.CONFIGURATOR.SCORE.SINGLE') }),
				);
			},
		},
	);

	const handleThemeGroupIdChange = (value: string, control: Control<Record<string, any>>) => {
		const formatted = parseInt(value);

		if (value !== '' && !themeGroupId && step) {
			const activeTheme = themes.data?.data.find(
				(theme: ITheme) => theme.id === step.in_depth_theme_id,
			);

			if (activeTheme) {
				control.setValue('theme_group_id', activeTheme.theme_group_id);
				control.setValue('in_depth_theme_id', activeTheme.id);
			}
		}

		if (formatted !== themeGroupId) {
			setThemeGroupId(formatted);
		}
	};

	const handleSubmit = (values: StepScoreFormFormProps) => {
		if (step) {
			return mutateEdit.mutateAsync(values);
		} else {
			return mutateCreate.mutateAsync(values);
		}
	};

	return (
		<ButtonWithPopup
			withForm
			initialValues={step}
			button={{
				color: step ? COLOR.PRIMARY : COLOR.WARNING,
				icon: step ? 'pencil' : 'plus',
				link: !!step,
				label: step ? '' : t('QUESTIONNAIRE.CONFIGURATOR.SCORE.CREATE'),
			}}
			submit={{
				label: step ? t('GLOBAL.UPDATE') : t('GLOBAL.CREATE'),
				onClick: (values: StepScoreFormFormProps) => handleSubmit(values),
			}}
			popup={{
				title: t(`${step ? 'GLOBAL.UPDATE_MODEL' : 'GLOBAL.CREATE_MODEL'}`, {
					name: t('QUESTIONNAIRE.CONFIGURATOR.SCORE.SINGLE'),
				}),
			}}>
			<Form.Layout.Field label={t('QUESTIONNAIRE.CONFIGURATOR.SCORE.LABELS.NEXT_QUESTION_LABEL')}>
				<Form.Input.Text name="next_question_label" />
			</Form.Layout.Field>

			<Form.Layout.Field
				label={t('QUESTIONNAIRE.CONFIGURATOR.SCORE.LABELS.NEXT_SECTION')}
				onChange={(value) => sethasInDepthQuestions(value)}>
				<Form.Input.Checkbox
					name="has_in_depth_questions"
					options={[
						{
							key: 1,
							value: '' + t('QUESTIONNAIRE.CONFIGURATOR.SCORE.LABELS.NEXT_SECTION_VALUE'),
						},
					]}
				/>
			</Form.Layout.Field>

			{hasInDepthQuestions && themes?.data?.data && themegroups?.data?.data && (
				<>
					<Form.Layout.Field
						label={t('QUESTIONNAIRE.CONFIGURATOR.SCORE.LABELS.IN_DEPTH_MINIMUM_SCORE')}>
						<Form.Input.Number name="in_depth_minimum_score" min={0} />
					</Form.Layout.Field>

					<Form.Layout.Field
						label={t('QUESTION.ANSWERS.LABELS.THEME_GROUP')}
						onChange={handleThemeGroupIdChange}>
						<Form.Input.Select
							name="theme_group_id"
							options={modelToOptions(themegroups.data.data, 'name')}
						/>
					</Form.Layout.Field>

					{!!themeGroupId && (
						<Form.Layout.Field label={t('QUESTION.ANSWERS.LABELS.THEME')}>
							<Form.Input.Select
								name="in_depth_theme_id"
								options={modelToOptions(
									themes.data.data.filter((theme: ITheme) => theme.theme_group_id === themeGroupId),
									'name',
								)}
							/>
						</Form.Layout.Field>
					)}
				</>
			)}
		</ButtonWithPopup>
	);
};

export const StepFormQuestion = ({ id, section, step }: StepFormProps) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const questionnaire = useQuestionnaireConfigurator();

	const validationSchema = Yup.object().shape({
		questionnaire_question_id: !step ? Yup.string().required() : Yup.string(),
	});

	const questions = useList<IQuestion>(QuestionService, {
		sortBy: 'title',
		perPage: Number.MAX_SAFE_INTEGER,
		filters: {
			module: [
				{
					operator: OPERATORS.IS,
					value: questionnaire.type,
				},
			],
		},
	});

	const mutateCreate = useMutation(
		(values: StepFormFormProps) => QuestionnaireSectionStepService.create(id, section.id, values),
		{
			onSuccess: () => {
				queryClient.invalidateQueries([QuestionnaireService.endpoint, id]);

				toast.success(
					t('GLOBAL.CREATED_SUCCESSFUL', { name: t('QUESTIONNAIRE.CONFIGURATOR.STEP.SINGLE') }),
				);
			},
			onError: () => {
				toast.error(
					t('GLOBAL.CREATED_UNSUCCESSFUL', { name: t('QUESTIONNAIRE.CONFIGURATOR.STEP.SINGLE') }),
				);
			},
		},
	);

	const mutateEdit = useMutation(
		(values: StepFormFormProps) =>
			QuestionnaireSectionStepService.update(id, section.id, step?.id || 0, values),
		{
			onSuccess: () => {
				queryClient.invalidateQueries([QuestionnaireService.endpoint, id]);

				toast.success(
					t('GLOBAL.UPDATED_SUCCESSFUL', { name: t('QUESTIONNAIRE.CONFIGURATOR.STEP.SINGLE') }),
				);
			},
			onError: () => {
				toast.error(
					t('GLOBAL.UPDATED_UNSUCCESSFUL', { name: t('QUESTIONNAIRE.CONFIGURATOR.STEP.SINGLE') }),
				);
			},
		},
	);

	return (
		<ButtonWithPopup
			withForm
			initialValues={step}
			validationSchema={validationSchema}
			button={{
				color: COLOR.PRIMARY,
				icon: step ? 'pencil' : 'plus',
				link: !!step,
				label: step ? '' : t('QUESTIONNAIRE.CONFIGURATOR.STEP.CREATE'),
			}}
			submit={{
				label: step ? t('GLOBAL.UPDATE') : t('GLOBAL.CREATE'),
				onClick: (values: StepFormFormProps) => {
					if (step) {
						return mutateEdit.mutateAsync(values);
					} else {
						return mutateCreate.mutateAsync(values);
					}
				},
			}}
			popup={{
				title: t(`${step ? 'GLOBAL.UPDATE_MODEL' : 'GLOBAL.CREATE_MODEL'}`, {
					name: t('QUESTIONNAIRE.CONFIGURATOR.STEP.SINGLE'),
				}),
			}}>
			<Form.Layout.Field label={t('QUESTIONNAIRE.CONFIGURATOR.STEP.LABELS.NEXT_QUESTION_LABEL')}>
				<Form.Input.Text name="next_question_label" />
			</Form.Layout.Field>

			{!step && (
				<Form.Layout.Field label={t('QUESTIONNAIRE.CONFIGURATOR.STEP.LABELS.STEP')}>
					<Form.Input.Select
						name="questionnaire_question_id"
						options={modelToOptions(questions.data?.data || [], 'title')}
					/>
				</Form.Layout.Field>
			)}
		</ButtonWithPopup>
	);
};

export const StepForm = ({ id, section }: StepFormProps) => {
	return (
		<>
			<StepFormQuestion id={id} section={section} />
			<StepFormScore id={id} section={section} />
			<StepFormContent id={id} section={section} />
		</>
	);
};
