import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { ButtonWithPopup, Form } from '@socialbrothers/components/Containers';
import { COLOR, OPERATORS } from '@socialbrothers/constants';
import { enumToOptions, modelToOptions } from '@socialbrothers/utils';
import { useList } from '@socialbrothers/hooks';

import {
	IPollAnswer,
	IQuestionnaire,
	ITheme,
	IThemeGroup,
	QuestionnaireAdditionalService,
	QuestionnaireService,
	QUESTIONNAIRE_FOR,
	QUESTIONNAIRE_TYPE,
	ThemeGroupService,
	ThemeService,
} from '@Services/index';

import { QuestionnaireAdditionalFormProps } from './QuestionnaireAdditionalForm.props';
import { useState } from 'react';
import { Control } from 'react-hook-form';

export const QuestionnaireAdditionalForm = ({
	answer,
	questionnaireId,
}: QuestionnaireAdditionalFormProps) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const [themeGroupId, setThemeGroupId] = useState<number>();
	const [questionnaireForId, setQuestionnaireForId] = useState<QUESTIONNAIRE_FOR>();

	const themes = useList<ITheme>(ThemeService, {
		sortBy: 'name',
		perPage: Number.MAX_SAFE_INTEGER,
	});

	const themegroups = useList<IThemeGroup>(ThemeGroupService, {
		perPage: Number.MAX_SAFE_INTEGER,
	});

	const questionnairesEmployee = useList<IQuestionnaire>(QuestionnaireService, {
		sortBy: 'title',
		perPage: Number.MAX_SAFE_INTEGER,
		filters: {
			published: [
				{
					operator: OPERATORS.IS,
					value: true,
				},
			],
			type: [
				{
					operator: OPERATORS.IS,
					value: QUESTIONNAIRE_TYPE.AOM,
				},
			],
			for: [
				{
					operator: OPERATORS.IS,
					value: QUESTIONNAIRE_FOR.FOR_EMPLOYEE,
				},
			],
			id: [
				{
					operator: OPERATORS.IS_NOT,
					value: questionnaireId,
				},
			],
		},
	});

	const questionnairesFilemanager = useList<IQuestionnaire>(QuestionnaireService, {
		sortBy: 'title',
		perPage: Number.MAX_SAFE_INTEGER,
		filters: {
			published: [
				{
					operator: OPERATORS.IS,
					value: true,
				},
			],
			type: [
				{
					operator: OPERATORS.IS,
					value: QUESTIONNAIRE_TYPE.AOM,
				},
			],
			for: [
				{
					operator: OPERATORS.IS,
					value: QUESTIONNAIRE_FOR.FILE_MANAGER,
				},
			],
			id: [
				{
					operator: OPERATORS.IS_NOT,
					value: questionnaireId,
				},
			],
		},
	});

	const mutateCreate = useMutation(
		(values: IPollAnswer) => QuestionnaireAdditionalService.create(questionnaireId, values),
		{
			onSuccess: () => {
				queryClient.invalidateQueries([
					QuestionnaireAdditionalService.parentEndpoint,
					questionnaireId,
				]);
				toast.success(
					t('GLOBAL.CREATED_SUCCESSFUL', { name: t('QUESTIONNAIRE.ADDITIONAL.SINGLE') }),
				);
			},

			onError: () => {
				toast.error(
					t('GLOBAL.CREATED_UNSUCCESSFUL', { name: t('QUESTIONNAIRE.ADDITIONAL.SINGLE') }),
				);
			},
		},
	);

	const mutateUpdate = useMutation(
		({ answerId, values }: { answerId: number; values: IPollAnswer }) =>
			QuestionnaireAdditionalService.update(questionnaireId, answerId, values),
		{
			onSuccess: () => {
				queryClient.invalidateQueries([
					QuestionnaireAdditionalService.parentEndpoint,
					questionnaireId,
				]);
				toast.success(
					t('GLOBAL.UPDATED_SUCCESSFUL', { name: t('QUESTIONNAIRE.ADDITIONAL.SINGLE') }),
				);
			},

			onError: () => {
				toast.error(
					t('GLOBAL.UPDATED_UNSUCCESSFUL', { name: t('QUESTIONNAIRE.ADDITIONAL.SINGLE') }),
				);
			},
		},
	);

	const validationSchema = Yup.object().shape({
		theme_group_id: Yup.string().required(),
		theme_id: Yup.string().required(),
		min_threshold: Yup.number().min(1).required(),
		max_threshold: Yup.number().moreThan(Yup.ref('min_threshold')).required(),
		questionnaire_for_id: Yup.string().required(),
		additional_questionnaire_id: Yup.string().required(),
	});

	const handleThemeGroupIdChange = (value: string, control: Control<Record<string, any>>) => {
		const formatted = parseInt(value);

		if (value !== '' && !themeGroupId && answer) {
			const activeTheme = themes.data?.data.find((theme: ITheme) => theme.id === answer.theme_id);

			if (activeTheme) {
				control.setValue('theme_group_id', activeTheme.theme_group_id);
			}
		}

		if (formatted !== themeGroupId) {
			setThemeGroupId(formatted);
		}
	};

	const handleQuestionnaireForIdChange = (
		value: QUESTIONNAIRE_FOR,
		control: Control<Record<string, any>>,
	) => {
		if (!value && !questionnaireForId && answer) {
			control.setValue('questionnaire_for_id', answer.additional_questionnaire.for);
		}

		if (value !== questionnaireForId) {
			setQuestionnaireForId(value);
		}
	};

	return (
		<ButtonWithPopup
			withForm
			validationSchema={validationSchema}
			initialValues={answer}
			button={{
				color: answer ? COLOR.PRIMARY : COLOR.SECONDARY,
				icon: answer ? 'pencil' : 'plus',
				label: answer
					? ''
					: t('GLOBAL.CREATE_MODEL', { name: t('QUESTIONNAIRE.ADDITIONAL.SINGLE') }),
			}}
			submit={{
				label: answer ? t('GLOBAL.UPDATE') : t('GLOBAL.CREATE'),
				onClick: (values) => {
					if (answer) {
						return mutateUpdate.mutateAsync({ answerId: answer.id, values });
					} else {
						return mutateCreate.mutateAsync(values);
					}
				},
			}}
			popup={{
				title: answer
					? t('GLOBAL.UPDATE_MODEL', { name: t('QUESTIONNAIRE.ADDITIONAL.SINGLE') })
					: t('GLOBAL.CREATE_MODEL', { name: t('QUESTIONNAIRE.ADDITIONAL.SINGLE') }),
			}}>
			{themes?.data?.data && themegroups?.data?.data && (
				<>
					<Form.Layout.Field
						label={t('QUESTIONNAIRE.ADDITIONAL.LABELS.THEME_GROUP')}
						onChange={handleThemeGroupIdChange}>
						<Form.Input.Select
							name="theme_group_id"
							options={modelToOptions(themegroups.data.data, 'name')}
						/>
					</Form.Layout.Field>

					{!!themeGroupId && (
						<Form.Layout.Field label={t('QUESTIONNAIRE.ADDITIONAL.LABELS.THEME')}>
							<Form.Input.Select
								name="theme_id"
								options={modelToOptions(
									themes.data.data.filter((theme: ITheme) => theme.theme_group_id === themeGroupId),
									'name',
								)}
							/>
						</Form.Layout.Field>
					)}
				</>
			)}

			<Form.Layout.Field label={t('QUESTIONNAIRE.ADDITIONAL.LABELS.MIN')}>
				<Form.Input.Number min={0} name="min_threshold" />
			</Form.Layout.Field>

			<Form.Layout.Field label={t('QUESTIONNAIRE.ADDITIONAL.LABELS.MAX')}>
				<Form.Input.Number min={0} name="max_threshold" />
			</Form.Layout.Field>

			{questionnairesEmployee?.data?.data && questionnairesFilemanager?.data?.data && (
				<>
					<Form.Layout.Field
						label={t('QUESTIONNAIRE.ADDITIONAL.LABELS.QUESTIONNAIRE_FOR')}
						onChange={handleQuestionnaireForIdChange}>
						<Form.Input.Select
							name="questionnaire_for_id"
							options={enumToOptions(
								{
									[QUESTIONNAIRE_FOR.FOR_EMPLOYEE]: t('EMPLOYEE'),
									[QUESTIONNAIRE_FOR.FILE_MANAGER]: t('FILE_MANAGER'),
								},
								'QUESTIONNAIRE_FOR',
							)}
						/>
					</Form.Layout.Field>

					{!!questionnaireForId && (
						<Form.Layout.Field label={t('QUESTIONNAIRE.ADDITIONAL.LABELS.QUESTIONNAIRE')}>
							<Form.Input.Select
								name="additional_questionnaire_id"
								options={modelToOptions(
									questionnaireForId === QUESTIONNAIRE_FOR.FOR_EMPLOYEE
										? questionnairesEmployee?.data?.data
										: questionnairesFilemanager?.data?.data,
									'title',
								)}
							/>
						</Form.Layout.Field>
					)}
				</>
			)}
		</ButtonWithPopup>
	);
};
