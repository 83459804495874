import { Control } from 'react-hook-form';
import { useState } from 'react';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import { useResource } from '@socialbrothers/hooks';
import { ButtonWithPopup, Form, Table } from '@socialbrothers/components/Containers';
import { COLOR } from '@socialbrothers/constants';
import { modelToOptions, parseMails } from '@socialbrothers/utils';

import {
	CompanyService,
	ICompanyLNIAccess,
	ICompanyLNITeam,
	ICompanyLNITeamAccess,
	IUser,
} from '@Services/index';

import {
	ModulesLNITeamPopupProps,
	ModulesLNITeamPopupFormProps,
	ModulesLNITeamProps,
	ModulesLNISendPopupFormProps,
	ModulesLNITeamAccessProps,
	ModulesLNITeamAccessPopupProps,
	ModulesLNITeamAccessPopupFormProps,
	ModulesLNITeamUsersProps,
} from './ModulesLNITeam.props';

const ModulesLNITeamAccessForm = ({
	companyId,
	teamId,
	isEdit,
	record,
}: ModulesLNITeamAccessPopupProps) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const company = useResource(CompanyService, companyId);

	const mutateCreateTeamAccess = useMutation(
		(values: ModulesLNITeamAccessPopupFormProps) =>
			CompanyService.createTeamAccess(companyId, teamId, values),
		{
			onSuccess: () => {
				queryClient.invalidateQueries([CompanyService.endpoint, companyId]);

				toast.success(
					t('GLOBAL.CREATED_SUCCESSFUL', {
						name: t('COMPANY.MODULE.TEAM.ACCESS.COURSE'),
					}),
				);
			},

			onError: () => {
				toast.error(
					t('GLOBAL.CREATED_UNSUCCESSFUL', {
						name: t('COMPANY.MODULE.TEAM.ACCESS.COURSE'),
					}),
				);
			},
		},
	);

	const mutateEditTeamAccess = useMutation(
		(values: ModulesLNITeamAccessPopupFormProps) =>
			CompanyService.updateTeamAccess(companyId, teamId, record?.id || 0, {
				...values,
				...{ id: record?.id },
			}),
		{
			onSuccess: () => {
				queryClient.invalidateQueries([CompanyService.endpoint, companyId]);

				toast.success(
					t('GLOBAL.UPDATED_SUCCESSFUL', {
						name: t('COMPANY.MODULE.TEAM.ACCESS.COURSE'),
					}),
				);
			},

			onError: () => {
				toast.error(
					t('GLOBAL.CREATED_UNSUCCESSFUL', {
						name: t('COMPANY.MODULE.TEAM.ACCESS.COURSE'),
					}),
				);
			},
		},
	);

	const validationSchema = Yup.object().shape({
		access_id: Yup.number().required(),
	});

	return (
		<ButtonWithPopup
			withForm
			initialValues={record}
			validationSchema={validationSchema}
			button={
				isEdit
					? {
							icon: 'pencil',
							color: COLOR.PRIMARY,
					  }
					: {
							icon: 'plus',
							color: COLOR.SECONDARY,
							label: t('COMPANY.MODULE.TEAM.ACCESS.ADD_COURSE'),
					  }
			}
			submit={{
				label: t('GLOBAL.SAVE'),
				onClick: (values: ModulesLNITeamAccessPopupFormProps) => {
					if (isEdit) {
						return mutateEditTeamAccess.mutateAsync(values);
					} else {
						return mutateCreateTeamAccess.mutateAsync(values);
					}
				},
			}}
			popup={{
				title: isEdit
					? t('COMPANY.MODULE.TEAM.ACCESS.EDIT_COURSE', { name: record?.access.course.title })
					: t('COMPANY.MODULE.TEAM.ACCESS.ADD_COURSE'),
			}}>
			<Form.Layout.Field label={t('COMPANY.MODULE.TEAM.ACCESS.COURSE')}>
				<Form.Input.Select
					name="access_id"
					options={company.data?.access && modelToOptions(company?.data?.access, 'course.title')}
				/>
			</Form.Layout.Field>

			<Form.Layout.Field label={t('COMPANY.MODULE.TEAM.ACCESS.MAIL_INTRO')}>
				<Form.Input.RichText name="mail_intro" />
			</Form.Layout.Field>

			<Form.Layout.Field label={t('COMPANY.MODULE.TEAM.ACCESS.MAIL_BODY')}>
				<Form.Input.RichText name="mail_body" />
			</Form.Layout.Field>

			<Form.Layout.Field label={t('COMPANY.MODULE.TEAM.ACCESS.MAIL_OUTRO')}>
				<Form.Input.RichText name="mail_outro" />
			</Form.Layout.Field>
		</ButtonWithPopup>
	);
};

const ModulesLNITeamAccess = ({ companyId, record }: ModulesLNITeamAccessProps) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const teamId = record?.id || 0;

	const mutateDeleteTeamAccess = useMutation(
		(teamAccesssId: number) => CompanyService.deleteTeamAccess(companyId, teamId, teamAccesssId),
		{
			onSuccess: () => {
				queryClient.invalidateQueries([CompanyService.endpoint, companyId]);

				toast.success(
					t('GLOBAL.DELETED_SUCCESSFUL', { name: t('COMPANY.MODULE.TEAM.ACCESS.COURSE') }),
				);
			},
		},
	);

	const mutateInviteTeamAccess = useMutation(
		(teamAccesssId: number) =>
			CompanyService.createTeamAccessInvite(companyId, teamId, teamAccesssId),
		{
			onSuccess: () => {
				queryClient.invalidateQueries([CompanyService.endpoint, companyId]);

				toast.success(t('GLOBAL.SENDED_SUCCESSFUL', { name: t('COMPANY.MODULE.MAIL.TITLE') }));
			},
		},
	);

	return (
		<ButtonWithPopup
			button={{
				icon: 'graduation-cap',
			}}
			popup={{
				title: t('COMPANY.MODULE.TEAM.ACCESS.COURSES'),
			}}>
			<div className="mb-sm d-flex">
				<div className="ml-auto">
					<ModulesLNITeamAccessForm companyId={companyId} teamId={teamId} />
				</div>
			</div>

			{record?.team_access && (
				<Table.Base data={record?.team_access}>
					<Table.Field.Function
						label={t('COMPANY.MODULE.TEAM.ACCESS.COURSE')}
						source="name"
						render={(record: ICompanyLNITeamAccess) => record.access.course.title}
					/>

					<Table.Field.Function
						label={t('COMPANY.MODULE.TEAM.ACCESS.TRAINER')}
						source="trainer"
						render={(record: ICompanyLNITeamAccess) => record.access.trainer_email}
					/>

					<Table.Field.BaseAction>
						<Table.Action.Field
							render={(record: ICompanyLNITeamAccess) => (
								<ModulesLNITeamAccessForm
									companyId={companyId}
									teamId={teamId}
									record={record}
									isEdit
								/>
							)}
						/>

						<Table.Action.Button
							icon="envelope"
							color={COLOR.SECONDARY}
							onClick={(record: ICompanyLNIAccess) => {
								mutateInviteTeamAccess.mutateAsync(record.id);
							}}
						/>

						<Table.Action.Button
							icon="trash-alt"
							color={COLOR.DANGER}
							onClick={(record: ICompanyLNIAccess) => {
								mutateDeleteTeamAccess.mutateAsync(record.id);
							}}
						/>
					</Table.Field.BaseAction>
				</Table.Base>
			)}
		</ButtonWithPopup>
	);
};

const ModulesLNITeamForm = ({ companyId, isEdit, record }: ModulesLNITeamPopupProps) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();

	const mutateCreateTeam = useMutation(
		(values: ModulesLNITeamPopupFormProps) => CompanyService.createTeam(companyId, values),
		{
			onSuccess: () => {
				queryClient.invalidateQueries([CompanyService.endpoint, companyId]);

				toast.success(
					t('GLOBAL.CREATED_SUCCESSFUL', {
						name: t('COMPANY.MODULE.TEAM.TEAM'),
					}),
				);
			},
		},
	);

	const mutateEditTeam = useMutation(
		(values: ModulesLNITeamPopupFormProps) =>
			CompanyService.updateTeam(companyId, record?.id || 0, { ...values, ...{ id: record?.id } }),
		{
			onSuccess: () => {
				queryClient.invalidateQueries([CompanyService.endpoint, companyId]);

				toast.success(
					t('GLOBAL.UPDATED_SUCCESSFUL', {
						name: t('COMPANY.MODULE.TEAM.TEAM'),
					}),
				);
			},
		},
	);

	const validationSchema = Yup.object().shape({
		name: Yup.string().required(),
	});

	return (
		<ButtonWithPopup
			withForm
			initialValues={record}
			validationSchema={validationSchema}
			button={
				isEdit
					? {
							icon: 'pencil',
							color: COLOR.PRIMARY,
					  }
					: {
							icon: 'plus',
							color: COLOR.SECONDARY,
							label: t('COMPANY.MODULE.TEAM.ADD_TEAM'),
					  }
			}
			submit={{
				label: t('GLOBAL.SAVE'),
				onClick: (values: ModulesLNITeamPopupFormProps) => {
					if (isEdit) {
						return mutateEditTeam.mutateAsync(values);
					} else {
						return mutateCreateTeam.mutateAsync(values);
					}
				},
			}}
			popup={{
				title: isEdit
					? t('COMPANY.MODULE.TEAM.EDIT_TEAM', { name: record?.name })
					: t('COMPANY.MODULE.TEAM.ADD_TEAM'),
			}}>
			{isEdit && (
				<Form.Layout.Field label={t('COMPANY.MODULE.COURSES.LICENCE_CODE')}>
					<Form.Input.Text disabled value={record?.licence_code} />
				</Form.Layout.Field>
			)}

			<Form.Layout.Field label={t('COMPANY.MODULE.TEAM.NAME')}>
				<Form.Input.Text name="name" />
			</Form.Layout.Field>
		</ButtonWithPopup>
	);
};

const ModulesLNITeamMail = ({ companyId, record }: ModulesLNITeamPopupProps) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const [mails, setMails] = useState<string | null>('');

	const mutateSend = useMutation(
		(values: ModulesLNISendPopupFormProps) =>
			CompanyService.createTeamInvite(
				companyId,
				record?.id || 0,
				values.emails?.replaceAll(' ', '').split(',') || [],
			),
		{
			onSuccess: () => {
				setMails('');

				queryClient.invalidateQueries([CompanyService.endpoint, companyId]);

				toast.success(
					t('GLOBAL.SENDED_SUCCESSFUL', {
						name: t('COMPANY.MODULE.MAIL.TITLE'),
					}),
				);
			},

			onError: () => {
				toast.error(
					t('GLOBAL.SENDED_UNSUCCESSFUL', {
						name: t('COMPANY.MODULE.MAIL.TITLE'),
					}),
				);
			},
		},
	);

	const handleUpload = async (file: File, control: Control) => {
		if (file) {
			setMails(await parseMails(file));
		}
	};

	const validationSchema = Yup.object().shape({
		emails: Yup.string().required(),
	});

	return (
		<ButtonWithPopup
			withForm
			validationSchema={validationSchema}
			button={{
				icon: 'envelope',
				color: COLOR.SECONDARY,
			}}
			submit={{
				label: t('GLOBAL.SEND'),
				onClick: async (values: ModulesLNISendPopupFormProps) => {
					return mutateSend.mutateAsync(values);
				},
			}}
			popup={{
				title: t('COMPANY.MODULE.MAIL.POPUP_TITLE', { name: record?.name }),
			}}>
			<p className="mb-md">{t('COMPANY.MODULE.MAIL.POPUP_DESCRIPTION')}</p>

			<Form.Layout.Field label={t('COMPANY.MODULE.MAIL.CSV')} onChange={handleUpload}>
				<Form.Input.File name="csv" accept=".csv" thumbnail={false} />
			</Form.Layout.Field>

			<Form.Layout.Field label={t('COMPANY.MODULE.MAIL.EMAILS')}>
				<Form.Input.Multiline name="emails" defaultValue={mails || ''} />
			</Form.Layout.Field>
		</ButtonWithPopup>
	);
};

const ModulesLNITeamUsers = ({ companyId, record }: ModulesLNITeamUsersProps) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const teamId = record?.id || 0;

	const mutateDeleteTeamUser = useMutation(
		(userId: number) => CompanyService.deleteTeamUser(companyId, teamId, userId),
		{
			onSuccess: () => {
				queryClient.invalidateQueries([CompanyService.endpoint, companyId]);

				toast.success(
					t('GLOBAL.DELETED_SUCCESSFUL', { name: t('COMPANY.MODULE.TEAM.USERS.USER') }),
				);
			},

			onError: () => {
				toast.error(
					t('GLOBAL.DELETED_UNSUCCESSFUL', { name: t('COMPANY.MODULE.TEAM.USERS.USER') }),
				);
			},
		},
	);

	return (
		<ButtonWithPopup
			button={{
				icon: 'users',
			}}
			popup={{
				title: t('COMPANY.MODULE.TEAM.USERS.USERS'),
			}}>
			{record?.users && (
				<Table.Base data={record?.users}>
					<Table.Field.Function
						label={t('COMPANY.MODULE.TEAM.USERS.NAME')}
						source="name"
						render={(record: IUser) => `${record.first_name} ${record.last_name}`}
					/>

					<Table.Field.Function
						label={t('COMPANY.MODULE.TEAM.USERS.EMAIL')}
						source="name"
						render={(record: IUser) => record.email}
					/>

					<Table.Field.BaseAction>
						<Table.Action.Button
							icon="unlink"
							color={COLOR.SECONDARY}
							onClick={(record: ICompanyLNIAccess) => {
								return mutateDeleteTeamUser.mutateAsync(record.id);
							}}
						/>
					</Table.Field.BaseAction>
				</Table.Base>
			)}
		</ButtonWithPopup>
	);
};

export const ModulesLNITeam = ({ id }: ModulesLNITeamProps) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const company = useResource(CompanyService, id);

	const mutateDeleteTeam = useMutation(
		(courseId: number) => CompanyService.deleteTeam(id, courseId),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(CompanyService.endpoint);
				queryClient.invalidateQueries([CompanyService.endpoint, id]);

				toast.success(t('GLOBAL.DELETED_SUCCESSFUL', { name: t('COMPANY.MODULE.TEAM.TEAM') }));
			},
		},
	);

	return (
		<ButtonWithPopup
			button={{
				icon: 'users',
			}}
			popup={{
				title: t('ENUM.MODULE.LNI'),
			}}>
			<>
				<div className="mb-sm d-flex">
					<div className="ml-auto">
						<ModulesLNITeamForm companyId={id} />
					</div>
				</div>

				<Table.Base data={company?.data.teams}>
					<Table.Field.Function
						label={t('COMPANY.MODULE.TEAM.TEAM')}
						source="name"
						render={(record: ICompanyLNITeam) => record.name}
					/>

					<Table.Field.BaseAction>
						<Table.Action.Field
							render={(record: ICompanyLNITeam) => (
								<ModulesLNITeamMail companyId={id} record={record} isEdit />
							)}
						/>

						<Table.Action.Field
							render={(record: ICompanyLNITeam) => (
								<ModulesLNITeamUsers companyId={id} record={record} />
							)}
						/>

						<Table.Action.Field
							render={(record: ICompanyLNITeam) => (
								<ModulesLNITeamAccess companyId={id} record={record} />
							)}
						/>

						<Table.Action.Field
							render={(record: ICompanyLNITeam) => (
								<ModulesLNITeamForm companyId={id} record={record} isEdit />
							)}
						/>

						<Table.Action.Button
							icon="trash-alt"
							color={COLOR.DANGER}
							onClick={(record: ICompanyLNIAccess) => {
								mutateDeleteTeam.mutateAsync(record.id);
							}}
						/>
					</Table.Field.BaseAction>
				</Table.Base>
			</>
		</ButtonWithPopup>
	);
};
