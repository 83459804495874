import ResourceTable from './ResourceTable/ResourceTable';
import BaseTable from './BaseTable/BaseTable';

import ButtonAction from './ButtonAction/ButtonAction';
import FieldAction from './FieldAction/FieldAction';

import UrlField from './UrlField/UrlField';
import EnumField from './EnumField/EnumField';
import DateField from './DateField/DateField';
import ChipField from './ChipField/ChipField';
import TextField from './TextField/TextField';
import EmailField from './EmailField/EmailField';
import NumberField from './NumberField/NumberField';
import BooleanField from './BooleanField/BooleanField';
import FunctionField from './FunctionField/FunctionField';

import BaseActionField from './BaseActionField/BaseActionField';
import ResourceActionFieldField from './ResourceActionField/ResourceActionField';

const tableCollection = {
	Action: {
		Button: ButtonAction,
		Field: FieldAction,
	},
	Field: {
		Url: UrlField,
		Enum: EnumField,
		Date: DateField,
		Chip: ChipField,
		Text: TextField,
		Email: EmailField,
		Number: NumberField,
		Boolean: BooleanField,
		Function: FunctionField,
		BaseAction: BaseActionField,
		ResourceAction: ResourceActionFieldField,
	},
	Resource: ResourceTable,
	Base: BaseTable,
};

export default tableCollection;
