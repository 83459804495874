import * as Yup from 'yup';
import { useState } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import { Form } from '@socialbrothers/components/Containers';
import { isCreate, YupUtils } from '@socialbrothers/utils';
import { IMAGE_FORMATS } from '@socialbrothers/constants';

import { CompanyService } from '@Services/index';

const CompanyForm = () => {
	const { id } = useParams<any>();
	const [initialName, setInitialName] = useState('');
	const { t } = useTranslation();

	const validationSchema = Yup.object().shape({
		name: Yup.string()
			.test(YupUtils.FieldValidation(CompanyService, t('COMPANY.FORM.NAME'), initialName))
			.required(),
		logo_path: Yup.mixed()
			.test(YupUtils.FileExtension([...Object.values(IMAGE_FORMATS)]))
			.test(YupUtils.FileSize(10)),
	});

	const handleDataFetched = (values: any) => {
		!isCreate(id) && setInitialName(values.name);
	};

	return (
		<>
			<Form.Resource
				id={id}
				validationSchema={validationSchema}
				onDataFetched={handleDataFetched}
				label={t('COMPANY.SINGLE')}
				deleteConfig={{
					redirect: '/companies',
					name: 'name',
				}}
				service={CompanyService}>
				<Form.Layout.Field label={t('COMPANY.FORM.NAME')}>
					<Form.Input.Text name="name" />
				</Form.Layout.Field>

				<Form.Layout.Field label={t('COMPANY.FORM.LOGO_PATH')}>
					<Form.Input.File name="logo_path" />
				</Form.Layout.Field>
			</Form.Resource>
		</>
	);
};

export default CompanyForm;
