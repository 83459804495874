import i18n from 'i18next';
import * as Yup from 'yup';
import { ResourceInterface } from '../services';

export class YupUtils {
	static FileExtension = (extensions: string[]): Yup.TestConfig => {
		return {
			name: 'FileExtension',
			test: (value: any) => {
				return value && value.type ? extensions.includes(value.type) : true;
			},
			message: i18n.t('VALIDATION.TEST.FILE_EXTENSION', {
				extensions: extensions,
			}),
			exclusive: false,
		};
	};

	static FileSize = (size: number): Yup.TestConfig => {
		return {
			name: 'FileExtension',
			test: (value: any) => {
				return value && value.size ? value.size / 1000000 <= size : true;
			},
			message: i18n.t('VALIDATION.TEST.FILE_SIZE', {
				size: size,
			}),
			exclusive: false,
		};
	};

	static FieldValidation = (
		service: ResourceInterface,
		field: string,
		initialValue?: string,
	): Yup.TestConfig => {
		return {
			name: 'FieldValidation',
			test: async (value: any, context: any) => {
				if (value !== initialValue) {
					const response = await service.validate(context.path, value);
					return response.is_valid;
				}

				return true;
			},
			message: i18n.t('VALIDATION.TEST.FIELD_UNIQUE', {
				field: field,
			}),
			exclusive: false,
		};
	};
}
