import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import { COLOR, KeyValues } from '@socialbrothers/constants';
import { ButtonWithPopup, Form } from '@socialbrothers/components/Containers';
import { useResource } from '@socialbrothers/hooks';

import { CompanyService, ICompanyModules } from '@Services/index';

import { ModulesCreateFormProps, ModulesCreateProps } from './ModulesCreate.props';

export const ModulesCreate = ({ id }: ModulesCreateProps) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const { data } = useResource(CompanyService, id);

	const mutateCreateModule = useMutation(
		(moduleId: number) => CompanyService.createModule(id, moduleId),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(CompanyService.endpoint);
				queryClient.invalidateQueries([CompanyService.endpoint, id]);

				toast.success(t('GLOBAL.CREATED_SUCCESSFUL', { name: t('COMPANY.MODULE.SINGLE') }));
			},
		},
	);

	const validationSchema = Yup.object().shape({
		module_id: Yup.string().required(),
	});

	const getModulesOptions = (): KeyValues => {
		const availableModules: string[] = [];

		availableModules[2] = t('ENUM.MODULE.LNI');
		availableModules[4] = t('ENUM.MODULE.AOM');
		availableModules[5] = t('ENUM.MODULE.OOM');
		availableModules[7] = t('ENUM.MODULE.PNI');

		const modules = Object.keys(availableModules)
			.map((value: string) => {
				if (!data.modules.some((module: ICompanyModules) => module.id === parseInt(value)))
					return {
						key: value,
						value: '' + availableModules[parseInt(value)],
					};

				return false;
			})
			.filter(Boolean);

		return modules as KeyValues;
	};

	return (
		<div className="mb-sm text-right">
			<ButtonWithPopup
				withForm
				validationSchema={validationSchema}
				button={{
					icon: 'plus',
					color: COLOR.SECONDARY,
					label: t('COMPANY.MODULE.CREATE'),
				}}
				submit={{
					label: t('COMPANY.MODULE.CREATE'),
					onClick: (values: ModulesCreateFormProps) => {
						return mutateCreateModule.mutateAsync(values.module_id);
					},
				}}
				popup={{
					title: t('COMPANY.MODULE.CREATE'),
				}}>
				<Form.Layout.Field label={t('COMPANY.MODULE.SINGLE')}>
					<Form.Input.Select name="module_id" options={getModulesOptions()} />
				</Form.Layout.Field>
			</ButtonWithPopup>
		</div>
	);
};
