import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Table } from '@socialbrothers/components/Containers';

import { CourseService, COURSE_TYPE, ICourse } from '@Services/index';

import styles from './CourseTable.module.scss';
import { CourseTableProps } from './CourseTable.props';

export const CourseTable = ({ className, defaultFilters, type }: CourseTableProps) => {
	const { t } = useTranslation();

	return (
		<Table.Resource<ICourse>
			service={CourseService}
			defaultFilters={defaultFilters}
			className={cn(styles.CourseTable, className)}>
			<Table.Field.Text sortable filterable source="title" label={t('COURSE.LABELS.TITLE')} />

			<Table.Field.Date
				source="created_at"
				label={t('TABLE.COMMON.COLUMN.CREATED_AT')}
				width={1}
				filterable
				sortable
			/>

			<Table.Field.ResourceAction
				deleteConfig={(record: ICourse) => ({
					name: record.title,
				})}
				editConfig={(record: ICourse) => ({
					link: `/${type === COURSE_TYPE.CHALLENGE ? 'challenges' : 'courses'}/${record.id}`,
				})}
			/>
		</Table.Resource>
	);
};
