import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import { Link, matchPath, NavLink, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import cn from 'classnames';

import { Icon } from '@socialbrothers/components/UI';
import { useEscape, useToggle } from '@socialbrothers/hooks/index';
import { RoutesLink, SidebarGroup, SidebarItem } from '@socialbrothers/constants';

import { sidebar } from '@Routes/index';
import { useStores } from '@Hooks/index';

import styles from './Sidebar.module.scss';
import { ItemProps } from './Sidebar.props';

const Item = ({ item }: ItemProps) => {
	var location = useLocation();

	const isActive =
		(!!matchPath(location.pathname, {
			path: item.path,
			exact: false,
			strict: false,
		}) &&
			item.path !== '/') ||
		item.path === location.pathname;

	return (
		<NavLink
			className={cn([styles.Item], {
				[styles.ItemActive]: isActive,
			})}
			to={item.path || ''}>
			<Icon className={styles.Icon} icon={item.icon ?? 'exclamation-circle'} />

			{item.label}
		</NavLink>
	);
};

const Dropdown = ({ item }: ItemProps) => {
	var location = useLocation();

	const items = item.children
		?.map((child: any) => {
			return !!matchPath(location.pathname, {
				path: child.path,
				exact: false,
				strict: false,
			});
		})
		.filter(Boolean);

	const { isToggle, toggle } = useToggle(items ? items?.length >= 1 : false);

	const renderItem = () => (
		<div
			onClick={toggle}
			className={cn([styles.Item, styles.ItemDropdown], {
				[styles.ItemActive]: isToggle,
			})}>
			<Icon className={styles.Icon} icon={item.icon ?? 'exclamation-circle'} />

			{item.label}
		</div>
	);

	const renderDropdown = () =>
		item.children && (
			<ul className={cn([styles.Dropdown], { [styles.DropdownActive]: isToggle })}>
				{item.children.map((link: RoutesLink, index: number) => {
					return (
						<li key={link.path}>
							<NavLink
								className={styles.Subitem}
								activeClassName={styles.SubitemActive}
								to={link.path || ''}>
								{link.label}
							</NavLink>
						</li>
					);
				})}
			</ul>
		);

	return (
		<>
			{renderItem()}
			{renderDropdown()}
		</>
	);
};

const Sidebar = () => {
	const { ThemeStore } = useStores();
	const { t } = useTranslation();
	const location = useLocation();

	useEffect(() => {
		ThemeStore.Sidebar.setIsOpen(false);
	}, [location, ThemeStore.Sidebar]);

	useEscape(() => ThemeStore.Sidebar.setIsOpen(false));

	return (
		<>
			<CSSTransition
				in={ThemeStore.Sidebar.isOpen}
				timeout={200}
				mountOnEnter={ThemeStore.isMobile}
				classNames="fade">
				<div className={styles.Overlay} onClick={ThemeStore.Sidebar.toggleIsOpen} />
			</CSSTransition>

			<CSSTransition
				in={ThemeStore.Sidebar.isOpen}
				timeout={200}
				mountOnEnter={ThemeStore.isMobile}
				classNames="slideLeft">
				<div className={styles.Sidebar}>
					<Link to="/" className={styles.Brand}>
						{t('GLOBAL.SITE_NAME')}
					</Link>

					<ul>
						{sidebar.map((group: SidebarGroup, index: number) => (
							<li key={index}>
								<span className={styles.Title}>{group.title}</span>

								<ul>
									{group.routes.map((item: SidebarItem, index: number) => (
										<li key={index}>
											{item.children ? <Dropdown item={item} /> : <Item item={item} />}
										</li>
									))}
								</ul>
							</li>
						))}
					</ul>
				</div>
			</CSSTransition>
		</>
	);
};

export default observer(Sidebar);
