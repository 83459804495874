import i18n from 'i18next';
import { merge } from 'lodash';

import socialbrothers from '@socialbrothers/locales';

import data from './locales';

i18n.init({
	lng: 'nl',
	resources: merge(socialbrothers, data),
	react: {
		bindI18n: 'languageChanged loaded',
		bindStore: 'added removed',
		nsMode: 'default',
		useSuspense: true,
	},
});

export default i18n;
