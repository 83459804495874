import { useTranslation } from 'react-i18next';

import { Page } from '@socialbrothers/components/Layout';
import { Button, Card } from '@socialbrothers/components/UI';
import { COLOR } from '@socialbrothers/constants';

import { QuestionnaireTable } from '@Components/tables';
import { QUESTIONNAIRE_TYPE } from '@Services/index';

export const AOMQuestionnaireOverviewPage = () => {
	const { t } = useTranslation();

	const buttons = (
		<Button icon="plus" to="/questionnaires/aom/create" color={COLOR.SECONDARY}>
			{t('QUESTIONNAIRE.CREATE')}
		</Button>
	);

	return (
		<Page title={t('QUESTIONNAIRE.PLURAL')} buttons={buttons}>
			<Card title={t('QUESTIONNAIRE.PLURAL')}>
				<QuestionnaireTable type={QUESTIONNAIRE_TYPE.AOM} />
			</Card>
		</Page>
	);
};
