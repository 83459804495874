import cn from 'classnames';

import { COLOR } from '@socialbrothers/constants';

import styles from './Progress.module.scss';
import { ProgressProps } from './Progress.props';

const Progress = ({ title, value, color, className, ...props }: ProgressProps) => {
	return (
		<div
			className={cn([styles.Progress], {
				[styles.SizeLG]: title,
				[`${className}`]: className,
			})}
			{...props}>
			{title && (
				<div className={styles.Header}>
					<div className={styles.Title}>{title}</div>
					<div className={styles.Value}>{`${value}%`}</div>
				</div>
			)}

			<div className={styles.Bar}>
				<div
					style={{ width: `${value}%` }}
					className={cn([styles.Percentage, `background-${color}`])}></div>
			</div>
		</div>
	);
};

Progress.defaultProps = {
	color: COLOR.PRIMARY,
};

export default Progress;
