import { useTranslation } from 'react-i18next';

import { Card } from '@socialbrothers/components/UI';

import { UserTable } from '@Components/tables';

import { UsersCreate } from './internal/UsersCreate/UsersCreate';
import { CompanyUsersProps } from './CompanyUsers.props';

export const CompanyUsers = ({ id }: CompanyUsersProps) => {
	const { t } = useTranslation();

	return (
		<Card title={t('COMPANY.USERS.TITLE')}>
			<UsersCreate id={id} />
			<UserTable companyId={id} />
		</Card>
	);
};
