import { useTranslation } from 'react-i18next';

import { Table } from '@socialbrothers/components/Containers';

import { ThemeGroupService, IThemeGroup } from '@Services/index';

import { ThemeGroupTableProps } from './ThemeGroupTable.props';

export const ThemeGroupTable = ({ className }: ThemeGroupTableProps) => {
	const { t } = useTranslation();

	return (
		<Table.Resource<IThemeGroup> service={ThemeGroupService}>
			<Table.Field.Text sortable filterable source="name" label={t('THEMEGROUP.LABELS.NAME')} />

			<Table.Field.ResourceAction
				deleteConfig={(record: IThemeGroup) => ({
					name: record.name,
				})}
				editConfig={(record: IThemeGroup) => ({
					link: `/themegroups/${record.id}`,
				})}
			/>
		</Table.Resource>
	);
};
