import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { useResource } from '@socialbrothers/hooks';
import { Page } from '@socialbrothers/components/Layout';
import { Card } from '@socialbrothers/components/UI';
import { isCreate } from '@socialbrothers/utils';

import { QuestionnaireService, QUESTIONNAIRE_TYPE } from '@Services/index';
import { QuestionnaireForm, QuestionnairePublishForm } from '@Components/forms';
import { QuestionnaireAdditional, QuestionnaireConfigurator } from '@Components/modules';

export const AOMQuestionnaireDetailPage = () => {
	const { t } = useTranslation();
	const { id } = useParams<any>();
	const { data } = useResource(QuestionnaireService, id);

	const label = data?.title || t('QUESTIONNAIRE.SINGLE');

	return (
		<Page title={label} buttons={data && <QuestionnairePublishForm questionnaire={data} />}>
			<Card title={label}>
				<QuestionnaireForm type={QUESTIONNAIRE_TYPE.AOM} />
			</Card>

			{!isCreate(id) && (
				<>
					<QuestionnaireAdditional
						id={id}
						type={QUESTIONNAIRE_TYPE.AOM}
						additional={data?.additional_questionnaires}
					/>

					<Card title={t('QUESTIONNAIRE.CONFIGURATOR.TITLE')}>
						<QuestionnaireConfigurator id={id} type={QUESTIONNAIRE_TYPE.AOM} />
					</Card>
				</>
			)}
		</Page>
	);
};
