import { ResourceService } from '@socialbrothers/services';
import { BaseModel } from '@socialbrothers/constants';
import { ChildResourceService } from '@socialbrothers/services';

import MenselyService from './MenselyService';

export interface IPollAnswer extends BaseModel {
	contents: string;
	prepr_article_id: string;
}

export interface IPoll extends BaseModel {
	question: string;
	answers: IPollAnswer[];
}

export class PollServiceImplementation extends ResourceService<IPoll> {}

export const PollService = new PollServiceImplementation(MenselyService, '/admin/polls');

export class PollAnswerServiceImplementation extends ChildResourceService<IPollAnswer, IPoll> {
	moveUp = async (pollId: number, answerId: number) => {
		const response = await this.client.post<IPoll>(
			`${this.parentEndpoint}/${pollId}/${this.endpoint}/${answerId}/up`,
		);

		return response.data;
	};

	moveDown = async (pollId: number, answerId: number) => {
		const response = await this.client.post<IPoll>(
			`${this.parentEndpoint}/${pollId}/${this.endpoint}/${answerId}/down`,
		);

		return response.data;
	};
}

export const PollAnswerService = new PollAnswerServiceImplementation(
	MenselyService,
	'/admin/polls',
	'answers',
);
