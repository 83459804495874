import { APIS } from '@socialbrothers/constants';

enum STORAGE_KEYS {
	ACCESS_TOKEN = 'accessToken',
}

interface AccessToken {
	token: string;
	expirationDate: string;
}

type TokenStorage = {
	[K in APIS]?: AccessToken;
};

class Storage {
	setItem = (key: STORAGE_KEYS, value: any) => {
		const data = JSON.stringify(value);

		localStorage.setItem(key, data);
	};

	getItem = <T>(key: STORAGE_KEYS): T | null => {
		const result = localStorage.getItem(key);

		if (!result) {
			return null;
		}

		return JSON.parse(result);
	};

	setAccessToken = (api: APIS, accessToken: AccessToken) => {
		const currentTokens = this.getAccessTokens();
		const newTokens = {
			...currentTokens,
			[api]: accessToken,
		};

		return this.setItem(STORAGE_KEYS.ACCESS_TOKEN, newTokens);
	};

	removeAccessToken = (api: APIS) => {
		const currentTokens = this.getAccessTokens();
		delete currentTokens[api];

		return this.setItem(STORAGE_KEYS.ACCESS_TOKEN, currentTokens);
	};

	getAccessToken = (api: APIS): AccessToken | null => {
		const tokens = this.getAccessTokens();

		if (!tokens) {
			return null;
		}

		return tokens[api] || null;
	};

	getAccessTokens = (): TokenStorage => {
		const tokens = this.getItem<TokenStorage>(STORAGE_KEYS.ACCESS_TOKEN);

		if (!tokens) {
			return {};
		}

		return tokens;
	};

	getIsAccessTokenValid = (): boolean => {
		const token = this.getAccessToken(APIS.MAIN);

		if (!token) {
			return false;
		}

		return true;
	};
}

export default new Storage();
