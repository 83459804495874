import cn from 'classnames';

import { SpinnerProps } from './Spinner.props';
import styles from './Spinner.module.scss';

const Spinner: React.FC<SpinnerProps> = ({ color, size = 40, className, ...props }) => {
	const dimension = `${size}px`;

	return (
		<div className={cn([styles.Spinner, className])}>
			<div
				style={{ width: dimension, height: dimension, borderWidth: size / 6 }}
				className={cn(styles.Loader, {
					[styles[`Loader--${color}`]]: !!color,
				})}
			/>
		</div>
	);
};

export default Spinner;
