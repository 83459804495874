import { MutableRefObject, useEffect } from 'react';

const useOnClickOutside = (
	ref: MutableRefObject<HTMLElement | undefined>,
	handler: (event: MouseEvent) => void,
) => {
	useEffect(() => {
		const listener = (event: MouseEvent) => {
			// Do nothing if clicking ref's element or descendent elements
			if (!ref.current || ref.current.contains(event.target as any)) {
				return;
			}

			handler(event);
		};

		document.addEventListener('mousedown', listener);
		document.addEventListener('touchstart', listener as any);

		return () => {
			document.removeEventListener('mousedown', listener);
			document.removeEventListener('touchstart', listener as any);
		};
	}, [ref, handler]);
};

export default useOnClickOutside;
