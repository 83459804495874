import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { Page } from '@socialbrothers/components/Layout';
import { Card } from '@socialbrothers/components/UI';
import { useResource } from '@socialbrothers/hooks';

import { UserService } from '@Services/index';
import { UserForm } from '@Components/forms';

export const AdminDetailPage = () => {
	const { t } = useTranslation();
	const { id } = useParams<any>();
	const { data } = useResource(UserService, id);

	const label = data ? `${data.first_name} ${data.last_name}` : t('ADMINS.SINGLE');

	return (
		<Page title={label}>
			<Card title={label}>
				<UserForm isAdmin />
			</Card>
		</Page>
	);
};
