import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useMutation, useQueryClient } from 'react-query';

import { COLOR } from '@socialbrothers/constants';
import { ButtonWithPopup, Table } from '@socialbrothers/components/Containers';

import { QuestionnaireAdditionalService } from '@Services/QuestionnaireService';

import { QuestionnaireAdditionalTableProps } from './QuestionnaireAdditionalTable.props';
import { QuestionnaireAdditionalForm } from '../QuestionnaireAdditionalForm/QuestionnaireAdditionalForm';

export const QuestionnaireAdditionalTable = ({
	additional,
	questionnaireId,
}: QuestionnaireAdditionalTableProps) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();

	const mutateDelete = useMutation(
		(additionalId: number) => {
			return QuestionnaireAdditionalService.delete(questionnaireId, additionalId);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries([
					QuestionnaireAdditionalService.parentEndpoint,
					questionnaireId,
				]);
				toast.success(
					t('GLOBAL.DELETED_SUCCESSFUL', { name: t('QUESTIONNAIRE.ADDITIONAL.SINGLE') }),
				);
			},
			onError: () => {
				toast.error(
					t('GLOBAL.DELETED_UNSUCCESSFUL', { name: t('QUESTIONNAIRE.ADDITIONAL.SINGLE') }),
				);
			},
		},
	);

	return (
		<Table.Base data={additional}>
			<Table.Field.Text source="theme.name" label={t('QUESTIONNAIRE.ADDITIONAL.LABELS.THEME')} />
			<Table.Field.Text source="min_threshold" label={t('QUESTIONNAIRE.ADDITIONAL.LABELS.MIN')} />
			<Table.Field.Text source="max_threshold" label={t('QUESTIONNAIRE.ADDITIONAL.LABELS.MAX')} />
			<Table.Field.Text
				source="additional_questionnaire.title"
				label={t('QUESTIONNAIRE.ADDITIONAL.LABELS.QUESTIONNAIRE')}
			/>

			<Table.Action.Field
				render={(record) => {
					return (
						<>
							<QuestionnaireAdditionalForm questionnaireId={questionnaireId} answer={record} />

							<ButtonWithPopup
								button={{
									icon: 'trash-alt',
									color: COLOR.DANGER,
								}}
								submit={{
									label: t('GLOBAL.DELETE'),
									color: COLOR.DANGER,
									onClick: () => {
										return mutateDelete.mutateAsync(record.id);
									},
								}}
								popup={{
									title: t('RESOURCE.DELETE.MODAL.TITLE', { name: record.theme.name }),
								}}>
								<p>
									{t('RESOURCE.DELETE.MODAL.DESCRIPTION', {
										name: record.theme.name,
									})}
								</p>
							</ButtonWithPopup>
						</>
					);
				}}
			/>
		</Table.Base>
	);
};
