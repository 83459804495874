import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { observer } from 'mobx-react';

import { getRoutesFromConfig } from '@socialbrothers/utils';
import { AppRoutes, AuthRoutes } from '@socialbrothers/routes';
import { NotFoundPage } from '@socialbrothers/pages';

import { useStores } from '@Hooks/index';
import { routes } from './routes';

const RootNavigator = () => {
	const { AuthStore } = useStores();

	const allRoutes = [...(!AuthStore.isAuthenticated ? AuthRoutes : [...AppRoutes, ...routes])];

	return (
		<Router>
			<Switch>
				{getRoutesFromConfig(allRoutes)}
				{AuthStore.isAuthenticated ? <Route component={NotFoundPage} /> : <Redirect to={'/'} />}
			</Switch>
		</Router>
	);
};

export default observer(RootNavigator);
