import { useState } from 'react';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import { COLOR, OPERATORS } from '@socialbrothers/constants';
import { ButtonWithPopup, Form } from '@socialbrothers/components/Containers';
import { enumToOptions, modelToOptions } from '@socialbrothers/utils';
import { useList } from '@socialbrothers/hooks';

import { ACCOUNT_TYPE, CompanyService, IUser, UserService } from '@Services/index';

import { UsersCreateFormProps, UsersCreateProps } from './UsersCreate.props';

export const UsersCreate = ({ id }: UsersCreateProps) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const [role, setRole] = useState<ACCOUNT_TYPE>();

	const users = useList<IUser>(UserService, {
		perPage: Number.MAX_SAFE_INTEGER,
		filters: {
			account_type: [
				{
					operator: OPERATORS.IS,
					value: role || ACCOUNT_TYPE.HR,
				},
			],
		},
	});

	const mutateAssignToCompany = useMutation(
		(userId: number) => UserService.assignToCompany(userId, id),
		{
			onSuccess: () => {
				toast.success(t('COMPANY.USERS.SUCCESS'));
				queryClient.invalidateQueries([CompanyService.endpoint, id]);
				queryClient.invalidateQueries([UserService.endpoint]);
			},

			onError: () => {
				toast.success(t('COMPANY.USERS.ERROR'));
			},
		},
	);

	const validationSchema = Yup.object().shape({
		role: Yup.string().required(),
		user_id: Yup.string().required(),
	});

	const handleRoleChange = (value: ACCOUNT_TYPE) => {
		if (role !== value) {
			setRole(value);
		}
	};

	return (
		<div className="mb-sm text-right">
			<ButtonWithPopup
				withForm
				validationSchema={validationSchema}
				button={{
					icon: 'plus',
					color: COLOR.SECONDARY,
					label: t('COMPANY.USERS.CREATE'),
				}}
				submit={{
					label: t('COMPANY.USERS.CREATE'),
					onClick: (values: UsersCreateFormProps) => {
						return mutateAssignToCompany.mutateAsync(values.user_id);
					},
				}}
				popup={{
					title: t('COMPANY.USERS.CREATE'),
				}}>
				<Form.Layout.Field label={t('COMPANY.USERS.ROLE')} onChange={handleRoleChange}>
					<Form.Input.Select
						name="role"
						options={enumToOptions(
							Object.values(ACCOUNT_TYPE).filter((val) => val !== ACCOUNT_TYPE.ADMIN),
							'ACCOUNT_TYPE',
						)}
					/>
				</Form.Layout.Field>

				{users.data?.data && role && (
					<Form.Layout.Field label={t('COMPANY.USERS.USER')}>
						<Form.Input.Select name="user_id" options={modelToOptions(users.data?.data, 'email')} />
					</Form.Layout.Field>
				)}
			</ButtonWithPopup>
		</div>
	);
};
