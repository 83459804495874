import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { ButtonWithPopup, Table } from '@socialbrothers/components/Containers';
import { COLOR, OPERATORS } from '@socialbrothers/constants';
import { Icon } from '@socialbrothers/components/UI';

import { UserService, IUser, ACCOUNT_TYPE, CompanyService } from '@Services/index';

import { UserTableProps } from './UserTable.props';

export const UserTable = ({ companyId, className, isAdmin, defaultFilters }: UserTableProps) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();

	const mutateRemoveToCompany = useMutation(
		(userId: number) => UserService.removeFromCompany(userId, companyId || 0),
		{
			onSuccess: () => {
				toast.success(t('COMPANY.USERS.UNLINK_SUCCESS'));
				queryClient.invalidateQueries([CompanyService.endpoint]);
				queryClient.invalidateQueries([UserService.endpoint]);
			},

			onError: () => {
				toast.success(t('COMPANY.USERS.UNLINK_ERROR'));
			},
		},
	);

	const mutateResend = useMutation((userId: number) => UserService.resend(userId), {
		onSuccess: () => {
			toast.success(t('USERS.LABELS_RESEND_INVITE.SUCCESS'));
			queryClient.invalidateQueries([CompanyService.endpoint]);
			queryClient.invalidateQueries([UserService.endpoint]);
		},

		onError: () => {
			toast.success(t('USERS.LABELS_RESEND_INVITE.ERROR'));
		},
	});

	return (
		<Table.Resource<IUser>
			service={UserService}
			className={className}
			defaultParams={
				companyId ? { companyId: companyId, includeInactive: true } : { includeInactive: true }
			}
			defaultFilters={{
				...defaultFilters,
				account_type: [
					{
						operator: isAdmin ? OPERATORS.IS : OPERATORS.IS_NOT,
						value: ACCOUNT_TYPE.ADMIN,
					},
				],
			}}>
			<Table.Field.Function
				source="first_name"
				width={1}
				label=""
				render={(item: IUser) => {
					const isComplete = !!item.first_name && !!item.last_name;
					return <Icon icon={isComplete ? 'check' : 'times'} />;
				}}
			/>

			<Table.Field.Text
				sortable
				filterable
				source="first_name"
				label={t('USERS.LABELS.FIRST_NAME')}
			/>
			<Table.Field.Text
				sortable
				filterable
				source="last_name"
				label={t('USERS.LABELS.LAST_NAME')}
			/>
			<Table.Field.Email sortable filterable source="email" label="Email" />

			{!isAdmin && (
				<Table.Field.Enum
					filterable
					sortable
					enumeration={ACCOUNT_TYPE}
					source="account_type"
					label="Account type"
					name="ACCOUNT_TYPE"
				/>
			)}

			<Table.Field.Date
				source="last_login"
				label={t('USERS.LABELS.LAST_LOGIN')}
				width={1}
				showTime
				filterable
				sortable
			/>
			<Table.Field.Date
				source="created_at"
				label={t('TABLE.COMMON.COLUMN.CREATED_AT')}
				width={1}
				filterable
				sortable
			/>

			{!isAdmin && (
				<Table.Field.Function
					source="requests_invite"
					width={1}
					label=""
					render={(item: IUser) => {
						const isComplete = !!item.first_name && !!item.last_name;

						return (
							<ButtonWithPopup
								button={{
									disabled: isComplete,
									color: COLOR.SECONDARY,
									icon: item.requests_invite ? 'envelope-open' : 'envelope',
								}}
								submit={{
									label: t('USERS.LABELS_RESEND_INVITE.POPUP_SUBMIT'),
									onClick: () => {
										return mutateResend.mutateAsync(item.id);
									},
								}}
								popup={{
									title: t('USERS.LABELS_RESEND_INVITE.POPUP_TITLE'),
								}}>
								{item.requests_invite
									? t('USERS.LABELS_RESEND_INVITE.REQUESTS_INVITE', {
											email: item.email,
									  })
									: t('USERS.LABELS_RESEND_INVITE.NOT_REQUESTS_INVITE', {
											email: item.email,
									  })}
							</ButtonWithPopup>
						);
					}}
				/>
			)}

			<Table.Field.ResourceAction
				deleteConfig={
					!companyId
						? (record: IUser) => ({
								name: record.email,
						  })
						: undefined
				}
				editConfig={(record: IUser) => ({
					link: `/${isAdmin ? 'administrators' : 'users'}/${record.id}`,
				})}>
				{companyId && (
					<Table.Action.Button
						icon="unlink"
						color={COLOR.SECONDARY}
						onClick={(record: IUser) => {
							mutateRemoveToCompany.mutateAsync(record.id);
						}}
					/>
				)}
			</Table.Field.ResourceAction>
		</Table.Resource>
	);
};
