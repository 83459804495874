import { forwardRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

import { Icon } from '@socialbrothers/components/UI';
import { keyValuesToReactSelectOptions } from '@socialbrothers/utils';

import { SelectInputProps } from './SelectInput.props';
import styles from './SelectInput.module.scss';

const SelectInput = ({
	name,
	options,
	isMulti,
	disabled,
	placeholder,
	...props
}: SelectInputProps) => {
	const { t } = useTranslation();
	const { control } = useFormContext();

	return (
		<div className={styles.SelectInput}>
			<Controller
				name={name}
				control={control}
				render={(field) => {
					const formattedOptions = keyValuesToReactSelectOptions(options);

					return (
						<Select
							isMulti={isMulti}
							isDisabled={disabled}
							menuPosition="fixed"
							menuPortalTarget={document.body}
							menuShouldScrollIntoView={true}
							menuShouldBlockScroll={true}
							className="react-select"
							styles={{ menuPortal: (base) => ({ ...base, zIndex: 1001 }) }}
							placeholder={placeholder || t('FORMS.COMMON_LABELS.SELECT_AN_OPTION')}
							options={formattedOptions}
							{...field}
							{...props}
							value={formattedOptions.find((option) => option.value === field.value)}
							onChange={(value: any) => {
								if (Array.isArray(value)) {
									field.onChange(value.map((v) => v.value));
								} else {
									field.onChange(value.value);
								}
							}}
						/>
					);
				}}
			/>

			<Icon type="light" className={styles.Icon} icon="angle-down" />
		</div>
	);
};

export default forwardRef(SelectInput);
