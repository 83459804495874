import axios, { AxiosResponse } from 'axios';

import { APIS, TokenResponse } from '@socialbrothers/constants';
import { Storage } from '@socialbrothers/helpers';
import { BaseService } from '@socialbrothers/services';
import RootStore from '@Stores/RootStore';

class MenselyService extends BaseService {
	private isRefreshing = false;
	private failedQueue: any[] = [];

	constructor(baseUrl: string) {
		super(baseUrl);

		const token = Storage.getAccessToken(APIS.MAIN);

		if (token) {
			this.setAccessToken(token.token);
		}

		this.api.interceptors.response.use(
			(response) => response,
			(err) => {
				const originalRequest = err.config;

				if (err.response.status === 401 && !originalRequest._retry) {
					// Fill queue till token is successfully refreshed...
					if (this.isRefreshing) {
						return new Promise((resolve, reject) => {
							this.failedQueue.push({ resolve, reject });
						})
							.then((token) => {
								originalRequest.headers['Authorization'] = 'Bearer ' + token;
								return this.api.request(originalRequest);
							})
							.catch((err) => {
								return Promise.reject(err);
							});
					}

					originalRequest._retry = true;
					this.isRefreshing = true;

					// Try to refresh the accessToken with the deprecated accessToken...
					return new Promise((resolve, reject) => {
						this.refreshToken()
							.then((response) => {
								this.setToken(response.data.access_token);

								originalRequest.headers['Authorization'] = 'Bearer ' + response.data.access_token;
								this.processQueue(null, response.data.access_token);
								resolve(this.api.request(originalRequest));
							})
							.catch((err) => {
								this.deleteToken();

								this.processQueue(err, '');
								reject(err);
							})
							.then(() => {
								this.isRefreshing = false;
							});
					});
				}

				return Promise.reject(err);
			},
		);
	}

	private setToken = (token: string) => {
		Storage.setAccessToken(APIS.MAIN, {
			token: token,
			expirationDate: '2030/01/01',
		});

		this.setAccessToken(token);
		RootStore.AuthStore.setIsAuthenticated(true);
	};

	private deleteToken = () => {
		Storage.removeAccessToken(APIS.MAIN);
		this.setAccessToken('');
		RootStore.AuthStore.setIsAuthenticated(false);
	};

	private refreshToken = (): Promise<AxiosResponse<TokenResponse>> => {
		return axios.post<TokenResponse>(`${process.env.REACT_APP_API_URL}/api/v1/auth/refresh`, null, {
			headers: {
				Authorization: `Bearer ${Storage.getAccessToken(APIS.MAIN)?.token}`,
				'Content-Type': 'multipart/form-data',
			},
		});
	};

	private processQueue = (error: any, token: string = '') => {
		this.failedQueue.forEach((prom) => {
			if (error) {
				prom.reject(error);
			} else {
				prom.resolve(token);
			}
		});

		this.failedQueue = [];
	};
}

export default new MenselyService(process.env.REACT_APP_API_URL as string);
