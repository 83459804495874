import { merge } from 'lodash';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { Table } from '@socialbrothers/components/Containers';
import { COLOR, OPERATORS } from '@socialbrothers/constants';

import { QuestionnaireService, IQuestionnaire } from '@Services/index';

import styles from './QuestionnaireTable.module.scss';
import { QuestionnaireTableProps } from './QuestionnaireTable.props';
import { useHistory } from 'react-router';

export const QuestionnaireTable = ({
	type,
	defaultFilters = {},
	className,
}: QuestionnaireTableProps) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const history = useHistory();

	const mutateDuplicate = useMutation(
		(record: IQuestionnaire) =>
			QuestionnaireService.duplicate(
				record,
				t('QUESTIONNAIRE.COPY', {
					name: record.title,
				}),
			),
		{
			onSuccess: (response: IQuestionnaire) => {
				queryClient.invalidateQueries(QuestionnaireService.endpoint);
				toast.success(t('GLOBAL.CREATED_SUCCESSFUL', { name: response.title }));

				history.push(`/questionnaires/${type.toLowerCase()}/${response.id}`);
			},
		},
	);

	return (
		<Table.Resource<IQuestionnaire>
			service={QuestionnaireService}
			defaultFilters={merge(defaultFilters, {
				type: [
					{
						operator: OPERATORS.IS,
						value: type,
					},
				],
			})}
			className={cn(styles.QuestionnaireTable, className)}>
			<Table.Field.Text sortable source="title" label={t('QUESTIONNAIRE.LABELS.TITLE')} />

			<Table.Field.Boolean
				sortable
				source="published"
				label={t('QUESTIONNAIRE.PUBLISH.PUBLISHED')}
			/>

			<Table.Field.Date
				source="created_at"
				label={t('TABLE.COMMON.COLUMN.CREATED_AT')}
				width={1}
				filterable
				sortable
			/>

			<Table.Field.ResourceAction
				deleteConfig={(record: IQuestionnaire) => ({
					name: record.title,
				})}
				editConfig={(record: IQuestionnaire) => ({
					link: `/questionnaires/${type.toLowerCase()}/${record.id}`,
				})}>
				<Table.Action.Button
					icon="copy"
					color={COLOR.SECONDARY}
					onClick={(record: IQuestionnaire) => {
						mutateDuplicate.mutateAsync(record);
					}}
				/>
			</Table.Field.ResourceAction>
		</Table.Resource>
	);
};
