import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import config from '@Config';
import { Form } from '@socialbrothers/components/Containers';
import { enumToOptions, isCreate, YupUtils } from '@socialbrothers/utils';
import { IMAGE_FORMATS } from '@socialbrothers/constants';

import { ACCOUNT_TYPE, UserService } from '@Services/index';

import { UserFormProps } from './UserForm.props';

export const UserForm = ({ isAdmin }: UserFormProps) => {
	const history = useHistory();
	const [initialEmail, setInitialEmail] = useState('');
	const [changePassword, setChangePassword] = useState(false);
	const { id } = useParams<any>();
	const { t } = useTranslation();

	useEffect(() => {
		if (isCreate(id) && !isAdmin) {
			history.push('/');
		}
	}, [id, history, isAdmin]);

	const validationSchema = Yup.object().shape({
		email: Yup.string()
			.test(YupUtils.FieldValidation(UserService, t('ROLE.LABELS.NAME'), initialEmail))
			.required(),
		first_name: Yup.string().required(),
		last_name: Yup.string().required(),
		phone_number: Yup.string().required(),
		avatar: Yup.mixed()
			.test(YupUtils.FileExtension([...Object.values(IMAGE_FORMATS)]))
			.test(YupUtils.FileSize(10)),
		account_type: isAdmin ? Yup.string() : Yup.string().required(),
		password: Yup.string().when('password_change', {
			is: 'true',
			then: (s) => s.matches(config.passwordRegex).required(),
		}),
		password_confirmation: Yup.string().when('password_change', {
			is: 'true',
			then: (s) =>
				s
					.equals([Yup.ref('password'), null], t('VALIDATION.COMMON.PASSWORDS_DONT_MATCH'))
					.required(),
		}),
	});

	const handleDataFetched = (values: any) => {
		!isCreate(id) && setInitialEmail(values.email);
	};

	return (
		<Form.Resource
			validationSchema={validationSchema}
			onDataFetched={handleDataFetched}
			service={UserService}
			label={t('USERS.SINGLE')}
			id={id}
			deleteConfig={{
				name: 'email',
				redirect: isAdmin ? '/administrators' : '/users',
			}}>
			<Form.Layout.Field label={t('USERS.LABELS.EMAIL')}>
				<Form.Input.Text name="email" />
			</Form.Layout.Field>

			<Form.Layout.Field label={t('USERS.LABELS.FIRST_NAME')}>
				<Form.Input.Text name="first_name" />
			</Form.Layout.Field>

			<Form.Layout.Field label={t('USERS.LABELS.LAST_NAME')}>
				<Form.Input.Text name="last_name" />
			</Form.Layout.Field>

			<Form.Layout.Field label={t('USERS.LABELS.PHONE_NUMBER')}>
				<Form.Input.Text name="phone_number" />
			</Form.Layout.Field>

			{isAdmin ? (
				<Form.Input.Hidden name="account_type" value={ACCOUNT_TYPE.ADMIN} />
			) : (
				<>
					<Form.Layout.Field label={t('USERS.LABELS.BIO')}>
						<Form.Input.Multiline name="bio" />
					</Form.Layout.Field>

					<Form.Layout.Field label={t('USERS.LABELS.AVATAR')}>
						<Form.Input.File name="avatar" onDelete={() => UserService.deleteFile(id, 'avatar')} />
					</Form.Layout.Field>

					<Form.Layout.Field label={t('USERS.LABELS.ACCOUNT_TYPE')}>
						<Form.Input.Select
							name="account_type"
							options={enumToOptions(ACCOUNT_TYPE, 'ACCOUNT_TYPE').filter(
								(value) => value.key !== ACCOUNT_TYPE.ADMIN,
							)}
						/>
					</Form.Layout.Field>
				</>
			)}

			<Form.Layout.Field
				label={t('USERS.PASSWORD_CHANGE')}
				onChange={(value) => {
					setChangePassword(value);
				}}>
				<Form.Input.Checkbox
					name="password_change"
					options={[{ key: 'true', value: String(t('USERS.PASSWORD_CHANGE')) }]}
				/>
			</Form.Layout.Field>

			{changePassword && (
				<>
					<Form.Layout.Field label={t('USERS.LABELS.PASSWORD')}>
						<Form.Input.Text type="password" name="password" />
					</Form.Layout.Field>

					<Form.Layout.Field label={t('USERS.LABELS.PASSWORD_CONFIRMATION')}>
						<Form.Input.Text type="password" name="password_confirmation" />
					</Form.Layout.Field>
				</>
			)}
		</Form.Resource>
	);
};
