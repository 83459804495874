import cn from 'classnames';
import { toast } from 'react-toastify';
import { useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';

import { Button } from '@socialbrothers/components/UI';
import { COLOR } from '@socialbrothers/constants';
import { ButtonWithPopup } from '@socialbrothers/components/Containers';

import {
	IQuestionnaireSection,
	QuestionnaireSectionService,
	QuestionnaireService,
} from '@Services/index';

import { SectionForm } from '../SectionForm/SectionForm';

import { StepView } from '../StepView/StepView';
import { StepForm } from '../StepForm/StepForm';

import styles from './SectionView.module.scss';
import { SectionViewProps } from './SectionView.props';

export const SectionView = ({ id, sections }: SectionViewProps) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();

	const mutateMove = useMutation(
		(values: IMutateMove) => {
			if (values.direction === 'up') {
				return QuestionnaireSectionService.moveUp(id, values.sectionId);
			} else {
				return QuestionnaireSectionService.moveDown(id, values.sectionId);
			}
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries([QuestionnaireService.endpoint, id]);
			},

			onError: () => {
				toast.error(t('QUESTIONNAIRE.CONFIGURATOR.SECTION.MOVE_FAILED'));
			},
		},
	);

	const mutateDelete = useMutation(
		(sectionId: number) => {
			return QuestionnaireSectionService.delete(id, sectionId);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries([QuestionnaireService.endpoint, id]);
				toast.success(
					t('GLOBAL.DELETED_SUCCESSFUL', { name: t('QUESTIONNAIRE.CONFIGURATOR.SECTION.SINGLE') }),
				);
			},

			onError: () => {
				toast.success(
					t('GLOBAL.DELETED_UNSUCCESSFUL', {
						name: t('QUESTIONNAIRE.CONFIGURATOR.SECTION.SINGLE'),
					}),
				);
			},
		},
	);

	const getSection = (section: IQuestionnaireSection, index: number) => (
		<div key={section.id} className={cn([styles.Sections__Item, styles.Section])}>
			<div className={styles.Section__Header}>
				<h4 className={styles.Section__Title}>
					{t('QUESTIONNAIRE.CONFIGURATOR.SECTION.SINGLE')} "{section.title}"
				</h4>

				<div className={styles.Section__Buttons}>
					<Button
						onClick={() => mutateMove.mutateAsync({ direction: 'up', sectionId: section.id })}
						disabled={index === 0}
						icon="arrow-up"
						isLoading={mutateMove.isLoading && mutateMove.variables?.sectionId === section.id}
					/>

					<Button
						onClick={() => mutateMove.mutateAsync({ direction: 'down', sectionId: section.id })}
						disabled={index + 1 === sections.length}
						icon="arrow-down"
						isLoading={mutateMove.isLoading && mutateMove.variables?.sectionId === section.id}
					/>

					<SectionForm id={id} section={section} />

					<ButtonWithPopup
						button={{
							icon: 'trash-alt',
							color: COLOR.DANGER,
						}}
						submit={{
							label: t('GLOBAL.DELETE'),
							color: COLOR.DANGER,
							onClick: () => {
								return mutateDelete.mutateAsync(section.id);
							},
						}}
						popup={{
							title: t('RESOURCE.DELETE.MODAL.TITLE', { name: section.title }),
						}}>
						<p>
							{t('RESOURCE.DELETE.MODAL.DESCRIPTION', {
								name: section.title,
							})}
						</p>
					</ButtonWithPopup>
				</div>
			</div>

			<div className={styles.Section__Steps}>
				<StepView id={id} steps={section.steps} />
				<StepForm id={id} section={section} />
			</div>
		</div>
	);

	if (sections) {
		if (sections.length > 0) {
			return (
				<div className={styles.Sections}>
					{sections.map((section: IQuestionnaireSection, index: number) =>
						getSection(section, index),
					)}
				</div>
			);
		} else {
			return (
				<div className={cn([styles.Sections, styles['Sections--None']])}>
					{t('QUESTIONNAIRE.CONFIGURATOR.SECTION.EMPTY')}
				</div>
			);
		}
	}

	return <></>;
};

interface IMutateMove {
	direction: 'up' | 'down';
	sectionId: number;
}
