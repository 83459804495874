import * as Yup from 'yup';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import { Form } from '@socialbrothers/components/Containers';

import { PollService } from '@Services/index';

import { PollFormProps } from './PollForm.props';

export const PollForm = ({ ...props }: PollFormProps) => {
	const { id } = useParams<any>();
	const { t } = useTranslation();

	const validationSchema = Yup.object().shape({
		question: Yup.string().required(),
	});

	return (
		<Form.Resource
			service={PollService}
			validationSchema={validationSchema}
			label={t('POLL.SINGLE')}
			id={id}
			deleteConfig={{
				name: 'question',
				redirect: '/polls',
			}}>
			<Form.Layout.Field label={t('POLL.LABELS.QUESTION')}>
				<Form.Input.Text name="question" />
			</Form.Layout.Field>
		</Form.Resource>
	);
};
