import { Controller, useFormContext } from 'react-hook-form';
import ReactQuill from 'react-quill';

import RichTextInputProps from './RichTextInput.props';
import styles from './RichTextInput.module.scss';

const RichTextInput = ({ name, onChange, ...props }: RichTextInputProps) => {
	const { control, setValue } = useFormContext();

	const formats = ['bold', 'italic', 'underline', 'strike', 'list', 'bullet', 'link'];
	const modules = {
		toolbar: [
			['bold', 'italic', 'underline', 'strike'],
			[{ list: 'ordered' }, { list: 'bullet' }],
			['link'],
		],
	};

	return (
		<div className={styles.RichText} {...props}>
			<Controller
				name={name}
				control={control}
				render={(field) => {
					return (
						<ReactQuill
							modules={modules}
							formats={formats}
							value={field.value || ''}
							onChange={(e) => setValue(field.name, e.replace('<p><br></p>', ''))}
						/>
					);
				}}
			/>
		</div>
	);
};

export default RichTextInput;
