import { setLocale } from 'yup';
import i18n from 'i18next';

setLocale({
	mixed: {
		required: i18n.t('VALIDATION.YUP.MIXED.REQUIRED'),
	},
	string: {
		email: i18n.t('VALIDATION.YUP.STRING.INVALID_EMAIL'),
	},
	array: {
		min: i18n.t('VALIDATION.YUP.ARRAY.MIN'),
		max: i18n.t('VALIDATION.YUP.ARRAY.MAX'),
	},
});
