import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { Button, DropdownButton, Icon } from '@socialbrothers/components/UI';
import { useTheme } from '@socialbrothers/hooks';

import { useStores } from '@Hooks/index';

import styles from './Header.module.scss';
import { HeaderProps } from './Header.props';

const Header = ({ ...props }: HeaderProps) => {
	const { AuthStore, ThemeStore } = useStores();
	const { t } = useTranslation();
	const { authService, accountService } = useTheme();

	const { data } = useQuery(['users', 'me'], accountService.me);

	const handleLogout = () => {
		authService.logout();
		AuthStore.setIsAuthenticated(false);
	};

	const accountDropdown = [
		[
			{
				link: '/account',
				label: t('ACCOUNT.TITLE'),
			},
		],
		[
			{
				link: '/administrators',
				label: t('ADMINS.PLURAL'),
			},
		],
		[
			{
				onPress: handleLogout,
				label: t('AUTHENTICATION.LOG_OUT'),
			},
		],
	];

	const renderAvatar = () => {
		return (
			<div className={styles['Header__Avatar']}>
				<div className={styles['Header__AvatarCircle']}>
					<Icon icon="user" />
				</div>
				<span className={styles['Header__Email']}>{data?.email}</span>
			</div>
		);
	};

	return (
		<div className={styles.Header}>
			<Button icon="bars" className="hidden-lg-up" onClick={ThemeStore.Sidebar.toggleIsOpen} />

			<div className="ml-auto">
				<DropdownButton render={renderAvatar} groups={accountDropdown} />
			</div>
		</div>
	);
};

export default observer(Header);
