import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import { Card } from '@socialbrothers/components/UI';
import { CourseStepsTable } from './internal/CourseStepsTable/CourseStepsTable';
import { CourseStepsForm } from './internal/CourseStepsForm/CourseStepsForm';

export const CourseSteps = ({ steps }: any) => {
	const { id } = useParams<any>();
	const { t } = useTranslation();

	const label = t('COURSE.STEPS.PLURAL');

	return (
		<Card title={label}>
			<div className="mb-sm text-right">
				<CourseStepsForm courseId={id} />
			</div>

			<CourseStepsTable courseId={id} steps={steps || []} />
		</Card>
	);
};
