import { useState } from 'react';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import { useList, useResource } from '@socialbrothers/hooks';
import { ButtonWithPopup, Form } from '@socialbrothers/components/Containers';
import { enumToOptions, modelToOptions } from '@socialbrothers/utils';
import { OPERATORS, SICK_LEAVE_SYSTEM, AFAS_IMPORTER } from '@socialbrothers/constants';

import {
	CompanyService,
	IQuestionnaire,
	QuestionnaireService,
	QUESTIONNAIRE_TYPE,
	UserService,
} from '@Services/index';

import { ModulesAOMFormProps, ModulesAOMProps } from './ModulesAOM.props';

export const ModulesAOM = ({ id, record }: ModulesAOMProps) => {
	const { t } = useTranslation();
	const [hasHR, setHasHR] = useState();
	const [sickLeaveSystem, setSickLeaveSystem] = useState<string>();
	const queryClient = useQueryClient();
	const company = useResource(CompanyService, id);

	const questionnaire = useList<IQuestionnaire>(QuestionnaireService, {
		sortBy: 'title',
		perPage: Number.MAX_SAFE_INTEGER,
		filters: {
			published: [
				{
					operator: OPERATORS.IS,
					value: true,
				},
			],
			type: [
				{
					operator: OPERATORS.IS,
					value: QUESTIONNAIRE_TYPE.AOM,
				},
			],
		},
	});

	const mutateUpdateModule = useMutation(
		(values: any) => CompanyService.updateModule(id, record.id, values),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(CompanyService.endpoint);
				queryClient.invalidateQueries([CompanyService.endpoint, id]);

				toast.success(t('GLOBAL.UPDATED_SUCCESSFUL', { name: t('ENUM.MODULE.AOM') }));
			},
			onError: (e: any) => {
				if (e?.response?.data?.additional === 'Invalid credentials') {
					toast.error(t('AOMSETTINGS.ERROR.INVALID_CREDENTIALS'));
				}
			},
		},
	);

	const mutateAssignToCompany = useMutation(
		(userId: number) => UserService.assignToCompany(userId, id),
		{
			onSuccess: () => {
				queryClient.invalidateQueries([CompanyService.endpoint, id]);
				queryClient.invalidateQueries([UserService.endpoint, id]);
			},
		},
	);

	const initialValues = {
		pob_id: company?.data?.pob?.id,
		company_doctor_id: company?.data?.company_doctor?.id,
		account_manager_id: company?.data?.account_manager?.id,
		consecutive_sick_days: company?.data?.aom_settings?.consecutive_sick_days,
		survey_expire_days: company?.data?.aom_settings?.survey_expire_days,
		file_lifetime: company?.data?.aom_settings?.file_lifetime,
		survey_reminder_interval: company?.data?.aom_settings?.survey_reminder_interval,
		hr_enabled: company?.data?.aom_settings?.hr_enabled,
		human_resources_id: company?.data?.human_resources?.id,
		hr_can_send_questionnaires: company?.data?.aom_settings?.hr_can_send_questionnaires,
		vos_questionnaire_id: company?.data?.aom_settings?.vos_questionnaire_id,
		sick_leave_system_type: company?.data?.aom_settings?.sick_leave_system_type || '',
		sick_leave_system_enabled: company?.data?.aom_settings?.sick_leave_system_enabled,
		sick_leave_system: company?.data?.aom_settings?.sick_leave_system,
	};

	const validationSchema = Yup.object().shape({
		consecutive_sick_days: Yup.number().min(1).required(),
		survey_expire_days: Yup.number().min(1).required(),
		file_lifetime: Yup.number().min(1).required(),
		survey_reminder_interval: Yup.number().min(1).required(),
		vos_questionnaire_id: Yup.string().required(),
		sick_leave_system_type: Yup.string(),
		sick_leave_system_enabled: Yup.boolean(),
		sick_leave_system: Yup.object()
			.when(['sick_leave_system_type'], {
				is: (sick_leave_system_type: string) =>
					sick_leave_system_type === SICK_LEAVE_SYSTEM.XPERT_SUITE,
				then: Yup.object({
					host: Yup.string().required(),
					client_id: Yup.string().required(),
					token: Yup.string().required(),
				}),
			})
			.when(['sick_leave_system_type'], {
				is: (sick_leave_system_type: string) => sick_leave_system_type === SICK_LEAVE_SYSTEM.AFAS,
				then: Yup.object({
					version: Yup.string().required(),
					data: Yup.string().required(),
					environment_code: Yup.string().required(),
					importer: Yup.string().required()
				}),
			}),
	});

	return (
		<ButtonWithPopup
			withForm
			initialValues={initialValues}
			validationSchema={validationSchema}
			button={{
				icon: 'pencil',
			}}
			submit={{
				label: t('GLOBAL.SAVE'),
				onClick: async (values: ModulesAOMFormProps) => {
					if (values.pob_id !== company?.data?.data?.pob?.id) {
						await mutateAssignToCompany.mutateAsync(values.pob_id);
					}

					if (values.company_doctor_id !== company?.data?.data?.company_doctor?.id) {
						await mutateAssignToCompany.mutateAsync(values.company_doctor_id);
					}

					if (values.account_manager_id !== company?.data?.data?.account_manager?.id) {
						await mutateAssignToCompany.mutateAsync(values.account_manager_id);
					}

					if (values.human_resources_id !== company?.data?.data?.human_resources?.id) {
						await mutateAssignToCompany.mutateAsync(values.human_resources_id);
					}

					return await mutateUpdateModule.mutateAsync(values);
				},
			}}
			popup={{
				title: t('ENUM.MODULE.AOM'),
			}}>
			<Form.Layout.Field
				label={t('COMPANY.MODULE.AOM.CONSECUTIVE_SICK_DAYS')}
				suffix={t('GLOBAL.DAYS')}>
				<Form.Input.Number min={1} name="consecutive_sick_days" />
			</Form.Layout.Field>

			<Form.Layout.Field
				label={t('COMPANY.MODULE.AOM.SURVEY_EXPIRE_DAYS')}
				suffix={t('GLOBAL.DAYS')}>
				<Form.Input.Number min={1} name="survey_expire_days" />
			</Form.Layout.Field>

			<Form.Layout.Field label={t('COMPANY.MODULE.AOM.FILE_LIFETIME')} suffix={t('GLOBAL.DAYS')}>
				<Form.Input.Number min={1} name="file_lifetime" />
			</Form.Layout.Field>

			<Form.Layout.Field
				label={t('COMPANY.MODULE.AOM.SURVEY_REMINDER_INTERVAL')}
				suffix={t('GLOBAL.DAYS')}>
				<Form.Input.Number min={1} name="survey_reminder_interval" />
			</Form.Layout.Field>

			<Form.Layout.Field label={t('COMPANY.MODULE.AOM.VOS_QUESTIONNAIRE_ID')}>
				<Form.Input.Select
					name="vos_questionnaire_id"
					options={questionnaire.data?.data && modelToOptions(questionnaire.data?.data, 'title')}
				/>
			</Form.Layout.Field>

			<Form.Layout.Field
				label={t('COMPANY.MODULE.AOM.HR.HR_ENABLED')}
				onChange={(value) => {
					if (value !== hasHR) {
						setHasHR(value);
					}
				}}>
				<Form.Input.Checkbox
					name="hr_enabled"
					options={[{ key: 1, value: String(t('GLOBAL.YES')) }]}
				/>
			</Form.Layout.Field>

			{hasHR && (
				<Form.Layout.Field label={t('COMPANY.MODULE.AOM.HR.HR_CAN_SEND_QUESTIONNAIRES')}>
					<Form.Input.Checkbox
						name="hr_can_send_questionnaires"
						options={[{ key: 1, value: String(t('GLOBAL.YES')) }]}
					/>
				</Form.Layout.Field>
			)}

			<Form.Layout.Field
				label={t('AOMSETTINGS.LABELS.SICK_LEAVE_SYSTEM_ENABLED')}
				description={t('AOMSETTINGS.LABELS.SICK_LEAVE_SYSTEM_ENABLED_DESCRIPTION')}>
				<Form.Input.Checkbox
					name="sick_leave_system_enabled"
					options={[{ key: 1, value: String(t('GLOBAL.YES')) }]}
				/>
			</Form.Layout.Field>

			<Form.Layout.Field
				label={t('AOMSETTINGS.LABELS.SICK_LEAVE_SYSTEM')}
				onChange={setSickLeaveSystem}>
				<Form.Input.Select
					name="sick_leave_system_type"
					options={[
						{
							key: '',
							value: String(t('AOMSETTINGS.LABELS.SICK_LEAVE_SYSTEM_PLACEHOLDER')),
						},
						...enumToOptions(SICK_LEAVE_SYSTEM, 'SICK_LEAVE_SYSTEM'),
					]}
				/>
			</Form.Layout.Field>

			{!!sickLeaveSystem && (
				<Form.Layout.Group label={t('AOMSETTINGS.LABELS.SICK_LEAVE_SYSTEM_CONFIGURATION.GROUP')}>
					{sickLeaveSystem === SICK_LEAVE_SYSTEM.XPERT_SUITE && (
						<>
							<Form.Layout.Field
								label={t('AOMSETTINGS.LABELS.SICK_LEAVE_SYSTEM_CONFIGURATION.HOST')}>
								<Form.Input.Text name="sick_leave_system.host" />
							</Form.Layout.Field>

							<Form.Layout.Field
								label={t('AOMSETTINGS.LABELS.SICK_LEAVE_SYSTEM_CONFIGURATION.CLIENT_ID')}>
								<Form.Input.Text name="sick_leave_system.client_id" />
							</Form.Layout.Field>

							<Form.Layout.Field
								label={t('AOMSETTINGS.LABELS.SICK_LEAVE_SYSTEM_CONFIGURATION.TOKEN')}>
								<Form.Input.Text name="sick_leave_system.token" />
							</Form.Layout.Field>
						</>
					)}

					{sickLeaveSystem === SICK_LEAVE_SYSTEM.AFAS && (
						<>
							<Form.Layout.Field
								label={t('AOMSETTINGS.LABELS.SICK_LEAVE_SYSTEM_CONFIGURATION.VERSION')}>
								<Form.Input.Select
									name="sick_leave_system.version"
									options={[{ key: '1', value: '1' }]}
								/>
							</Form.Layout.Field>

							<Form.Layout.Field
								label={t('AOMSETTINGS.LABELS.SICK_LEAVE_SYSTEM_CONFIGURATION.DATA')}>
								<Form.Input.Text name="sick_leave_system.data" />
							</Form.Layout.Field>

							<Form.Layout.Field
								label={t('AOMSETTINGS.LABELS.SICK_LEAVE_SYSTEM_CONFIGURATION.ENVIRONMENT_CODE')}>
								<Form.Input.Text name="sick_leave_system.environment_code" />
							</Form.Layout.Field>

							<Form.Layout.Field
								label={t('AOMSETTINGS.LABELS.SICK_LEAVE_SYSTEM_CONFIGURATION.IMPORTER')}>
								<Form.Input.Select
									name="sick_leave_system.importer"
									options={enumToOptions(AFAS_IMPORTER, 'AFAS_IMPORTER')}
								/>
							</Form.Layout.Field>
						</>
					)}
				</Form.Layout.Group>
			)}
		</ButtonWithPopup>
	);
};
