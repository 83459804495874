import { getInputNameFromChildren } from '@socialbrothers/utils';
import cn from 'classnames';
import { get } from 'lodash';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import styles from './Field.module.scss';
import { FieldProps } from './Field.props';

const Field = ({
	children,
	className,
	label,
	description,
	onChange,
	prefix,
	suffix,
	size,
	...props
}: FieldProps) => {
	const { formState, control } = useFormContext();
	const inputName = getInputNameFromChildren(children);

	const value = useWatch({
		control,
		name: inputName,
	});

	useEffect(() => {
		onChange && formState.isSubmitting === false && onChange(value, control);
	}, [value, control, onChange, formState.isSubmitting]);

	const errorMessage = get(formState.errors, inputName)?.message;

	return (
		<div
			style={{
				flexGrow: size,
			}}
			className={cn(styles.Field, className, {
				[styles['Field--Error']]: !!errorMessage,
			})}
			{...props}>
			<fieldset className={styles['Field__Fieldset']}>
				{!!label && <legend className={styles['Field__Label']}>{label}</legend>}

				<div className={styles.Field__Children}>
					{prefix && <div className={styles.Field__Prefix}>{prefix}</div>}
					{children}
					{suffix && <div className={styles.Field__Suffix}>{suffix}</div>}
				</div>
			</fieldset>
			{!!description && (
				<div
					className={styles['Field__Description']}
					dangerouslySetInnerHTML={{ __html: description }}
				/>
			)}
			{!!errorMessage && <div className={styles['Field__Error']}>{errorMessage}</div>}
		</div>
	);
};

export default Field;
