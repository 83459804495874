import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import { Header, Sidebar, Footer, Breadcrumbs } from '@socialbrothers/components/Layout';
import { useBreadcrumbs } from '@socialbrothers/hooks';

import { routes } from '@Routes/routes';

import styles from './Page.module.scss';
import { PageProps } from './Page.props';

const Page = ({ title, breadcrumbs = true, buttons, children }: PageProps) => {
	const { t } = useTranslation();
	const items = useBreadcrumbs(routes, title);

	useEffect(() => {
		document.title = t('GLOBAL.SITE_TITLE', {
			name: items.length > 1 ? items[1].label : t('DASHBOARD.TITLE'),
		});
	}, [t, items]);

	return (
		<div className={styles.Page}>
			<Sidebar />

			<div className={styles.Body}>
				<Header />

				<div className={styles.Header}>
					<div className={styles.Header__Content}>
						{title && <h1 className={styles.Title}>{title}</h1>}
						{breadcrumbs && <Breadcrumbs items={items} />}
					</div>

					{buttons && <div className={styles.Header__Buttons}>{buttons}</div>}
				</div>

				<div className={styles.Content}>{children}</div>

				<Footer />
			</div>
		</div>
	);
};

export default observer(Page);
