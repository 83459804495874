import { cloneElement } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { FieldProps } from '@socialbrothers/constants';
import { SORT_DIRECTION } from '@socialbrothers/stores/Table/TableStore';

import { BaseTableProps, BaseTableBodyProps } from './BaseTable.props';
import styles from './BaseTable.module.scss';

const TableBody = observer(({ fields, filter, data }: BaseTableBodyProps) => {
	const { t } = useTranslation();
	const items = fields.map((child: JSX.Element) => child.props);

	const getTableHead = () => (
		<thead>
			<tr>
				{items
					.filter((item: FieldProps | undefined) => item !== undefined)
					.map((item: FieldProps, key: number) => {
						const style = {
							width: 'auto',
						};

						if (item.source) {
							style.width = item.width ? `${item.width}px` : 'auto';
						} else {
							style.width = `1px`;
						}

						if (item.sortable && filter) {
							return (
								<th key={item.source} style={style} onClick={() => filter.setSort(item.source)}>
									<div className={styles.Sortable}>
										<span className={styles.Label}>{item.label}</span>

										<div className={styles.Sortable__Icon}>
											<i
												className={cn(['fad'], {
													'fa-sort': filter.sortBy !== item.source,
													'fa-sort-up':
														filter.sortBy === item.source &&
														filter.sortDirection === SORT_DIRECTION.ASCENDING,
													'fa-sort-down':
														filter.sortBy === item.source &&
														filter.sortDirection === SORT_DIRECTION.DESCENDING,
												})}
											/>
										</div>
									</div>
								</th>
							);
						}

						return (
							<th key={item.source || key} style={style}>
								{item.label && <span className={styles.Label}>{item.label}</span>}
							</th>
						);
					})}
			</tr>
		</thead>
	);

	const getTableBody = () => (
		<tbody>
			{data.map((field: any) => {
				return (
					<tr key={field.id}>
						{fields
							.filter((item: JSX.Element | false) => item !== false)
							.map((item: JSX.Element, index: number) => {
								return (
									<td key={item.props.source || index}>
										{cloneElement(item, { sortable: null, filterable: null, record: field })}
									</td>
								);
							})}
					</tr>
				);
			})}
		</tbody>
	);

	const getTableEmpty = () => (
		<tbody>
			<tr>
				<td colSpan={fields.length} className={styles.Empty}>
					{t('TABLE.TABLE.NO_RESULTS.DEFAULT')}
				</td>
			</tr>
		</tbody>
	);

	if (data)
		return (
			<div className={styles.TableBody}>
				<table>
					{getTableHead()}

					{data.length === 0 && getTableEmpty()}
					{data.length > 0 && getTableBody()}
				</table>
			</div>
		);

	return <></>;
});

function BaseTable<T>({ data, filter, children }: BaseTableProps<T>) {
	return (
		<div className={cn(styles.Wrapper)}>
			<TableBody data={data} fields={children} filter={filter} />
		</div>
	);
}

export default observer(BaseTable);
