import { useTranslation } from 'react-i18next';

import { Table } from '@socialbrothers/components/Containers';

import { ThemeService, ITheme } from '@Services/index';

import { ThemeTableProps } from './ThemeTable.props';

export const ThemeTable = ({ className }: ThemeTableProps) => {
	const { t } = useTranslation();

	return (
		<Table.Resource<ITheme> service={ThemeService}>
			<Table.Field.Text sortable filterable source="name" label={t('THEME.LABELS.NAME')} />

			<Table.Field.Text source="theme_group.name" label={t('THEME.LABELS.THEME_GROUP')} />

			<Table.Field.ResourceAction
				editConfig={(record: ITheme) => ({
					link: `/themes/${record.id}`,
				})}
				deleteConfig={(record: ITheme) => ({
					name: record.name,
				})}
			/>
		</Table.Resource>
	);
};
