import { useEffect } from 'react';
import { useStores } from '@Hooks/index';

function useMobile() {
	const { ThemeStore } = useStores();

	const isWindowClient = typeof window === 'object';

	useEffect(() => {
		function setSize() {
			ThemeStore.setIsMobile(window.innerWidth < 992);
		}

		if (isWindowClient) {
			window.addEventListener('load', setSize);

			return () => window.removeEventListener('load', setSize);
		}
	}, [isWindowClient, ThemeStore]);

	return false;
}

export default useMobile;
