import { createContext, useContext, Context } from 'react';
import { AuthServiceInterface, AccountServiceInterface } from '@socialbrothers/constants';

let themeContext: Context<IThemeContext>;

const getContext = (context?: IThemeContext): Context<IThemeContext> => {
	if (!themeContext && context) {
		themeContext = createContext(context);
	}

	return themeContext;
};

export const ThemeProvider = ({ context, children }: { context: IThemeContext; children: any }) => {
	const ThemeContext = getContext(context);

	return <ThemeContext.Provider value={context}>{children}</ThemeContext.Provider>;
};

export const useTheme = () => {
	return useContext(getContext());
};

export interface IThemeContext {
	authService: AuthServiceInterface;
	accountService: AccountServiceInterface;
	config: {
		loginImage: any;
		forgotPasswordImage: any;
		logo: any;
		passwordRegex: RegExp;
	};
}
