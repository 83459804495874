import * as Yup from 'yup';
import cn from 'classnames';
import { debounce } from 'lodash';
import { forwardRef, useCallback, useEffect, useImperativeHandle } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { FIELDS, KeyValues, OPERATORS } from '@socialbrothers/constants';
import { useTable, useToggle } from '@socialbrothers/hooks';
import { getFilterableFields } from '@socialbrothers/utils';
import { Icon } from '@socialbrothers/components/UI';
import { Form } from '@socialbrothers/components/Containers';

import styles from './Filter.module.scss';

const validationSchema = Yup.object().shape({
	filter: Yup.array(
		Yup.object().shape({
			field: Yup.string().required(),
			value: Yup.string().required(),
		}),
	),
});

const Filter = forwardRef((props, ref) => {
	const { t } = useTranslation();
	const table = useTable();
	const { isToggle, toggle, setToggle } = useToggle(true);

	const methods = useForm({
		mode: 'onChange',
		reValidateMode: 'onChange',
		resolver: yupResolver(validationSchema),
	});

	const { fields, prepend, remove } = useFieldArray({
		control: methods.control,
		name: 'filter',
	});

	const values = methods.watch('filter');
	const valuesJSON = JSON.stringify(values);

	useImperativeHandle(ref, () => ({
		append(value = {}) {
			setToggle(true);
			prepend(value);
		},
	}));

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debounceFilters = useCallback(
		debounce(() => methods.handleSubmit(onSubmit)(), 500),
		[methods.handleSubmit],
	);

	useEffect(() => {
		debounceFilters();
	}, [debounceFilters, valuesJSON]);

	/**
	 * Saves the filter(s) to the store and update the query with
	 * the new data.
	 *
	 * @param data
	 */
	const onSubmit = (data: any) => {
		table.filter.setFilter(data.filter);
	};

	/**
	 * Gets an key value object of operators that match the given field
	 * type found by the source.
	 *
	 * @param source
	 * @returns { key: value }
	 */
	const getOperator = (source: string) => {
		const field = table.fields.find((field) => field.source === source);

		if (field) {
			switch (field.type) {
				case FIELDS.BOOLEAN_FIELD:
					return [
						{
							key: OPERATORS.IS,
							value: t('TABLE.HEADER.FILTER.OPERATORS.IS'),
						},
					];
				case FIELDS.DATE_FIELD:
					return [
						{ key: OPERATORS.LOWER_THAN, value: t('TABLE.HEADER.FILTER.OPERATORS.LOWER_THAN') },
						{ key: OPERATORS.GREATER_THAN, value: t('TABLE.HEADER.FILTER.OPERATORS.GREATER_THAN') },
						{
							key: OPERATORS.LOWER_THAN_OR_EQUAL,
							value: t('TABLE.HEADER.FILTER.OPERATORS.LOWER_THAN_OR_EQUAL'),
						},
						{
							key: OPERATORS.GREATER_THAN_OR_EQUAL,
							value: t('TABLE.HEADER.FILTER.OPERATORS.GREATER_THAN_OR_EQUAL'),
						},
					];
				case FIELDS.NUMBER_FIELD:
					return [
						{ key: OPERATORS.IS, value: t('TABLE.HEADER.FILTER.OPERATORS.IS') },
						{ key: OPERATORS.IS_NOT, value: t('TABLE.HEADER.FILTER.OPERATORS.IS_NOT') },
						{ key: OPERATORS.LOWER_THAN, value: t('TABLE.HEADER.FILTER.OPERATORS.LOWER_THAN') },
						{ key: OPERATORS.GREATER_THAN, value: t('TABLE.HEADER.FILTER.OPERATORS.GREATER_THAN') },
						{
							key: OPERATORS.LOWER_THAN_OR_EQUAL,
							value: t('TABLE.HEADER.FILTER.OPERATORS.LOWER_THAN_OR_EQUAL'),
						},
						{
							key: OPERATORS.GREATER_THAN_OR_EQUAL,
							value: t('TABLE.HEADER.FILTER.OPERATORS.GREATER_THAN_OR_EQUAL'),
						},
					];
				default:
					return [
						{ key: OPERATORS.IS, value: t('TABLE.HEADER.FILTER.OPERATORS.IS') },
						{ key: OPERATORS.IS_NOT, value: t('TABLE.HEADER.FILTER.OPERATORS.IS_NOT') },
					];
			}
		}

		return [];
	};

	/**
	 * Gets an form input element that matches the given field found
	 * by the source.
	 *
	 * @param source
	 * @param index
	 * @returns JSX.Element
	 */
	const getField = (source: string, index: number) => {
		const field = table.fields.find((field) => field.source === source);
		const name = `filter.${index}.value`;

		const booleanOptions = [
			{ key: 1, value: t('TABLE.HEADER.FILTER.TRUE') },
			{ key: 0, value: t('TABLE.HEADER.FILTER.FALSE') },
		];

		if (field) {
			switch (field.type) {
				case FIELDS.DATE_FIELD:
					return <Form.Input.Date name={name} />;
				case FIELDS.BOOLEAN_FIELD:
					return <Form.Input.Select name={name} options={booleanOptions} />;
				case FIELDS.ENUM_FIELD:
					const values: KeyValues = Object.values(field.enumeration).map((value: any) => {
						return {
							key: value,
							value: '' + t(`ENUM.${field.name}.${value}`),
						};
					});

					return <Form.Input.Select name={name} options={values} />;
			}
		}

		return <Form.Input.Text name={name} />;
	};

	if (fields.length > 0) {
		return (
			<div className={styles.FilterWrapper} {...props}>
				<FormProvider {...methods}>
					<div className={styles.Toggle} onClick={toggle}>
						{isToggle
							? t('TABLE.HEADER.FILTER.HIDE', { count: fields.length })
							: t('TABLE.HEADER.FILTER.SHOW', { count: fields.length })}
					</div>

					<form
						onSubmit={methods.handleSubmit(onSubmit)}
						className={cn(styles.Filter, {
							[styles['Filter--Hidden']]: !isToggle,
						})}>
						{fields.map((field, index) => (
							<Form.Layout.Row key={field.id} className={styles.Filter__Row}>
								<Form.Layout.Field label={t('TABLE.HEADER.FILTER.NAME')}>
									<Form.Input.Select
										name={`filter.${index}.field`}
										options={getFilterableFields(table.fields)}
									/>
								</Form.Layout.Field>

								<Form.Layout.Field label={t('TABLE.HEADER.FILTER.OPERATOR')}>
									<Form.Input.Select
										name={`filter.${index}.operator`}
										options={getOperator(values[index]?.field)}
									/>
								</Form.Layout.Field>

								<Form.Layout.Field label={t('TABLE.HEADER.FILTER.VALUE')}>
									{getField(values[index]?.field, index)}
								</Form.Layout.Field>

								<div className={styles.Filter__Delete} onClick={() => remove(index)}>
									<Icon icon="trash-alt" />
								</div>
							</Form.Layout.Row>
						))}
					</form>
				</FormProvider>
			</div>
		);
	}

	return <div {...props}></div>;
});

export default observer(Filter);
