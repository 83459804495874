import { get } from 'lodash';

import { Icon } from '@socialbrothers/components/UI';

import { BooleanFieldProps } from './BooleanField.props';

const BooleanField = ({
	source,
	record,
	className,
	iconTrue = 'check',
	iconFalse = 'times',
	...props
}: BooleanFieldProps) => {
	const value = get(record, source);

	return (
		<div className={className} {...props}>
			<Icon icon={value ? iconTrue : iconFalse} />
		</div>
	);
};

BooleanField.displayName = 'BooleanField';

export default BooleanField;
