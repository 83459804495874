import { useTranslation } from 'react-i18next';

import { useModal } from '@socialbrothers/hooks';
import { Button, Modal } from '@socialbrothers/components/UI';
import { Form } from '@socialbrothers/components/Containers';
import { COLOR } from '@socialbrothers/constants';
import { serialize } from '@socialbrothers/utils';

import { ButtonWithPopupProps } from './ButtonWithPopup.props';

export const ButtonWithPopup = ({
	popup,
	submit,
	button,
	children,
	withForm,
	validationSchema,
	initialValues,
	withClose = true,
}: ButtonWithPopupProps) => {
	const { t } = useTranslation();
	const { onOpen, onClose, isVisible } = useModal();

	const handleSubmit = async (values?: any) => {
		if (submit && submit.onClick) {
			await submit.onClick(serialize(values));
			onClose();
		}
	};

	return (
		<>
			<Button type="button" onClick={onOpen} {...button} />

			<Modal title={popup.title} onClose={withClose ? onClose : undefined} isVisible={isVisible}>
				{withForm && (
					<Form.Base
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={handleSubmit}>
						{children}

						<hr />

						<div className="d-flex justify-content-end">
							{withClose && (
								<Button type="button" onClick={onClose} color={COLOR.GRAY}>
									{t('GLOBAL.CANCEL')}
								</Button>
							)}

							{submit && <Button {...submit} onClick={undefined} type="submit" />}
						</div>
					</Form.Base>
				)}

				{!withForm && (
					<>
						{children}

						<hr />

						<div className="d-flex justify-content-end">
							{withClose && (
								<Button type="button" onClick={onClose} color={COLOR.GRAY}>
									{t('GLOBAL.CANCEL')}
								</Button>
							)}

							{submit && <Button {...submit} onClick={handleSubmit} />}
						</div>
					</>
				)}
			</Modal>
		</>
	);
};
