import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { useResource } from '@socialbrothers/hooks';
import { Page } from '@socialbrothers/components/Layout';
import { Card } from '@socialbrothers/components/UI';

import { MomentService } from '@Services/index';
import { MomentForm } from '@Components/forms';

export const MomentDetailPage = () => {
	const { t } = useTranslation();
	const { id } = useParams<any>();
	const { data } = useResource(MomentService, id);

	const label = data?.title || t('MOMENT.SINGLE');

	return (
		<Page title={label}>
			<Card title={label}>
				<MomentForm />
			</Card>
		</Page>
	);
};
