import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import { useResource } from '@socialbrothers/hooks';
import { ButtonWithPopup, Form } from '@socialbrothers/components/Containers';

import { CompanyService } from '@Services/index';

import { ModulesLNIFormProps, ModulesLNIProps } from './ModulesLNI.props';

export const ModulesLNI = ({ id, record }: ModulesLNIProps) => {
	const { t } = useTranslation();
	const { data } = useResource(CompanyService, id);
	const queryClient = useQueryClient();

	const mutateUpdateModule = useMutation(
		(values: ModulesLNIFormProps) => CompanyService.updateModule(id, record.id, values),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(CompanyService.endpoint);
				queryClient.invalidateQueries([CompanyService.endpoint, id]);

				toast.success(t('GLOBAL.UPDATED_SUCCESSFUL', { name: t('ENUM.MODULE.LNI') }));
			},
		},
	);

	return (
		<ButtonWithPopup
			withForm
			initialValues={{
				...data.lni_settings,
				slug: `https://mijn.leiderschapeninzetbaarheid.nl/aanmelden/${data.slug}`,
			}}
			button={{
				icon: 'pencil',
			}}
			submit={{
				label: t('GLOBAL.SAVE'),
				onClick: (values: ModulesLNIFormProps) => {
					return mutateUpdateModule.mutateAsync(values);
				},
			}}
			popup={{
				title: t('ENUM.MODULE.LNI'),
			}}>
			<Form.Layout.Field label={t('COMPANY.MODULE.COURSES.MOMENTS_ENABLED')}>
				<Form.Input.Checkbox
					options={[
						{
							key: 1,
							value: String(t('COMPANY.MODULE.COURSES.MOMENTS_ENABLED_VALUE')),
						},
					]}
					name="moments_enabled"
				/>
			</Form.Layout.Field>

			<Form.Layout.Field label={t('COMPANY.MODULE.COURSES.ADVISOR_ENABLED')}>
				<Form.Input.Checkbox
					options={[
						{
							key: 1,
							value: String(t('COMPANY.MODULE.COURSES.ADVISOR_ENABLED_VALUE')),
						},
					]}
					name="advisor_enabled"
				/>
			</Form.Layout.Field>

			<Form.Layout.Field label={t('COMPANY.MODULE.COURSES.MAIL_WELCOME_INTRO')}>
				<Form.Input.RichText name="mail_welcome_intro" />
			</Form.Layout.Field>
		</ButtonWithPopup>
	);
};
