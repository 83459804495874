import { get } from 'lodash';

import { TextFieldProps } from './TextField.props';

const TextField = ({ source, record, className, ...props }: TextFieldProps) => {
	const value = get(record, source);

	return (
		<div className={className} {...props}>
			{value ? value : '-'}
		</div>
	);
};

TextField.displayName = 'TextField';

export default TextField;
