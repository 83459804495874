import React from 'react';
import { useTranslation } from 'react-i18next';

import { Page } from '@socialbrothers/components/Layout';
import { Card } from '@socialbrothers/components/UI';
import { MyAccountForm } from '@socialbrothers/components/Forms';
import { MyAccountPageProps } from './MyAccountPage.props';

const MyAccountPage: React.FC<MyAccountPageProps> = () => {
	const { t } = useTranslation();

	return (
		<Page title={t('ACCOUNT.TITLE')}>
			<Card title="Account">
				<MyAccountForm />
			</Card>
		</Page>
	);
};

export default MyAccountPage;
