import GLOBAL from './global.json';
import AUTHENTICATION from './authentication.json';
import TABLE from './table.json';
import RESOURCE from './resource.json';
import FORMS from './forms.json';
import COMPONENTS from './components.json';
import VALIDATION from './validation.json';
import ACCOUNT from './account.json';
import FORGOT_PASSWORD from './forgotPassword.json';
import RESET_PASSWORD from './resetPassword.json';

const data = {
	translation: {
		AUTHENTICATION,
		GLOBAL,
		TABLE,
		RESOURCE,
		FORMS,
		VALIDATION,
		FORGOT_PASSWORD,
		ACCOUNT,
		COMPONENTS,
		RESET_PASSWORD,
	},
};

export default data;
