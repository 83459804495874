import { createContext, useContext } from 'react';

import { IQuestionnaire } from '@Services/QuestionnaireService';

export const QuestionnaireConfiguratorContext = createContext<null | IQuestionnaire>(null);
export const QuestionnaireConfiguratorProvider = QuestionnaireConfiguratorContext.Provider;

export function useQuestionnaireConfigurator(): IQuestionnaire {
	const context = useContext(QuestionnaireConfiguratorContext);

	if (context === null) {
		throw new Error(
			'QuestionnaireConfiguratorContext cannot be null, please add a context provider',
		);
	}

	return context;
}
