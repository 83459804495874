import { useTranslation } from 'react-i18next';

import { Card } from '@socialbrothers/components/UI';

import { QuestionAnswerTable } from './internal/QuestionAnswerTable/QuestionAnswerTable';
import { QuestionAnswerForm } from './internal/QuestionAnswerForm/QuestionAnswerForm';

import { QuestionAnswersProps } from './QuestionAnswers.props';

export const QuestionAnswers = ({ answers, questionId, module }: QuestionAnswersProps) => {
	const { t } = useTranslation();

	const label = t('QUESTION.ANSWERS.PLURAL');

	return (
		<Card title={label}>
			<div className="mb-sm text-right">
				<QuestionAnswerForm questionId={questionId} module={module} />
			</div>

			<QuestionAnswerTable answers={answers} questionId={questionId} module={module} />
		</Card>
	);
};
