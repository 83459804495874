import { ChildResourceService, ResourceService } from '@socialbrothers/services';
import { BaseModel } from '@socialbrothers/constants';

import MenselyService from './MenselyService';
import { QUESTIONNAIRE_TYPE } from './QuestionnaireService';

export enum QUESTION_TYPE {
	OPEN = 'open',
	OPEN_SHORT = 'open_short',
	SINGLE = 'single',
	SINGLE_DROPDOWN = 'single_dropdown',
	SCALE = 'scale',
	SCALE_SLIDER = 'scale_slider',
	SCALE_EMOJI = 'scale_emoji',
	MULTIPLE = 'multiple',
	NUMBER = 'number',
	STATEMENT = 'statement',
	DATE = 'date',
}

export enum QUESTION_ANSWER_URGENCY {
	HIGH = 'HIGH',
	MEDIUM = 'MEDIUM',
	LOW = 'LOW',
}

export interface IQuestionAnswerAction {
	goal: string;
	action: string;
	advice: string;
	urgency: QUESTION_ANSWER_URGENCY;
}

export interface IQuestionAnswer extends BaseModel {
	text: string;
	order: number;
	group?: string;
	open_input: boolean;
	is_open: boolean;
	theme_id?: number;
	action: IQuestionAnswerAction;
	additional_questionnaire_id: number;
}

export interface IQuestion extends BaseModel {
	title: string;
	text: string;
	type: QUESTION_TYPE;
	answers_required: number;
	scale_low?: string;
	scale_high?: string;
	module: QUESTIONNAIRE_TYPE;
	answers: IQuestionAnswer[];
}

export class QuestionServiceImplementation extends ResourceService<IQuestion> {}

export const QuestionService = new QuestionServiceImplementation(
	MenselyService,
	'/admin/questions',
);
export class QuestionAnswerServiceImplementation extends ChildResourceService<
	IQuestionAnswer,
	IQuestion
> {
	moveUp = async (questionId: number, answerId: number) => {
		const response = await this.client.post<IQuestion>(
			`${this.parentEndpoint}/${questionId}/${this.endpoint}/${answerId}/up`,
		);

		return response.data;
	};

	moveDown = async (questionId: number, answerId: number) => {
		const response = await this.client.post<IQuestion>(
			`${this.parentEndpoint}/${questionId}/${this.endpoint}/${answerId}/down`,
		);

		return response.data;
	};
}

export const QuestionAnswerService = new QuestionAnswerServiceImplementation(
	MenselyService,
	'/admin/questions',
	'answers',
);
