import {
	APIS,
	LoginConfig,
	LoginResponse,
	ResendConfig,
	ResendResponse,
	TokenResponse,
} from '@socialbrothers/constants';
import { Storage } from '@socialbrothers/helpers';
import { BaseService } from '@socialbrothers/services';

import MenselyService from './MenselyService';

class AuthService {
	service: BaseService;

	constructor(service: BaseService) {
		this.service = service;
	}

	login = async (credentials: LoginConfig): Promise<LoginResponse> => {
		const response = await this.service.post<LoginResponse>('/api/v1/auth/login', credentials);
		return response.data;
	};

	resend = async (values: ResendConfig): Promise<ResendResponse> => {
		const response = await this.service.post<ResendResponse>('/api/v1/auth/resend', values);
		return response.data;
	};

	token = async (values: { sms_token: string; code: string }): Promise<TokenResponse> => {
		const response = await this.service.post<TokenResponse>('/api/v1/auth/token', values);

		Storage.setAccessToken(APIS.MAIN, {
			token: response.data.access_token,
			expirationDate: '2030-01-01',
		});

		this.service.setAccessToken(response.data.access_token);

		return response.data;
	};

	logout = async () => {
		Storage.removeAccessToken(APIS.MAIN);
		this.service.removeAccessToken();

		return true;
	};

	resetPassword = async (
		email: string,
		token: string,
		newPassword: string,
		newPasswordConfirmation: string,
	) => {
		const result = await this.service.post('/api/v1/auth/reset_password', {
			email,
			token,
			password: newPassword,
			password_confirmation: newPasswordConfirmation,
		});

		return result.data;
	};

	forgotPassword = async (email: string) => {
		const result = await this.service.post(
			'/api/v1/auth/forgot_password',
			{
				email,
			},
			{
				headers: {
					'x-module': 'DASHBOARD',
				},
			},
		);

		return result.data;
	};
}

export default new AuthService(MenselyService);
