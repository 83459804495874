import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useList } from '@socialbrothers/hooks';
import { OPERATORS } from '@socialbrothers/constants';
import { Form } from '@socialbrothers/components/Containers';
import { modelToOptions } from '@socialbrothers/utils';

import {
	AOMService,
	IQuestionnaire,
	QuestionnaireService,
	QUESTIONNAIRE_TYPE,
	IAliiQuestionnaire,
	AliiQuestionnaireService,
} from '@Services/index';

import { AOMSettingsFormProps } from './AOMSettingsForm.props';

export const AOMSettingsForm = ({ ...props }: AOMSettingsFormProps) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();

	const configuration = useQuery(AOMService.endpoint, () => AOMService.getConfiguration());

	const questionnaire = useList<IQuestionnaire>(QuestionnaireService, {
		sortBy: 'title',
		perPage: Number.MAX_SAFE_INTEGER,
		filters: {
			published: [
				{
					operator: OPERATORS.IS,
					value: true,
				},
			],
			type: [
				{
					operator: OPERATORS.IS,
					value: QUESTIONNAIRE_TYPE.AOM,
				},
			],
		},
	});

	const aliiQuestionnaire = useList<IAliiQuestionnaire>(AliiQuestionnaireService, {
		sortBy: 'title',
		perPage: Number.MAX_SAFE_INTEGER,
	});

	const mutateUpdateConfiguration = useMutation(AOMService.updateConfiguration, {
		onSuccess: () => {
			queryClient.invalidateQueries(AOMService.endpoint);

			toast.success(t('GLOBAL.UPDATED_SUCCESSFUL', { name: t('AOMSETTINGS.LABEL') }));
		},
		onError: () => {
			toast.error(t('GLOBAL.UPDATED_UNSUCCESSFUL', { name: t('AOMSETTINGS.LABEL') }));
		},
	});

	const validationSchema = Yup.object().shape({
		vos_questionnaire_id: Yup.string().required(),
	});

	if (configuration?.data && questionnaire.data?.data && aliiQuestionnaire.data?.data) {
		return (
			<Form.Base
				validationSchema={validationSchema}
				initialValues={configuration.data}
				submitLabel={t('GLOBAL.SAVE')}
				onSubmit={(values: any) => mutateUpdateConfiguration.mutateAsync(values)}>
				<Form.Layout.Field label={t('AOMSETTINGS.LABELS.VOS_QUESTIONNAIRE_ID')}>
					<Form.Input.Select
						name="vos_questionnaire_id"
						options={modelToOptions(questionnaire.data?.data, 'title')}
					/>
				</Form.Layout.Field>
			</Form.Base>
		);
	}

	return <></>;
};
