import { get } from 'lodash';
import { useTranslation } from 'react-i18next';

import { EnumFieldProps } from './EnumField.props';

const EnumField = ({ source, record, name, enumeration, className, ...props }: EnumFieldProps) => {
	const { t } = useTranslation();
	const value = get(record, source);

	return (
		<div className={className} {...props}>
			{t(`ENUM.${name}.${value}`)}
		</div>
	);
};

EnumField.displayName = 'EnumField';

export default EnumField;
