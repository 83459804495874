import { get } from 'lodash';

import { EmailFieldProps } from './EmailField.props';

const EmailField = ({ source, record, className, ...props }: EmailFieldProps) => {
	const value = get(record, source);

	return (
		<div className={className} {...props}>
			<a className="link" target="_blank" href={`mailto:${value}`} rel="noopener noreferrer">
				{value}
			</a>
		</div>
	);
};

EmailField.displayName = 'EmailField';

export default EmailField;
