import { useState } from 'react';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import { useList, useResource } from '@socialbrothers/hooks';
import { ButtonWithPopup, Form } from '@socialbrothers/components/Containers';
import { modelToOptions } from '@socialbrothers/utils';
import { OPERATORS } from '@socialbrothers/constants';

import {
	CompanyService,
	IQuestionnaire,
	QuestionnaireService,
	QUESTIONNAIRE_TYPE,
} from '@Services/index';

import { ModulesPNIFormProps, ModulesPNIProps } from './ModulesPNI.props';

export const ModulesPNI = ({ id, record }: ModulesPNIProps) => {
	const { t } = useTranslation();
	const [vosEnabled, setVosEnabled] = useState(record);
	const queryClient = useQueryClient();
	const company = useResource(CompanyService, id);

	const questionnaire = useList<IQuestionnaire>(QuestionnaireService, {
		sortBy: 'title',
		perPage: Number.MAX_SAFE_INTEGER,
		filters: {
			published: [
				{
					operator: OPERATORS.IS,
					value: true,
				},
			],
			type: [
				{
					operator: OPERATORS.IS,
					value: QUESTIONNAIRE_TYPE.AOM,
				},
			],
		},
	});

	const mutateUpdateModule = useMutation(
		(values: any) => CompanyService.updateModule(id, record.id, values),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(CompanyService.endpoint);
				queryClient.invalidateQueries([CompanyService.endpoint, id]);

				toast.success(t('GLOBAL.UPDATED_SUCCESSFUL', { name: t('ENUM.MODULE.PNI') }));
			},
		},
	);

	const initialValues = {
		vos_enabled: company?.data?.pni_settings?.vos_enabled,
		vos_questionnaire_id: company?.data?.pni_settings?.vos_questionnaire_id,
	};

	const validationSchema = Yup.object().shape({
		vos_questionnaire_id: vosEnabled ? Yup.string().required() : Yup.string(),
	});

	return (
		<ButtonWithPopup
			withForm
			initialValues={initialValues}
			validationSchema={validationSchema}
			button={{
				icon: 'pencil',
			}}
			submit={{
				label: t('GLOBAL.SAVE'),
				onClick: async (values: ModulesPNIFormProps) => {
					return mutateUpdateModule.mutateAsync(values);
				},
			}}
			popup={{
				title: t('ENUM.MODULE.PNI'),
			}}>
			<Form.Layout.Field
				label={t('COMPANY.MODULE.PNI.VOS_ENABLED')}
				onChange={(value) => {
					if (value !== vosEnabled) {
						setVosEnabled(value);
					}
				}}>
				<Form.Input.Checkbox
					name="vos_enabled"
					options={[{ key: 1, value: String(t('GLOBAL.YES')) }]}
				/>
			</Form.Layout.Field>

			{vosEnabled && (
				<Form.Layout.Field label={t('COMPANY.MODULE.PNI.VOS_QUESTIONNAIRE_ID')}>
					<Form.Input.Select
						name="vos_questionnaire_id"
						options={questionnaire.data?.data && modelToOptions(questionnaire.data?.data, 'title')}
					/>
				</Form.Layout.Field>
			)}
		</ButtonWithPopup>
	);
};
