import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import { useList, useResource } from '@socialbrothers/hooks';
import { ButtonWithPopup, Form, Table } from '@socialbrothers/components/Containers';
import { COLOR, KeyValue, OPERATORS } from '@socialbrothers/constants';
import { modelToOptions } from '@socialbrothers/utils';

import {
	RoleService,
	CompanyService,
	QuestionnaireService,
	IQuestionnaire,
	IRole,
	ICompanyOOMRoles,
	QUESTIONNAIRE_TYPE,
} from '@Services/index';

import {
	ModulesOOMRolesPopupProps,
	ModulesOOMRolesPopupFormProps,
	ModulesOOMRolesProps,
} from './ModulesOOMRoles.props';

const ModuleOOMRolesForm = ({ id, isEdit, record }: ModulesOOMRolesPopupProps) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const company = useResource(CompanyService, id);

	const roles = useList<IRole>(RoleService, {
		perPage: Number.MAX_SAFE_INTEGER,
	});

	const questionnaire = useList<IQuestionnaire>(QuestionnaireService, {
		sortBy: 'title',
		perPage: Number.MAX_SAFE_INTEGER,
		filters: {
			published: [
				{
					operator: OPERATORS.IS,
					value: true,
				},
			],
			type: [
				{
					operator: OPERATORS.IS,
					value: QUESTIONNAIRE_TYPE.OOM,
				},
			],
		},
	});

	const mutateCreateRole = useMutation(
		(values: ModulesOOMRolesPopupFormProps) => CompanyService.createRole(id, values),
		{
			onSuccess: () => {
				queryClient.invalidateQueries([CompanyService.endpoint, id]);

				toast.success(
					t('GLOBAL.CREATED_SUCCESSFUL', {
						name: t('COMPANY.MODULE.OOM.ROLE'),
					}),
				);
			},
		},
	);

	const mutateEditRole = useMutation(
		(values: ModulesOOMRolesPopupFormProps) =>
			CompanyService.updateRole(id, record?.id || 0, { ...values, ...{ id: record?.id } }),
		{
			onSuccess: () => {
				queryClient.invalidateQueries([CompanyService.endpoint, id]);

				toast.success(
					t('GLOBAL.UPDATED_SUCCESSFUL', {
						name: t('COMPANY.MODULE.OOM.ROLE'),
					}),
				);
			},
		},
	);

	const validationSchema = Yup.object().shape({
		oom_role_id: isEdit ? Yup.string() : Yup.string().required(),
		questionnaire_id: Yup.string().required(),
	});

	return (
		<ButtonWithPopup
			withForm
			initialValues={record}
			validationSchema={validationSchema}
			button={
				isEdit
					? {
							icon: 'pencil',
							color: COLOR.PRIMARY,
					  }
					: {
							icon: 'plus',
							color: COLOR.SECONDARY,
							label: t('COMPANY.MODULE.OOM.ADD_ROLE'),
					  }
			}
			submit={{
				label: t('GLOBAL.SAVE'),
				onClick: (values: ModulesOOMRolesPopupFormProps) => {
					if (isEdit) {
						return mutateEditRole.mutateAsync(values);
					} else {
						return mutateCreateRole.mutateAsync(values);
					}
				},
			}}
			popup={{
				title: isEdit
					? t('COMPANY.MODULE.OOM.EDIT_ROLE', { name: record?.role?.name })
					: t('COMPANY.MODULE.OOM.ADD_ROLE'),
			}}>
			{!isEdit && (
				<Form.Layout.Field
					label={t('COMPANY.MODULE.OOM.ROLE')}
					onChange={(value, control) => {
						const found = roles?.data?.data?.find(
							(companyRole: any) => parseInt(value) === companyRole.id,
						);

						if (found) {
							control.setValue('questionnaire_id', found.base_questionnaire.id);
						}
					}}>
					<Form.Input.Select
						name="oom_role_id"
						options={
							roles.data?.data &&
							modelToOptions(roles?.data?.data, 'name').filter((role: KeyValue) => {
								const exists = company?.data?.oom_roles?.some(
									(companyRole: any) => role.key === companyRole.oom_role_id,
								);

								role.disabled = exists;

								return role;
							})
						}
					/>
				</Form.Layout.Field>
			)}

			<Form.Layout.Field label={t('COMPANY.MODULE.OOM.QUESTIONNAIRE_ID')}>
				<Form.Input.Select
					name="questionnaire_id"
					options={questionnaire.data?.data && modelToOptions(questionnaire?.data?.data, 'title')}
				/>
			</Form.Layout.Field>

			<Form.Layout.Field label={t('COMPANY.MODULE.OOM.INVITE_MAIL_INTRO')}>
				<Form.Input.RichText name="invite_mail_intro" />
			</Form.Layout.Field>

			<Form.Layout.Field label={t('COMPANY.MODULE.OOM.INVITE_MAIL_OUTRO')}>
				<Form.Input.RichText name="invite_mail_outro" />
			</Form.Layout.Field>
		</ButtonWithPopup>
	);
};

export const ModulesOOMRoles = ({ id }: ModulesOOMRolesProps) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const company = useResource(CompanyService, id);

	const mutateDeleteRole = useMutation((roleId: number) => CompanyService.deleteRole(id, roleId), {
		onSuccess: () => {
			queryClient.invalidateQueries(CompanyService.endpoint);
			queryClient.invalidateQueries([CompanyService.endpoint, id]);

			toast.success(t('GLOBAL.DELETED_SUCCESSFUL', { name: t('COMPANY.MODULE.OOM.ROLE') }));
		},
	});

	return (
		<ButtonWithPopup
			button={{
				icon: 'user-tag',
			}}
			popup={{
				title: t('ENUM.MODULE.OOM'),
			}}>
			<>
				<div className="mb-sm d-flex">
					<div className="ml-auto">
						<ModuleOOMRolesForm id={id} />
					</div>
				</div>

				<Table.Base data={company?.data.oom_roles}>
					<Table.Field.Function
						label={t('COMPANY.MODULE.OOM.ROLE')}
						source="id"
						render={(record: ICompanyOOMRoles) => record.role.name}
					/>

					<Table.Field.BaseAction>
						<Table.Action.Field
							render={(record: ICompanyOOMRoles) => (
								<ModuleOOMRolesForm id={id} record={record} isEdit />
							)}
						/>

						<Table.Action.Button
							icon="trash-alt"
							color={COLOR.DANGER}
							onClick={(record: ICompanyOOMRoles) => {
								mutateDeleteRole.mutateAsync(record.id);
							}}
						/>
					</Table.Field.BaseAction>
				</Table.Base>
			</>
		</ButtonWithPopup>
	);
};
