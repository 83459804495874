import { FunctionFieldProps } from './FunctionField.props';

const FunctionField = ({ source, record, className, render, ...props }: FunctionFieldProps) => {
	return (
		<div className={className} {...props}>
			{render(record, source)}
		</div>
	);
};

FunctionField.displayName = 'FunctionField';

export default FunctionField;
