import { useTranslation } from 'react-i18next';

import config from '@Config';
import { ForgotPasswordForm } from '@socialbrothers/components/Forms';
import { AuthWrapper } from '@socialbrothers/components/Layout';

interface Props {}

const ForgotPasswordPage = ({ ...props }: Props) => {
	const { t } = useTranslation();

	const initialValues = {
		email: '',
		password: '',
	};

	return (
		<AuthWrapper
			imageUri={config.forgotPasswordImage}
			title={t('AUTHENTICATION.FORGOT_PASSWORD_PAGE_TITLE')}>
			<ForgotPasswordForm initialValues={initialValues} />
		</AuthWrapper>
	);
};

export default ForgotPasswordPage;
