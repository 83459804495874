import * as Yup from 'yup';
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useList } from '@socialbrothers/hooks';
import { Form } from '@socialbrothers/components/Containers';
import { enumToOptions, modelToOptions } from '@socialbrothers/utils';
import { CompanyService, ICompany, MessageService, ACCOUNT_TYPE } from '@Services/index';
import { KeyValue } from '@socialbrothers/constants';

import { MessageFormProps } from './MessageForm.props';
import { useQuery } from 'react-query';

export const MessageForm = ({ ...props }: MessageFormProps) => {
	const { t } = useTranslation();
	const history = useHistory();
	const [accountType, setAccountType] = useState<string>('');
	const [companyId, setCompanyId] = useState<string>('');

	const recipients = useQuery(
		[MessageService.endpoint, 'recipients', companyId, accountType],
		() => MessageService.recipients(companyId, accountType),
		{ enabled: !!companyId },
	);

	const companies = useList<ICompany>(CompanyService, {
		sortBy: 'name',
		perPage: Number.MAX_SAFE_INTEGER,
	});

	const handleSuccess = useCallback(() => {
		history.push('/messages');
	}, [history]);

	const validationSchema = Yup.object().shape({
		company_id: Yup.string().required(),
		title: Yup.string().required(),
		message: Yup.string().required(),
	});

	const handleChangeCompanyId = useCallback((value: string) => {
		setCompanyId(value);
	}, []);

	const handleChangeAccountType = useCallback((value: string) => {
		setAccountType(value);
	}, []);

	return (
		<Form.Resource
			service={MessageService}
			validationSchema={validationSchema}
			label={t('MESSAGE.SINGLE')}
			onSuccess={handleSuccess}>
			{companies?.data?.data && (
				<Form.Layout.Field onChange={handleChangeCompanyId} label={t('MESSAGE.LABELS.COMPANY_ID')}>
					<Form.Input.Select
						name="company_id"
						options={modelToOptions(companies.data.data, 'name')}
					/>
				</Form.Layout.Field>
			)}

			<Form.Layout.Field
				onChange={handleChangeAccountType}
				label={t('MESSAGE.LABELS.ACCOUNT_TYPE')}>
				<Form.Input.Select
					name="account_type"
					placeholder={t('ENUM.ACCOUNT_TYPE.null')}
					options={enumToOptions(ACCOUNT_TYPE, 'ACCOUNT_TYPE').filter(
						(accountType: KeyValue) => accountType.key !== 'App\\Models\\Admin',
					)}
				/>
			</Form.Layout.Field>

			<Form.Layout.Field label={t('MESSAGE.LABELS.TITLE')}>
				<Form.Input.Text name="title" />
			</Form.Layout.Field>

			<Form.Layout.Field label={t('MESSAGE.LABELS.MESSAGE')}>
				<Form.Input.RichText name="message" />
			</Form.Layout.Field>

			<p>
				{(recipients.isSuccess || recipients.isLoading) &&
					t('MESSAGE.RECIPIENTS.RESULT', {
						value: recipients.data ?? '...',
					})}

				{recipients.isIdle && t('MESSAGE.RECIPIENTS.EMPTY')}

				{recipients.isError && t('MESSAGE.RECIPIENTS.ERROR')}
			</p>
		</Form.Resource>
	);
};
