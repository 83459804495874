import { COURSE_STEP_TYPE, IQuestion, QUESTION_TYPE } from '@Services/index';

export const shouldShowField = (type: QUESTION_TYPE, fieldName: keyof IQuestion) => {
	if (!type || !questionFields || !(type in questionFields)) {
		return false;
	}

	return questionFields[type]?.includes(fieldName);
};

const questionTypesWithoutAnswers = [
	QUESTION_TYPE.OPEN,
	QUESTION_TYPE.OPEN_SHORT,
	QUESTION_TYPE.DATE,
	QUESTION_TYPE.NUMBER,
];

export const shouldShowAnswers = (type: QUESTION_TYPE) => {
	return !questionTypesWithoutAnswers.includes(type);
};

type QuestionFields = {
	[key in QUESTION_TYPE]?: Array<keyof IQuestion>;
};

const questionFields: QuestionFields = {
	[QUESTION_TYPE.SCALE]: ['scale_high', 'scale_low'],
	[QUESTION_TYPE.MULTIPLE]: ['answers_required'],
};

export const shouldShowCourseStepField = (field: string, type: string): boolean => {
	switch (field) {
		case 'prepr_article_id':
			return [COURSE_STEP_TYPE.ARTICLE.toString()].includes(type);
		case 'assignable_id':
			return [COURSE_STEP_TYPE.QUESTIONNAIRE.toString()].includes(type);
		case 'contents':
			return [COURSE_STEP_TYPE.TEXT.toString(), COURSE_STEP_TYPE.VIDEO.toString()].includes(type);
	}

	return false;
};
