import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Table } from '@socialbrothers/components/Containers';

import { MomentService, IMoment, MOMENT_MOMENTABLE_TYPE } from '@Services/index';

import styles from './MomentTable.module.scss';
import { MomentTableProps } from './MomentTable.props';

export const MomentTable = ({ className }: MomentTableProps) => {
	const { t } = useTranslation();

	return (
		<Table.Resource<IMoment> service={MomentService} className={cn(styles.MomentTable, className)}>
			<Table.Field.Text sortable filterable source="title" label={t('MOMENT.LABELS.TITLE')} />

			<Table.Field.Enum
				sortable
				filterable
				source="momentable_type"
				enumeration={MOMENT_MOMENTABLE_TYPE}
				name="MOMENT_MOMENTABLE_TYPE"
				label={t('MOMENT.LABELS.TYPE')}
			/>

			<Table.Field.ResourceAction
				deleteConfig={(record: IMoment) => ({
					name: record.title,
				})}
				editConfig={(record: IMoment) => ({
					link: `/moments/${record.id}`,
				})}
			/>
		</Table.Resource>
	);
};
