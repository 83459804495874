import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import { ButtonWithPopup, Form } from '@socialbrothers/components/Containers';
import { YupUtils } from '@socialbrothers/utils';
import { IMAGE_FORMATS } from '@socialbrothers/constants';

import { QuestionnaireSectionService, QuestionnaireService } from '@Services/index';
import { useQuestionnaireConfigurator } from '@Hooks/index';

import { SectionFormFormProps, SectionFormProps } from './SectionForm.props';

export const SectionForm = ({ id, section }: SectionFormProps) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const questionnaire = useQuestionnaireConfigurator();

	const mutateCreate = useMutation(
		(values: SectionFormFormProps) => QuestionnaireSectionService.create(id, values),
		{
			onSuccess: () => {
				queryClient.invalidateQueries([QuestionnaireService.endpoint, id]);

				toast.success(
					t('GLOBAL.CREATED_SUCCESSFUL', { name: t('QUESTIONNAIRE.CONFIGURATOR.SECTION.SINGLE') }),
				);
			},

			onError: () => {
				toast.error(
					t('GLOBAL.DELETED_UNSUCCESSFUL', {
						name: t('QUESTIONNAIRE.CONFIGURATOR.SECTION.SINGLE'),
					}),
				);
			},
		},
	);

	const mutateUpdate = useMutation(
		(values: SectionFormFormProps) =>
			QuestionnaireSectionService.update(id, section?.id || 0, values),
		{
			onSuccess: () => {
				queryClient.invalidateQueries([QuestionnaireService.endpoint, id]);

				toast.success(
					t('GLOBAL.UPDATED_SUCCESSFUL', { name: t('QUESTIONNAIRE.CONFIGURATOR.SECTION.SINGLE') }),
				);
			},

			onError: () => {
				toast.error(
					t('GLOBAL.UPDATED_UNSUCCESSFUL', {
						name: t('QUESTIONNAIRE.CONFIGURATOR.SECTION.SINGLE'),
					}),
				);
			},
		},
	);

	const validationSchema = Yup.object().shape({
		title: Yup.string().required(),
		icon: questionnaire?.show_section_progress
			? Yup.mixed()
					.test(YupUtils.FileExtension([...Object.values(IMAGE_FORMATS)]))
					.test(YupUtils.FileSize(10))
					.required()
			: Yup.mixed()
					.test(YupUtils.FileExtension([...Object.values(IMAGE_FORMATS)]))
					.test(YupUtils.FileSize(10)),
	});

	return (
		<ButtonWithPopup
			withForm
			validationSchema={validationSchema}
			initialValues={section}
			button={
				section
					? { icon: 'pencil' }
					: {
							icon: 'plus',
							label: t('QUESTIONNAIRE.CONFIGURATOR.SECTION.CREATE'),
					  }
			}
			submit={{
				label: section ? t('GLOBAL.UPDATE') : t('GLOBAL.CREATE'),
				onClick: (values: SectionFormFormProps) => {
					return section ? mutateUpdate.mutateAsync(values) : mutateCreate.mutateAsync(values);
				},
			}}
			popup={{
				title: section
					? t('GLOBAL.UPDATE_MODEL', {
							name: section.title,
					  })
					: t('GLOBAL.CREATE_MODEL', {
							name: t('QUESTIONNAIRE.CONFIGURATOR.SECTION.SINGLE'),
					  }),
			}}>
			<Form.Layout.Field label={t('QUESTIONNAIRE.CONFIGURATOR.SECTION.LABELS.TITLE')}>
				<Form.Input.Text name="title" />
			</Form.Layout.Field>

			<Form.Layout.Field label={t('QUESTIONNAIRE.CONFIGURATOR.SECTION.LABELS.ICON')}>
				<Form.Input.File name="icon" />
			</Form.Layout.Field>
		</ButtonWithPopup>
	);
};
