import styles from './Card.module.scss';
import { CardProps } from './Card.props';

const Card = ({ title, children, ...props }: CardProps) => {
	return (
		<div className={styles.Card} {...props}>
			<div className={styles.Header}>{title}</div>

			<div className={styles.Body}>{children}</div>
		</div>
	);
};

export default Card;
