import BaseForm from './BaseForm/BaseForm';
import ResourceForm from './ResourceForm/ResourceForm';
import Group from './Group/Group';
import Row from './Row/Row';
import Field from './Field/Field';
import SelectInput from './SelectInput/SelectInput';
import RadioInput from './RadioInput/RadioInput';
import CheckboxInput from './CheckboxInput/CheckboxInput';
import MultilineInput from './MultilineInput/MultilineInput';
import TextInput from './TextInput/TextInput';
import DateInput from './DateInput/DateInput';
import FileInput from './FileInput/FileInput';
import Repeater from './Repeater/Repeater';
import RichTextInput from './RichTextInput/RichTextInput';
import NumberInput from './NumberInput/NumberInput';
import HiddenInput from './HiddenInput/HiddenInput';

const formCollection = {
	Layout: {
		Group,
		Row,
		Field,
		Repeater,
	},
	Input: {
		Select: SelectInput,
		Text: TextInput,
		Hidden: HiddenInput,
		Number: NumberInput,
		Radio: RadioInput,
		Checkbox: CheckboxInput,
		Multiline: MultilineInput,
		Date: DateInput,
		File: FileInput,
		RichText: RichTextInput,
	},
	Resource: ResourceForm,
	Base: BaseForm,
};

export default formCollection;
