import * as Yup from 'yup';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import { Form } from '@socialbrothers/components/Containers';
import { modelToOptions } from '@socialbrothers/utils';
import { useList } from '@socialbrothers/hooks';

import { CourseService, COURSE_TYPE, ITheme, ThemeService } from '@Services/index';

import { CourseFormProps } from './CourseForm.props';

export const CourseForm = ({ type, ...props }: CourseFormProps) => {
	const { id } = useParams<any>();
	const { t } = useTranslation();
	const themesQuery = useList<ITheme>(ThemeService);

	const validationSchema = Yup.object().shape({
		title: Yup.string().required(),
		introduction_text: Yup.string().required(),
		congratulation_text: Yup.string().required(),
		icon: Yup.string().required(),
		type: Yup.string().required(),
		theme_id: type === COURSE_TYPE.CHALLENGE ? Yup.string().required() : Yup.string(),
	});

	return (
		<Form.Resource
			validationSchema={validationSchema}
			service={CourseService}
			label={type === COURSE_TYPE.COURSE ? t('COURSE.COURSE.SINGLE') : t('COURSE.CHALLENGE.SINGLE')}
			deleteConfig={{
				redirect: type === COURSE_TYPE.COURSE ? '/courses' : '/challenges',
				name: 'title',
			}}
			id={id}>
			<Form.Layout.Field label={t('COURSE.LABELS.TITLE')}>
				<Form.Input.Text name="title" />
			</Form.Layout.Field>

			<Form.Layout.Field label={t('COURSE.LABELS.INTRODUCTION_TEXT')}>
				<Form.Input.Multiline name="introduction_text" />
			</Form.Layout.Field>

			<Form.Layout.Field label={t('COURSE.LABELS.CONGRATULATION_TEXT')}>
				<Form.Input.Multiline name="congratulation_text" />
			</Form.Layout.Field>

			<Form.Layout.Field
				label={t('COURSE.LABELS.ICON')}
				description={t('COURSE.LABELS.ICON_DESCRIPTION')}>
				<Form.Input.Text name="icon" />
			</Form.Layout.Field>

			{type === COURSE_TYPE.CHALLENGE && themesQuery.data?.data && (
				<Form.Layout.Field label={t('COURSE.LABELS.THEME')}>
					<Form.Input.Select
						options={modelToOptions(themesQuery.data?.data, 'name')}
						name="theme_id"
					/>
				</Form.Layout.Field>
			)}

			<Form.Input.Hidden name="type" defaultValue={type} />
		</Form.Resource>
	);
};
