import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import { COLOR, IMAGE_FORMATS } from '@socialbrothers/constants';
import { ButtonWithPopup, Form } from '@socialbrothers/components/Containers';
import { YupUtils } from '@socialbrothers/utils';

import { CompanyService, ICompanyContact } from '@Services/index';

import { ContactsCreateProps } from './ContactsCreate.props';

export const ContactsCreate = ({ id, record }: ContactsCreateProps) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();

	const mutateCreateContact = useMutation(
		(contact: ICompanyContact) => CompanyService.createContact(id, contact),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(CompanyService.endpoint);
				queryClient.invalidateQueries([CompanyService.endpoint, id]);

				toast.success(t('GLOBAL.CREATED_SUCCESSFUL', { name: t('COMPANY.CONTACTS.SINGLE') }));
			},

			onError: () => {
				toast.error(t('GLOBAL.CREATED_UNSUCCESSFUL', { name: t('COMPANY.CONTACTS.SINGLE') }));
			},
		},
	);

	const mutateUpdateContact = useMutation(
		(values: any) => CompanyService.updateContact(id, record?.id || 0, values),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(CompanyService.endpoint);
				queryClient.invalidateQueries([CompanyService.endpoint, id]);

				toast.success(t('GLOBAL.UPDATED_SUCCESSFUL', { name: t('COMPANY.CONTACTS.SINGLE') }));
			},

			onError: () => {
				toast.error(t('GLOBAL.UPDATED_UNSUCCESSFUL', { name: t('COMPANY.CONTACTS.SINGLE') }));
			},
		},
	);

	const validationSchema = Yup.object().shape({
		first_name: Yup.string().required(),
		last_name: Yup.string().required(),
		email: Yup.string().required(),
		phone_number: Yup.string().required(),
		function: Yup.string().required(),
		avatar: Yup.mixed()
			.test(YupUtils.FileExtension([...Object.values(IMAGE_FORMATS)]))
			.test(YupUtils.FileSize(10))
			.required(),
	});

	return (
		<ButtonWithPopup
			withForm
			validationSchema={validationSchema}
			button={{
				color: record ? COLOR.PRIMARY : COLOR.SECONDARY,
				icon: record ? 'pencil' : 'plus',
				label: record ? '' : t('COMPANY.CONTACTS.CREATE'),
			}}
			submit={{
				label: record ? t('GLOBAL.UPDATE') : t('GLOBAL.CREATE'),
				onClick: (values) => {
					if (record) {
						return mutateUpdateContact.mutateAsync(values);
					} else {
						return mutateCreateContact.mutateAsync(values);
					}
				},
			}}
			initialValues={record}
			popup={{
				title: record
					? t('GLOBAL.UPDATE_MODEL', { name: record.first_name })
					: t('GLOBAL.CREATE_MODEL', { name: t('COMPANY.CONTACTS.SINGLE') }),
			}}>
			<Form.Layout.Field label={t('COMPANY.CONTACTS.LABELS.FIRST_NAME')}>
				<Form.Input.Text name="first_name" />
			</Form.Layout.Field>

			<Form.Layout.Field label={t('COMPANY.CONTACTS.LABELS.LAST_NAME')}>
				<Form.Input.Text name="last_name" />
			</Form.Layout.Field>

			<Form.Layout.Field label={t('COMPANY.CONTACTS.LABELS.EMAIL')}>
				<Form.Input.Text name="email" />
			</Form.Layout.Field>

			<Form.Layout.Field label={t('COMPANY.CONTACTS.LABELS.PHONE_NUMBER')}>
				<Form.Input.Text name="phone_number" />
			</Form.Layout.Field>

			<Form.Layout.Field label={t('COMPANY.CONTACTS.LABELS.FUNCTION')}>
				<Form.Input.Text name="function" />
			</Form.Layout.Field>

			<Form.Layout.Field label={t('COMPANY.CONTACTS.LABELS.AVATAR')}>
				<Form.Input.File name="avatar" />
			</Form.Layout.Field>
		</ButtonWithPopup>
	);
};
