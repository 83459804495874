import ReactDOM from 'react-dom';
import { useRef, useState } from 'react';
import cn from 'classnames';

import styles from './Tooltip.module.scss';
import { PortalProps, TooltipProps, TooltipStyle } from './Tooltip.props';

const Portal = ({ tooltip, width, style }: PortalProps) => {
	return ReactDOM.createPortal(
		<div
			className={cn(styles.Tooltip)}
			style={{
				...style,
				width: width,
			}}>
			{tooltip}
		</div>,
		document.body,
	);
};

const Tooltip = ({ tooltip, width = 200, children, ...props }: TooltipProps) => {
	const ref = useRef<HTMLElement>();
	const [style, setStyle] = useState<TooltipStyle>();

	const handleOnMouseOver = () => {
		const dimensions = ref.current?.getBoundingClientRect();

		if (dimensions) {
			const newStyle = {} as TooltipStyle;

			newStyle.left = dimensions.left + dimensions.width / 2 - width / 2;
			newStyle.left = Math.max(8, newStyle.left); // make sure it doesn't poke off the left side of the page
			newStyle.left = Math.min(newStyle.left, document.body.clientWidth - width - 8); // or off the right

			if (dimensions.top < window.innerHeight / 2) {
				newStyle.top = dimensions.top + dimensions.height + 8;
			} else {
				newStyle.bottom = window.innerHeight - dimensions.top + 8;
			}

			setStyle(newStyle);
		}
	};

	const handleOnMouseOut = () => {
		setStyle(undefined);
	};

	return (
		<div {...props} ref={ref as any} onMouseOver={handleOnMouseOver} onMouseOut={handleOnMouseOut}>
			{children}

			{style && <Portal width={width} tooltip={tooltip} style={style} />}
		</div>
	);
};

export default Tooltip;
