import { useTranslation } from 'react-i18next';

import { Page } from '@socialbrothers/components/Layout';
import { Button, Card } from '@socialbrothers/components/UI';
import { COLOR } from '@socialbrothers/constants';

import { PollTable } from '@Components/tables';

export const PollOverviewPage = () => {
	const { t } = useTranslation();

	const buttons = (
		<Button icon="plus" to="/polls/create" color={COLOR.SECONDARY}>
			{t('GLOBAL.CREATE_MODEL', { name: t('POLL.SINGLE') })}
		</Button>
	);

	return (
		<Page title={t('POLL.PLURAL')} buttons={buttons}>
			<Card title={t('POLL.PLURAL')}>
				<PollTable />
			</Card>
		</Page>
	);
};
