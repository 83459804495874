import { forwardRef } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import format from 'date-fns/format';
import { Controller, useFormContext } from 'react-hook-form';

import { Icon } from '@socialbrothers/components/UI';

import styles from './DateInput.module.scss';
import { DateInputProps } from './DateInput.props';

const DateInput = (
	{ name, onChange, showTime, initialDate, ...props }: DateInputProps,
	ref?: any,
) => {
	const { control } = useFormContext();

	return (
		<div className={styles.DateInput} {...props}>
			<Controller
				name={name}
				control={control}
				defaultValue=""
				render={({ onChange, value }) => {
					const handleChange = (value: Date) => {
						onChange(showTime ? value.toISOString() : format(value, 'yyyy-MM-dd'));
					};

					return (
						<>
							<DatePicker
								selected={value ? new Date(value) : undefined}
								showTimeSelect={showTime}
								onChange={handleChange}
								showYearDropdown
								locale="nl"
								showMonthDropdown
								dateFormat={`dd-MM-yyyy${showTime ? ' HH:mm' : ''}`}
								dropdownMode="select"
								portalId="root"
							/>

							<Icon className={styles.DateInput__Icon} icon="calendar" type="regular" />
						</>
					);
				}}
			/>
		</div>
	);
};

export default forwardRef(DateInput);
