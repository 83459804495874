import cn from 'classnames';
import { Link } from 'react-router-dom';

import { RoutesLink } from '@socialbrothers/constants';

import styles from './Breadcrumbs.module.scss';
import { BreadcrumbsProps } from './Breadcrumbs.props';

const Breadcrumbs = ({ items }: BreadcrumbsProps) => {
	if (items) {
		return (
			<ol className={styles.Breadcrumb}>
				{items.map((item: RoutesLink) => (
					<li key={item.key} className={cn([styles.Item])}>
						<Link
							className={cn([styles.Link], { [styles.Current]: item.isExact })}
							to={item.path || ''}>
							{item.label}
						</Link>
					</li>
				))}
			</ol>
		);
	}

	return <></>;
};
export default Breadcrumbs;
