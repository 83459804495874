import { RequestConfig } from '@socialbrothers/constants';
import { Instance, types } from 'mobx-state-tree';

export enum SORT_DIRECTION {
	ASCENDING = 'asc',
	DESCENDING = 'desc',
}

const FilterModel = types.model('FilterModel', {
	operator: types.maybe(types.string),
	value: types.union(types.number, types.string),
});

const TableStore = types
	.model('TableStore', {
		search: '',
		perPage: 25,
		page: 1,
		filters: types.map(types.array(FilterModel)),
		defaultFilters: types.map(types.array(FilterModel)),
		sortBy: '',
		sortDirection: types.enumeration<SORT_DIRECTION>(Object.values(SORT_DIRECTION)),
		totalResults: 0,
	})
	.actions((self) => ({
		setSort(source: string) {
			if (self.sortBy !== source) {
				self.sortDirection = SORT_DIRECTION.ASCENDING;
				self.sortBy = source;
			} else {
				if (self.sortDirection === SORT_DIRECTION.ASCENDING) {
					self.sortDirection = SORT_DIRECTION.DESCENDING;
				} else {
					self.sortDirection = SORT_DIRECTION.ASCENDING;
					self.sortBy = '';
				}
			}
		},

		setTotalResults(totalResults: number) {
			self.totalResults = totalResults;
		},

		setPerPage(perPage: number) {
			self.perPage = perPage;
		},

		setPage(page: number) {
			self.page = page;
		},

		setSearch(search: string) {
			self.search = search;
		},

		setFilter(filter?: any) {
			if (filter) {
				var result = filter.reduce((accumulator: any, target: any) => {
					if (!target.id) {
						const { field } = target;
						delete target.field;

						return {
							...accumulator,
							[field]: accumulator[field] ? [...accumulator[field], target] : [target],
						};
					}

					return accumulator;
				}, {});

				self.filters = result;
			} else {
				self.filters.clear();
			}
		},
	}))
	.views((self) => ({
		get getFilter(): RequestConfig<any> {
			return {
				page: self.page,
				perPage: self.perPage,
				...(!!self.sortDirection ? { sortDirection: self.sortDirection } : {}),
				...(!!self.sortBy ? { sortBy: self.sortBy } : {}),
				...(!!self.search ? { searchPhrase: self.search } : {}),
				filters: { ...self.defaultFilters.toJSON(), ...self.filters.toJSON() } as any,
			};
		},
	}));

export interface TableStoreInstance extends Instance<typeof TableStore> {}
export default TableStore;
