import Papa from 'papaparse';

import { YUP_FILE_EXTENSION_STATUS } from '../constants';

export const getFileURL = (filename: string) => {
	return `${process.env.REACT_APP_API_URL}/${filename}`;
};

export const isFileImage = (
	path: string,
): Promise<{ path: string; status: YUP_FILE_EXTENSION_STATUS }> => {
	return new Promise((resolve) => {
		const img = new Image();
		img.onload = () => resolve({ path, status: YUP_FILE_EXTENSION_STATUS.OK });
		img.onerror = () => resolve({ path, status: YUP_FILE_EXTENSION_STATUS.ERROR });

		img.src = path;
	});
};

export const parseMails = async (file: File): Promise<string | null> => {
	return new Promise<string | null>((resolve) => {
		Papa.parse(file, {
			complete: (results) => {
				const mappedResults = results.data?.map((row: any) => row[0]);
				const joinedEmails = mappedResults?.join(', ');

				resolve(joinedEmails);
			},
		});
	});
};

export const downloadFile = (fileName: string, data: any) => {
	const url = window.URL.createObjectURL(new Blob([data]));
	const link = document.createElement('a');
	link.href = url;
	link.setAttribute('download', fileName);
	document.body.appendChild(link);
	link.click();
	link.remove();
};

export const getFilenameFromContentDisposition = (contentDisposition: string) => {
	var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
	var matches = filenameRegex.exec(contentDisposition);

	if (matches != null && matches[1]) {
		return matches[1].replace(/['"]/g, '');
	}
};
