import { EffectForm } from '../EffectForm/EffectForm';

import styles from './EffectView.module.scss';
import { EffectViewProps } from './EffectView.props';

export const EffectView = ({ id, step }: EffectViewProps) => {
	return (
		<div className={styles.Effetcs}>
			<EffectForm id={id} step={step} />
		</div>
	);
};
