import { toast } from 'react-toastify';
import { useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';

import { ButtonWithPopup } from '@socialbrothers/components/Containers';

import { QuestionnairePublishFormProps } from './QuestionnairePublishForm.props';
import { COLOR } from '@Root/socialbrothers/constants';
import { QuestionnaireService } from '@Services/QuestionnaireService';

export const QuestionnairePublishForm = ({ questionnaire }: QuestionnairePublishFormProps) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();

	const mutatePublishQuestionnaire = useMutation(
		() => QuestionnaireService.publish(questionnaire.id),
		{
			onSuccess: () => {
				queryClient.invalidateQueries([QuestionnaireService.endpoint, questionnaire.id]);
				toast.success(t('GLOBAL.PUBLISHED_SUCCESSFUL', { name: t('QUESTIONNAIRE.SINGLE') }));
			},
			onError: () => {
				toast.error(t('GLOBAL.PUBLISHED_UNSUCCESSFUL', { name: t('QUESTIONNAIRE.SINGLE') }));
			},
		},
	);

	return (
		<ButtonWithPopup
			withForm
			button={{
				color: COLOR.SECONDARY,
				label: questionnaire.published
					? t('QUESTIONNAIRE.PUBLISH.PUBLISHED')
					: t('QUESTIONNAIRE.PUBLISH.PUBLISH'),
				icon: 'eye',
				disabled: !questionnaire.publishable || questionnaire.published,
			}}
			submit={{
				label: t('GLOBAL.PUBLISH'),
				onClick: () => {
					return mutatePublishQuestionnaire.mutateAsync();
				},
			}}
			popup={{
				title: t('QUESTIONNAIRE.PUBLISH.POPUP_TITLE'),
			}}>
			<p>{t('QUESTIONNAIRE.PUBLISH.POPUP_DESCRIPTION')}</p>
		</ButtonWithPopup>
	);
};
