import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import { useResource } from '@socialbrothers/hooks';
import { ButtonWithPopup, Form } from '@socialbrothers/components/Containers';

import { CompanyService } from '@Services/index';

import { ModulesOOMProps, ModulesOOMPropsFormProps } from './ModulesOOM.props';

export const ModulesOOM = ({ id, record }: ModulesOOMProps) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const company = useResource(CompanyService, id);

	const mutatonUpdateModule = useMutation(
		(values: ModulesOOMPropsFormProps) => CompanyService.updateModule(id, record.id, values),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(CompanyService.endpoint);
				queryClient.invalidateQueries([CompanyService.endpoint, id]);

				toast.success(t('GLOBAL.UPDATED_SUCCESSFUL', { name: t('ENUM.MODULE.OOM') }));
			},
		},
	);

	const initialValues = {
		survey_expire_days: company?.data?.oom_settings?.survey_expire_days,
	};

	const validationSchema = Yup.object().shape({
		survey_expire_days: Yup.number().min(1).required(),
	});

	return (
		<ButtonWithPopup
			withForm
			initialValues={initialValues}
			validationSchema={validationSchema}
			button={{
				icon: 'pencil',
			}}
			submit={{
				label: t('GLOBAL.SAVE'),
				onClick: async (values: ModulesOOMPropsFormProps) => {
					return mutatonUpdateModule.mutateAsync(values);
				},
			}}
			popup={{
				title: t('ENUM.MODULE.OOM'),
			}}>
			<Form.Layout.Field
				label={t('COMPANY.MODULE.OOM.SURVEY_EXPIRE_DAYS')}
				suffix={t('GLOBAL.DAYS')}>
				<Form.Input.Number min={1} name="survey_expire_days" />
			</Form.Layout.Field>
		</ButtonWithPopup>
	);
};
