import * as Yup from 'yup';
import { Control } from 'react-hook-form';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { ButtonWithPopup, Form } from '@socialbrothers/components/Containers';
import { COLOR, OPERATORS } from '@socialbrothers/constants';
import { useList, useResource } from '@socialbrothers/hooks';
import { modelToOptions } from '@socialbrothers/utils';

import {
	IQuestionnaire,
	ITheme,
	IThemeGroup,
	QuestionAnswerService,
	QuestionnaireService,
	QUESTION_TYPE,
	QUESTIONNAIRE_TYPE,
	ThemeGroupService,
	ThemeService,
	QuestionService,
} from '@Services/index';

import { QuestionAnswerFormProps } from './QuestionAnswerForm.props';

export const QuestionAnswerForm = ({ questionId, answer, module }: QuestionAnswerFormProps) => {
	const { t } = useTranslation();
	const [themeGroupId, setThemeGroupId] = useState<number>();
	const queryClient = useQueryClient();
	const { data: question } = useResource(QuestionService, questionId);

	const themes = useList<ITheme>(ThemeService, {
		perPage: Number.MAX_SAFE_INTEGER,
	});

	const themegroups = useList<IThemeGroup>(ThemeGroupService, {
		perPage: Number.MAX_SAFE_INTEGER,
	});

	const questionnaire = useList<IQuestionnaire>(QuestionnaireService, {
		sortBy: 'title',
		perPage: Number.MAX_SAFE_INTEGER,
		filters: {
			published: [
				{
					operator: OPERATORS.IS,
					value: true,
				},
			],
			type: [
				{
					operator: OPERATORS.IS,
					value: QUESTIONNAIRE_TYPE.AOM,
				},
			],
		},
	});

	const mutateCreate = useMutation(
		(values: any) => {
			return QuestionAnswerService.create(questionId, values);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries([QuestionAnswerService.parentEndpoint, questionId]);
				toast.success(t('GLOBAL.CREATED_SUCCESSFUL', { name: t('QUESTION.ANSWERS.SINGLE') }));
			},
			onError: () => {
				toast.error(t('GLOBAL.CREATED_UNSUCCESSFUL', { name: t('QUESTION.ANSWERS.SINGLE') }));
			},
		},
	);

	const mutateUpdate = useMutation(
		(values: any) => {
			return QuestionAnswerService.update(questionId, answer?.id || 0, values);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries([QuestionAnswerService.parentEndpoint, questionId]);
				toast.success(t('GLOBAL.UPDATED_SUCCESSFUL', { name: t('QUESTION.ANSWERS.SINGLE') }));
			},
			onError: () => {
				toast.error(t('GLOBAL.UPDATED_UNSUCCESSFUL', { name: t('QUESTION.ANSWERS.SINGLE') }));
			},
		},
	);

	const validationSchema = Yup.object().shape({
		text: Yup.string().required(),
		theme_weight: Yup.number()
			.min(0)
			.nullable(true)
			.transform((v) => (v === '' || Number.isNaN(v) ? null : v)),
	});

	const handleThemeGroupIdChange = (value: string, control: Control<Record<string, any>>) => {
		const formatted = parseInt(value);

		if (value !== '' && !themeGroupId && answer) {
			const activeTheme = themes.data?.data.find((theme: ITheme) => theme.id === answer.theme_id);

			if (activeTheme) {
				control.setValue('theme_group_id', activeTheme.theme_group_id);
			}
		}

		if (formatted !== themeGroupId) {
			setThemeGroupId(formatted);
		}
	};

	return (
		<ButtonWithPopup
			withForm
			validationSchema={validationSchema}
			button={{
				color: answer ? COLOR.PRIMARY : COLOR.SECONDARY,
				icon: answer ? 'pencil' : 'plus',
				label: answer ? '' : t('GLOBAL.CREATE_MODEL', { name: t('QUESTION.ANSWERS.SINGLE') }),
			}}
			submit={{
				label: answer ? t('GLOBAL.UPDATE') : t('GLOBAL.CREATE'),
				color: COLOR.PRIMARY,
				onClick: (values) => {
					if (answer) {
						return mutateUpdate.mutateAsync(values);
					} else {
						return mutateCreate.mutateAsync(values);
					}
				},
			}}
			initialValues={{ ...answer, action_enabled: !!answer?.action && 'action' }}
			popup={{
				title: answer
					? t('GLOBAL.UPDATE_MODEL', { name: answer.text })
					: t('GLOBAL.CREATE_MODEL', { name: t('QUESTION.ANSWERS.SINGLE') }),
			}}>
			<Form.Layout.Field label={t('QUESTION.ANSWERS.LABELS.TEXT')}>
				<Form.Input.Multiline name="text" />
			</Form.Layout.Field>

			{[QUESTION_TYPE.SINGLE_DROPDOWN, QUESTION_TYPE.MULTIPLE].includes(question.type) && (
				<Form.Layout.Field label={t('QUESTION.ANSWERS.LABELS.GROUP')}>
					<Form.Input.Text name="group" />
				</Form.Layout.Field>
			)}

			<Form.Layout.Field label={t('QUESTION.ANSWERS.LABELS.IS_OPEN')}>
				<Form.Input.Checkbox
					name="open_input"
					options={[{ key: 1, value: String(t('GLOBAL.YES')) }]}
				/>
			</Form.Layout.Field>

			{themes?.data?.data && themegroups?.data?.data && (
				<Form.Layout.Group label={t('QUESTION.ANSWERS.LABELS.THEME')}>
					<Form.Layout.Field
						label={t('QUESTION.ANSWERS.LABELS.THEME_GROUP')}
						onChange={handleThemeGroupIdChange}>
						<Form.Input.Select
							name="theme_group_id"
							options={modelToOptions(themegroups.data.data, 'name')}
						/>
					</Form.Layout.Field>

					{!!themeGroupId && (
						<Form.Layout.Field label={t('QUESTION.ANSWERS.LABELS.THEME')}>
							<Form.Input.Select
								name="theme_id"
								options={modelToOptions(
									themes.data.data.filter((theme: ITheme) => theme.theme_group_id === themeGroupId),
									'name',
								)}
							/>
						</Form.Layout.Field>
					)}

					<Form.Layout.Field label={t('QUESTION.ANSWERS.LABELS.WEIGHT')}>
						<Form.Input.Number name="theme_weight" />
					</Form.Layout.Field>
				</Form.Layout.Group>
			)}

			{module === QUESTIONNAIRE_TYPE.AOM && (
				<>
					{questionnaire.data?.data && (
						<Form.Layout.Group label={t('QUESTION.ANSWERS.LABELS.ADDITIONAL_QUESTIONNAIRE')}>
							<Form.Layout.Field label={t('QUESTION.ANSWERS.LABELS.ADDITIONAL_QUESTIONNAIRE')}>
								<Form.Input.Select
									name="additional_questionnaire_id"
									options={modelToOptions(questionnaire.data?.data, 'title')}
								/>
							</Form.Layout.Field>
						</Form.Layout.Group>
					)}
				</>
			)}
		</ButtonWithPopup>
	);
};
