import { useTranslation } from 'react-i18next';

import { Page } from '@socialbrothers/components/Layout';
import { Button, Card } from '@socialbrothers/components/UI';
import { COLOR } from '@socialbrothers/constants';

import { QuestionTable } from '@Components/tables';
import { QUESTIONNAIRE_TYPE } from '@Services/index';

export const OOMQuestionOverviewPage = () => {
	const { t } = useTranslation();

	const buttons = (
		<Button icon="plus" to="/questions/oom/create" color={COLOR.SECONDARY}>
			{t('GLOBAL.CREATE_MODEL', { name: t('QUESTION.SINGLE') })}
		</Button>
	);

	return (
		<Page title={t('QUESTION.PLURAL')} buttons={buttons}>
			<Card title={t('QUESTION.PLURAL')}>
				<QuestionTable module={QUESTIONNAIRE_TYPE.OOM} />
			</Card>
		</Page>
	);
};
