import cn from 'classnames';

import styles from './Row.module.scss';

import { RowProps } from './Row.props';

const Row = ({ children, className, ...props }: RowProps) => {
	return (
		<div className={cn(styles.Row, className)} {...props}>
			{children}
		</div>
	);
};

export default Row;
