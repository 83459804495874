import { ResourceService } from '@socialbrothers/services';
import { BaseModel } from '@socialbrothers/constants';

import MenselyService from './MenselyService';

export interface IArticle extends BaseModel {
	title: string;
}

interface ArticleResponse {
	items: {
		items: {
			['nl-NL']: any;
		};
	}[];
}

export class ArticleServiceImplementation extends ResourceService<IArticle> {
	getAll = async (searchPhrase = '') => {
		const response = await this.client.get<ArticleResponse>(
			`${this.endpoint}/search?searchPhrase=${searchPhrase}`,
		);

		return response.data;
	};
}

export const ArticleService = new ArticleServiceImplementation(MenselyService, '/api/v1/articles');
