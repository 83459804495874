import cn from 'classnames';

import { IconProps } from './Icon.props';

const Icon = ({ icon, className, type = 'solid', ...props }: IconProps) => {
	const faType = {
		solid: 'fas',
		regular: 'far',
		light: 'fal',
		brands: 'fab',
	}[type];

	return <i className={cn(`fa-${icon}`, faType, { [`${className}`]: className })} {...props} />;
};
export default Icon;
