import cn from 'classnames';

import styles from './Group.module.scss';
import { GroupProps } from './Group.props';

const Group = ({ children, className, label, ...props }: GroupProps) => {
	return (
		<div className={cn(styles.Group, className)} {...props}>
			<h4 className={styles.Group__Title}>{label}</h4>
			<div className={styles.Group__Fields}>{children}</div>
		</div>
	);
};

export default Group;
