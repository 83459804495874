import { useTranslation } from 'react-i18next';

import { Page } from '@socialbrothers/components/Layout';
import { Button, Card } from '@socialbrothers/components/UI';
import { COLOR } from '@socialbrothers/constants';

import { UserTable } from '@Components/tables';

export const AdminOverviewPage = () => {
	const { t } = useTranslation();
	const buttons = (
		<Button icon="plus" to="administrators/create" color={COLOR.SECONDARY}>
			{t('ADMINS.CREATE')}
		</Button>
	);

	return (
		<Page title={t('ADMINS.PLURAL')} buttons={buttons}>
			<Card title={t('ADMINS.PLURAL')}>
				<UserTable isAdmin />
			</Card>
		</Page>
	);
};
