import { useState } from 'react';
import { Link } from 'react-router-dom';
import { remove } from 'lodash';
import cn from 'classnames';
import { toast } from 'react-toastify';
import { useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';

import { Button, Icon } from '@socialbrothers/components/UI';
import { COLOR } from '@socialbrothers/constants';
import { ButtonWithPopup } from '@socialbrothers/components/Containers';

import {
	IQuestionnaireSectionStep,
	QuestionnaireSectionStepService,
	QuestionnaireService,
	QUESTIONNAIRE_SECTION_STEP_TYPE,
} from '@Services/index';

import { EffectView } from '../EffectView/EffectView';

import styles from './StepView.module.scss';
import { StepViewProps } from './StepView.props';
import { StepFormContent, StepFormQuestion, StepFormScore } from '../StepForm/StepForm';
import { ThresholdView } from '../ThresholdView/ThresholdView';

export const StepView = ({ id, steps }: StepViewProps) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const [expandedSteps, setExpandedSteps] = useState<number[]>([]);

	const mutateMove = useMutation(
		(values: IMutateMove) => {
			if (values.direction === 'up') {
				return QuestionnaireSectionStepService.moveUp(id, values.sectionId, values.stepId);
			} else {
				return QuestionnaireSectionStepService.moveDown(id, values.sectionId, values.stepId);
			}
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries([QuestionnaireService.endpoint, id]);
			},

			onError: () => {
				toast.error(t('QUESTIONNAIRE.CONFIGURATOR.STEP.MOVE_FAILED'));
			},
		},
	);

	const mutateDelete = useMutation(
		(values: IMutateDelete) => {
			return QuestionnaireSectionStepService.delete(id, values.sectionId, values.stepId);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries([QuestionnaireService.endpoint, id]);
				toast.success(
					t('GLOBAL.DELETED_SUCCESSFUL', {
						name: t(`QUESTIONNAIRE.CONFIGURATOR.STEP.SINGLE`),
					}),
				);
			},

			onError: () => {
				toast.error(
					t('GLOBAL.DELETED_UNSUCCESSFUL', { name: t('QUESTIONNAIRE.CONFIGURATOR.STEP.SINGLE') }),
				);
			},
		},
	);

	const handleExpandedToggle = (stepId: number) => {
		setExpandedSteps((oldExpandedSteps) => {
			const newExpandedSteps = [...oldExpandedSteps];

			if (newExpandedSteps.includes(stepId)) {
				remove(newExpandedSteps, (value: number) => value === stepId);
			} else {
				newExpandedSteps.push(stepId);
			}

			return newExpandedSteps;
		});
	};

	const getStep = (step: IQuestionnaireSectionStep, index: number) => {
		const isQuestion = step.type === QUESTIONNAIRE_SECTION_STEP_TYPE.QUESTION;
		const isScore = step.type === QUESTIONNAIRE_SECTION_STEP_TYPE.SCORE;
		const isContent = step.type === QUESTIONNAIRE_SECTION_STEP_TYPE.CONTENT;

		let title = '';

		switch (step.type) {
			case QUESTIONNAIRE_SECTION_STEP_TYPE.QUESTION:
				title = step.question.title;
				break;
			case QUESTIONNAIRE_SECTION_STEP_TYPE.SCORE:
				title = t('QUESTIONNAIRE.CONFIGURATOR.SCORE.SINGLE');
				break;
			case QUESTIONNAIRE_SECTION_STEP_TYPE.CONTENT:
				title = t('QUESTIONNAIRE.CONFIGURATOR.CONTENT.SINGLE');
				break;
		}

		return (
			<div
				key={step.id}
				className={cn(styles.Steps__Item, styles.Step, {
					[styles['Step--Score']]: isScore,
					[styles['Step--Content']]: isContent,
				})}>
				<div className={styles.Step__Header}>
					<h4 className={styles.Step__Title}>
						{isQuestion && (
							<>
								<div>
									<Link to={`/questions/${step.question.module.toLowerCase()}/${step.question.id}`}>
										<Icon className={styles.Step__Edit} icon="pencil" />
									</Link>

									{title}
								</div>

								<small>{t(`ENUM.QUESTION_TYPE.${step.question.type}`)}</small>
							</>
						)}

						{isScore && (
							<>
								<div>{title}</div>
								<small>{t('QUESTIONNAIRE.CONFIGURATOR.SCORE.TOOLTIP')}</small>
							</>
						)}

						{isContent && (
							<>
								<div>{title}</div>
								<small>{t('QUESTIONNAIRE.CONFIGURATOR.CONTENT.TOOLTIP')}</small>
							</>
						)}
					</h4>

					<div className={styles.Step__Buttons}>
						{isQuestion && (
							<>
								<Button
									onClick={() => handleExpandedToggle(step.id)}
									icon={expandedSteps.includes(step.id) ? 'eye-slash' : 'eye'}
									link
								/>

								<StepFormQuestion step={step} id={id} section={step.section} />
							</>
						)}

						{isScore && (
							<>
								<ThresholdView step={step} id={id} section={step.section} />

								<StepFormScore step={step} id={id} section={step.section} />
							</>
						)}

						{isContent && (
							<>
								<StepFormContent step={step} id={id} section={step.section} />
							</>
						)}

						<Button
							onClick={() =>
								mutateMove.mutateAsync({
									direction: 'up',
									sectionId: step.section.id,
									stepId: step.id,
								})
							}
							disabled={index === 0}
							icon="arrow-up"
							link
							isLoading={mutateMove.isLoading && mutateMove.variables?.stepId === step.id}
						/>

						<Button
							onClick={() =>
								mutateMove.mutateAsync({
									direction: 'down',
									sectionId: step.section.id,
									stepId: step.id,
								})
							}
							disabled={index + 1 === steps.length}
							icon="arrow-down"
							link
							isLoading={mutateMove.isLoading && mutateMove.variables?.stepId === step.id}
						/>

						<ButtonWithPopup
							button={{
								icon: 'trash-alt',
								link: true,
								color: COLOR.DANGER,
							}}
							submit={{
								label: t('GLOBAL.DELETE'),
								color: COLOR.DANGER,
								onClick: () => {
									return mutateDelete.mutateAsync({
										sectionId: step.section.id,
										stepId: step.id,
									});
								},
							}}
							popup={{
								title: t('RESOURCE.DELETE.MODAL.TITLE', { name: title }),
							}}>
							<p>
								{t('RESOURCE.DELETE.MODAL.DESCRIPTION', {
									name: title,
								})}
							</p>
						</ButtonWithPopup>
					</div>
				</div>

				{isQuestion && expandedSteps.includes(step.id) && (
					<div className={styles.Step__Effects}>
						<EffectView id={id} step={step} />
					</div>
				)}
			</div>
		);
	};

	if (steps) {
		if (steps.length > 0) {
			return (
				<div className={styles.Steps}>
					{steps.map((step: IQuestionnaireSectionStep, index: number) => getStep(step, index))}
				</div>
			);
		} else {
			return (
				<div className={cn([styles.Steps, styles['Steps--None']])}>
					{t('QUESTIONNAIRE.CONFIGURATOR.STEP.EMPTY')}
				</div>
			);
		}
	}

	return <></>;
};

interface IMutateDelete {
	sectionId: number;
	stepId: number;
}

interface IMutateMove {
	sectionId: number;
	stepId: number;
	direction: 'up' | 'down';
}
