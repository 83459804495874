import { useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { ButtonWithPopup, Table } from '@socialbrothers/components/Containers';
import { COLOR } from '@socialbrothers/constants';
import { Button } from '@socialbrothers/components/UI';

import { CourseService, CourseStepService, COURSE_STEP_TYPE } from '@Services/index';

import { CourseStepsTableProps } from './CourseStepsTable.props';
import { CourseStepsForm } from '../CourseStepsForm/CourseStepsForm';

export const CourseStepsTable = ({ courseId, steps, className }: CourseStepsTableProps) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();

	const mutateDelete = useMutation(
		(stepId: number) => {
			return CourseStepService.delete(courseId, stepId);
		},
		{
			onSuccess: () => {
				toast.success(t('GLOBAL.DELETED_SUCCESSFUL', { name: t('COURSE.STEPS.SINGLE') }));
				queryClient.invalidateQueries([CourseService.endpoint, courseId]);
			},

			onError: () => {
				toast.error(t('GLOBAL.DELETED_UNSUCCESSFUL', { name: t('COURSE.STEPS.SINGLE') }));
			},
		},
	);

	const mutateMove = useMutation(
		(values: IMutateMove) => {
			if (values.direction === 'up') {
				return CourseStepService.moveUp(courseId, values.stepId);
			} else {
				return CourseStepService.moveDown(courseId, values.stepId);
			}
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries([CourseService.endpoint, courseId]);
			},

			onError: () => {
				toast.error(t('QUESTIONNAIRE.CONFIGURATOR.SECTION.MOVE_FAILED'));
			},
		},
	);

	return (
		<Table.Base data={steps}>
			<Table.Field.Text source="title" label={t('COURSE.STEPS.LABELS.TITLE')} />

			<Table.Field.Enum
				enumeration={COURSE_STEP_TYPE}
				name="COURSE_STEP_TYPE"
				label={t('COURSE.STEPS.LABELS.TYPE')}
				source="type"
			/>

			<Table.Action.Field
				render={(record) => {
					return (
						<>
							<Button
								onClick={() => mutateMove.mutateAsync({ direction: 'up', stepId: record.id })}
								icon="arrow-up"
								isLoading={mutateMove.isLoading && mutateMove.variables?.stepId === record.id}
							/>

							<Button
								onClick={() => mutateMove.mutateAsync({ direction: 'down', stepId: record.id })}
								icon="arrow-down"
								isLoading={mutateMove.isLoading && mutateMove.variables?.stepId === record.id}
							/>
						</>
					);
				}}
			/>

			<Table.Action.Field
				render={(record) => {
					return (
						<>
							<CourseStepsForm courseId={courseId} step={record} />

							<ButtonWithPopup
								button={{
									icon: 'trash-alt',
									color: COLOR.DANGER,
								}}
								submit={{
									label: t('GLOBAL.DELETE'),
									color: COLOR.DANGER,
									onClick: () => {
										return mutateDelete.mutateAsync(record.id);
									},
								}}
								popup={{
									title: t('GLOBAL.DELETE_MODEL', { name: record.title }),
								}}>
								<p>
									{t('RESOURCE.DELETE.MODAL.DESCRIPTION', {
										name: record.title,
									})}
								</p>
							</ButtonWithPopup>
						</>
					);
				}}
			/>
		</Table.Base>
	);
};

interface IMutateMove {
	direction: 'up' | 'down';
	stepId: number;
}
