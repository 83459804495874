import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { useList } from '@socialbrothers/hooks';
import { ButtonWithPopup, Form } from '@socialbrothers/components/Containers';
import { COLOR, OPERATORS } from '@socialbrothers/constants';
import { modelToOptions } from '@socialbrothers/utils';

import { CompanyService, IQuestionnaire, QuestionnaireService } from '@Services/index';
import { ModulesExportFormProps, ModulesExportProps } from './ModulesExport.props';

export const ModulesExport = ({ id, type }: ModulesExportProps) => {
	const { t } = useTranslation();

	const questionnaires = useList<IQuestionnaire>(QuestionnaireService, {
		sortBy: 'title',
		perPage: Number.MAX_SAFE_INTEGER,
		filters: {
			published: [
				{
					operator: OPERATORS.IS,
					value: true,
				},
			],
		},
	});

	const mutateExport = useMutation(
		(values: ModulesExportFormProps) => CompanyService.exportQuestionnaire(id, values),
		{
			onSuccess: (response, values) => {
				toast.success(t('COMPANY.MODULE.EXPORT.EXPORT_SUCCESSFUL'));
			},

			onError: () => {
				toast.error(t('COMPANY.MODULE.EXPORT.EXPORT_UNSUCCESSFUL'));
			},
		},
	);

	const validationSchema = Yup.object().shape({
		from: Yup.date().required(),
		to: Yup.date().required().min(Yup.ref('from')),
		questionnaire_id: Yup.string().required(),
	});

	return (
		<ButtonWithPopup
			withForm
			validationSchema={validationSchema}
			button={{
				icon: 'file-export',
				color: COLOR.PRIMARY,
			}}
			submit={{
				label: t('GLOBAL.SAVE'),
				onClick: (values: ModulesExportFormProps) => {
					return mutateExport.mutateAsync(values);
				},
			}}
			popup={{
				title: t('COMPANY.MODULE.EXPORT.TITLE'),
			}}>
			<Form.Layout.Field label={t('COMPANY.MODULE.EXPORT.FROM')}>
				<Form.Input.Date name="from" />
			</Form.Layout.Field>

			<Form.Layout.Field label={t('COMPANY.MODULE.EXPORT.TO')}>
				<Form.Input.Date name="to" />
			</Form.Layout.Field>

			<Form.Layout.Field label={t('COMPANY.MODULE.EXPORT.QUESTIONNAIRE')}>
				<Form.Input.Select
					name="questionnaire_id"
					options={
						questionnaires.data?.data &&
						modelToOptions(
							questionnaires.data?.data.filter((q: IQuestionnaire) => type.includes(q.type)),
							'title',
						)
					}
				/>
			</Form.Layout.Field>
		</ButtonWithPopup>
	);
};
