import { useTranslation } from 'react-i18next';

import { Page } from '@socialbrothers/components/Layout';
import { Button, Card } from '@socialbrothers/components/UI';
import { COLOR } from '@socialbrothers/constants';

import { MomentTable } from '@Components/tables';

export const MomentOverviewPage = () => {
	const { t } = useTranslation();

	const buttons = (
		<Button icon="plus" to="/moments/create" color={COLOR.SECONDARY}>
			{t('MOMENT.CREATE')}
		</Button>
	);

	return (
		<Page title={t('MOMENT.PLURAL')} buttons={buttons}>
			<Card title={t('MOMENT.PLURAL')}>
				<MomentTable />
			</Card>
		</Page>
	);
};
