import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useMutation, useQueryClient } from 'react-query';

import { COLOR } from '@socialbrothers/constants';
import { Button } from '@socialbrothers/components/UI';
import { ButtonWithPopup, Table } from '@socialbrothers/components/Containers';

import { PollAnswerService } from '@Services/PollService';

import { PollAnswerForm } from '../PollAnswerForm/PollAnswerForm';

import { PollAnswerTableProps } from './PollAnswerTable.props';

export const PollAnswerTable = ({ answers, pollId }: PollAnswerTableProps) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();

	const mutateDelete = useMutation(
		(answerId: number) => {
			return PollAnswerService.delete(pollId, answerId);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries([PollAnswerService.parentEndpoint, pollId]);
				toast.success(t('GLOBAL.DELETED_SUCCESSFUL', { name: t('POLL.ANSWERS.SINGLE') }));
			},
		},
	);

	const mutateMove = useMutation(
		(values: IMutateMove) => {
			if (values.direction === 'up') {
				return PollAnswerService.moveUp(pollId, values.answerId);
			} else {
				return PollAnswerService.moveDown(pollId, values.answerId);
			}
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries([PollAnswerService.parentEndpoint, pollId]);
			},

			onError: () => {
				toast.error(t('QUESTIONNAIRE.CONFIGURATOR.SECTION.MOVE_FAILED'));
			},
		},
	);

	return (
		<Table.Base data={answers}>
			<Table.Field.Text source="contents" label={t('POLL.ANSWERS.SINGLE')} />
			<Table.Action.Field
				render={(record) => {
					return (
						<>
							<Button
								onClick={() => mutateMove.mutateAsync({ direction: 'up', answerId: record.id })}
								icon="arrow-up"
								isLoading={mutateMove.isLoading && mutateMove.variables?.answerId === record.id}
							/>

							<Button
								onClick={() => mutateMove.mutateAsync({ direction: 'down', answerId: record.id })}
								icon="arrow-down"
								isLoading={mutateMove.isLoading && mutateMove.variables?.answerId === record.id}
							/>
						</>
					);
				}}
			/>

			<Table.Action.Field
				render={(record) => {
					return (
						<>
							<PollAnswerForm pollId={pollId} answer={record} />

							<ButtonWithPopup
								button={{
									icon: 'trash-alt',
									color: COLOR.DANGER,
								}}
								submit={{
									label: t('GLOBAL.DELETE'),
									color: COLOR.DANGER,
									onClick: () => {
										return mutateDelete.mutateAsync(record.id);
									},
								}}
								popup={{
									title: t('RESOURCE.DELETE.MODAL.TITLE', { name: record.contents }),
								}}>
								<p>
									{t('RESOURCE.DELETE.MODAL.DESCRIPTION', {
										name: record.contents,
									})}
								</p>
							</ButtonWithPopup>
						</>
					);
				}}
			/>
		</Table.Base>
	);
};

interface IMutateMove {
	direction: 'up' | 'down';
	answerId: number;
}
