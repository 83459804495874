import { useTranslation } from 'react-i18next';

import config from '@Config';
import { ResetPasswordForm } from '@socialbrothers/components/Forms';
import { AuthWrapper } from '@socialbrothers/components/Layout';

interface Props {}

const ForgotPasswordPage = ({ ...props }: Props) => {
	const { t } = useTranslation();

	const initialValues = {
		password: '',
		password_confirmation: '',
	};

	return (
		<AuthWrapper imageUri={config.forgotPasswordImage} title={t('RESET_PASSWORD.LABEL')}>
			<ResetPasswordForm initialValues={initialValues} />
		</AuthWrapper>
	);
};

export default ForgotPasswordPage;
