import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Table } from '@socialbrothers/components/Containers';

import { RoleService, IRole } from '@Services/index';

import styles from './RoleTable.module.scss';
import { RoleTableProps } from './RoleTable.props';

export const RoleTable = ({ className }: RoleTableProps) => {
	const { t } = useTranslation();

	return (
		<Table.Resource<IRole> service={RoleService} className={cn(styles.RoleTable, className)}>
			<Table.Field.Text sortable filterable source="name" label={t('ROLE.LABELS.NAME')} />

			<Table.Field.Boolean
				sortable
				filterable
				source="is_default"
				width={1}
				label={t('ROLE.LABELS.IS_DEFAULT')}
			/>

			<Table.Field.Date
				source="created_at"
				label={t('TABLE.COMMON.COLUMN.CREATED_AT')}
				width={1}
				filterable
				sortable
			/>

			<Table.Field.ResourceAction
				deleteConfig={(record: IRole) => ({
					name: record.name,
				})}
				editConfig={(record: IRole) => ({
					link: `/roles/${record.id}`,
				})}
			/>
		</Table.Resource>
	);
};
