import { useState } from 'react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { Page } from '@socialbrothers/components/Layout';
import { Card, KPI } from '@socialbrothers/components/UI';
import { COLOR, OPERATORS } from '@socialbrothers/constants';
import { useList } from '@socialbrothers/hooks';

import { IUser, UserService } from '@Services/UserService';
import { CompanyService } from '@Services/CompanyService';

import '../App.scss';

const DashboardPage = () => {
	const { t } = useTranslation();
	const [date] = useState(format(new Date(), 'yyyy-MM-dd'));

	const companies = useList(CompanyService, { perPage: 0 });
	const users = useList(UserService, { perPage: 0 });

	const loggedInUsers = useList<IUser>(UserService, {
		perPage: 0,
		filters: {
			last_login: [
				{
					operator: OPERATORS.GREATER_THAN_OR_EQUAL,
					value: date,
				},
			],
		},
	});

	return (
		<Page breadcrumbs={false}>
			<div className="row">
				<div className="col-xl-3 col-lg-6 col-sm-6 col-xs-12">
					<KPI
						title={t('DASHBOARD.KPI.COMPANIES')}
						icon="building"
						value={companies?.data?.total || '-'}
						color={COLOR.TERTIARY}
					/>
				</div>

				<div className="col-xl-3 col-lg-6 col-sm-6 col-xs-12">
					<KPI
						title={t('DASHBOARD.KPI.USERS')}
						icon="users"
						value={users?.data?.total || '-'}
						color={COLOR.SECONDARY}
					/>
				</div>

				<div className="col-xl-3 col-lg-6 col-sm-6 col-xs-12">
					<KPI
						title={t('DASHBOARD.KPI.LOGGED_IN')}
						icon="chart-line"
						value={loggedInUsers?.data?.total || '-'}
						color={COLOR.INFO}
					/>
				</div>
			</div>

			<Card title={t('DASHBOARD.TITLE')}></Card>
		</Page>
	);
};

export default DashboardPage;
