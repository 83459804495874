import { useState } from 'react';
import * as Yup from 'yup';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { Form } from '@socialbrothers/components/Containers';
import { useTheme } from '@socialbrothers/hooks';
import { YupUtils } from '@socialbrothers/utils';
import { UserService } from '@Services/UserService';
import config from '@Config';

import { MyAccountFormValues } from './MyAccountForm.props';
import styles from './MyAccountForm.module.scss';

const MyAccountForm = ({ ...props }) => {
	const { t } = useTranslation();
	const { accountService } = useTheme();
	const queryClient = useQueryClient();
	const [changePassword, setChangePassword] = useState(false);
	const user = useQuery(['users', 'me'], accountService.me);

	const validationSchema = Yup.object().shape({
		email: Yup.string()
			.test(YupUtils.FieldValidation(UserService, t('ROLE.LABELS.NAME'), user?.data?.email))
			.required(),
		first_name: Yup.string().required(),
		last_name: Yup.string().required(),
		phone_number: Yup.string().required(),
		password: Yup.string().when('password_change', {
			is: 'true',
			then: (s) => s.matches(config.passwordRegex).required(),
		}),
		password_confirmation: Yup.string().when('password_change', {
			is: 'true',
			then: (s) =>
				s
					.equals([Yup.ref('password'), null], t('VALIDATION.COMMON.PASSWORDS_DONT_MATCH'))
					.required(),
		}),
	});

	const mutateUpdateMe = useMutation(accountService.updateMe, {
		onSuccess: () => {
			queryClient.invalidateQueries(accountService.endpoint);

			toast.success(t('GLOBAL.UPDATED_SUCCESSFUL', { name: t('ACCOUNT.YOUR_ACCOUNT') }));
		},
		onError: () => {
			toast.error(t('GLOBAL.UPDATED_UNSUCCESSFUL', { name: t('ACCOUNT.YOUR_ACCOUNT') }));
		},
	});

	const handleSubmit = (values: MyAccountFormValues) => {
		const params = {
			...values,
			password: values.password || undefined,
		};

		return mutateUpdateMe.mutateAsync(params);
	};

	return (
		<Form.Base
			className={styles.MyAccountForm}
			onSubmit={handleSubmit}
			submitLabel={t('GLOBAL.SAVE')}
			validationSchema={validationSchema}
			initialValues={user.data}
			{...props}>
			<Form.Layout.Field label={t('FORMS.COMMON_LABELS.EMAIL')}>
				<Form.Input.Text name="email" />
			</Form.Layout.Field>

			<Form.Layout.Field label={t('USERS.LABELS.FIRST_NAME')}>
				<Form.Input.Text name="first_name" />
			</Form.Layout.Field>

			<Form.Layout.Field label={t('USERS.LABELS.LAST_NAME')}>
				<Form.Input.Text name="last_name" />
			</Form.Layout.Field>

			<Form.Layout.Field label={t('USERS.LABELS.PHONE_NUMBER')}>
				<Form.Input.Text name="phone_number" />
			</Form.Layout.Field>

			<Form.Layout.Field
				label={t('USERS.PASSWORD_CHANGE')}
				onChange={(value) => {
					setChangePassword(value);
				}}>
				<Form.Input.Checkbox
					name="password_change"
					options={[{ key: 'true', value: String(t('USERS.PASSWORD_CHANGE')) }]}
				/>
			</Form.Layout.Field>

			{changePassword && (
				<>
					<Form.Layout.Field label={t('USERS.LABELS.PASSWORD')}>
						<Form.Input.Text type="password" name="password" />
					</Form.Layout.Field>

					<Form.Layout.Field label={t('USERS.LABELS.PASSWORD_CONFIRMATION')}>
						<Form.Input.Text type="password" name="password_confirmation" />
					</Form.Layout.Field>
				</>
			)}
		</Form.Base>
	);
};

export default MyAccountForm;
