import i18n from './i18n';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import nl from 'date-fns/locale/nl';
import { ToastContainer } from 'react-toastify';
import { registerLocale } from 'react-datepicker';

import './index.scss';
import './yupSetup';

import App from './App';

registerLocale('nl', nl);

const queryClient = new QueryClient();

ReactDOM.render(
	<I18nextProvider i18n={i18n}>
		<QueryClientProvider client={queryClient}>
			<ReactQueryDevtools />
			<ToastContainer />

			<App />
		</QueryClientProvider>
	</I18nextProvider>,
	document.getElementById('root'),
);
