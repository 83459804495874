import { Instance, types } from 'mobx-state-tree';
import SidebarModel from './SidebarModel';

const ThemeStore = types
	.model('ThemeStore', {
		isMobile: window.innerWidth < 992,
		Sidebar: SidebarModel,
	})
	.actions((self) => ({
		setIsMobile(isMobile: boolean) {
			self.isMobile = isMobile;
		},
	}));

export interface ThemeStoreInstance extends Instance<typeof ThemeStore> {}
export default ThemeStore;
