import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Table } from '@socialbrothers/components/Containers';

import { MessageService, IMessage, ACCOUNT_TYPE } from '@Services/index';

import styles from './MessageTable.module.scss';
import { MessageTableProps } from './MessageTable.props';

export const MessageTable = ({ className }: MessageTableProps) => {
	const { t } = useTranslation();

	return (
		<Table.Resource<IMessage>
			service={MessageService}
			className={cn(styles.MessageTable, className)}>
			<Table.Field.Text sortable filterable source="title" label={t('MESSAGE.LABELS.TITLE')} />

			<Table.Field.Text
				sortable
				filterable
				source="company.name"
				label={t('MESSAGE.LABELS.COMPANY_ID')}
			/>

			<Table.Field.Enum
				sortable
				filterable
				enumeration={ACCOUNT_TYPE}
				name="ACCOUNT_TYPE"
				label={t('MESSAGE.LABELS.ACCOUNT_TYPE')}
				source="account_type"
			/>

			<Table.Field.Function
				render={(message: IMessage) => `${message.total_read}/${message.total_recipients}`}
				label={t('MESSAGE.LABELS.READ_SEND')}
				source="total_recipients"
			/>

			<Table.Field.Date
				source="created_at"
				label={t('TABLE.COMMON.COLUMN.CREATED_AT')}
				width={1}
				filterable
				sortable
			/>

			<Table.Field.ResourceAction
				deleteConfig={(record: IMessage) => ({
					name: record.title,
				})}
			/>
		</Table.Resource>
	);
};
