import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { useResource } from '@socialbrothers/hooks';
import { Page } from '@socialbrothers/components/Layout';
import { Card } from '@socialbrothers/components/UI';
import { isCreate } from '@socialbrothers/utils';

import { CourseService, COURSE_TYPE } from '@Services/index';
import { CourseForm } from '@Components/forms';
import { CourseSteps } from '@Components/modules';

export const ChallengeDetailPage = () => {
	const { t } = useTranslation();
	const { id } = useParams<any>();
	const { data } = useResource(CourseService, id);

	const label = data?.title || t('COURSE.CHALLENGE.SINGLE');

	return (
		<Page title={label}>
			<Card title={label}>
				<CourseForm type={COURSE_TYPE.CHALLENGE} />
			</Card>

			{!isCreate(id) && <CourseSteps courseId={id} steps={data?.assignments} />}
		</Page>
	);
};
