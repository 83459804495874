import * as Yup from 'yup';
import { useQuery } from 'react-query';
import { useState } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import { useList } from '@socialbrothers/hooks';
import { IMAGE_FORMATS } from '@socialbrothers/constants';
import { Form } from '@socialbrothers/components/Containers';
import {
	articlesToModel,
	enumToOptions,
	isCreate,
	modelToOptions,
	YupUtils,
} from '@socialbrothers/utils';

import {
	MomentService,
	MOMENT_MOMENTABLE_TYPE,
	ArticleService,
	PollService,
} from '@Services/index';

import { MomentFormProps } from './MomentForm.props';

export const MomentForm = ({ ...props }: MomentFormProps) => {
	const { id } = useParams<any>();
	const { t } = useTranslation();
	const [type, setType] = useState('');

	const articles = useQuery(ArticleService.endpoint, () => ArticleService.getAll());

	const polls = useList(PollService, {
		perPage: Number.MAX_SAFE_INTEGER,
	});

	const validationSchema = Yup.object().shape({
		title: Yup.string().required(),
		description: Yup.string().required(),
		momentable_type: isCreate(id) ? Yup.string().required() : Yup.string(),

		momentable_id: [MOMENT_MOMENTABLE_TYPE.POLL.toString()].includes(type)
			? Yup.string().required()
			: Yup.string(),

		prepr_article_id: [MOMENT_MOMENTABLE_TYPE.ARTICLE.toString()].includes(type)
			? Yup.string().required()
			: Yup.string(),

		contents: [
			MOMENT_MOMENTABLE_TYPE.TEXT.toString(),
			MOMENT_MOMENTABLE_TYPE.VIDEO.toString(),
			MOMENT_MOMENTABLE_TYPE.IMAGE.toString(),
		].includes(type)
			? Yup.string().required()
			: Yup.string(),

		image: [MOMENT_MOMENTABLE_TYPE.IMAGE.toString()].includes(type)
			? Yup.mixed()
					.test(YupUtils.FileExtension([...Object.values(IMAGE_FORMATS)]))
					.test(YupUtils.FileSize(10))
					.required()
			: Yup.mixed(),
	});

	const getTypeFields = () => {
		switch (type) {
			case MOMENT_MOMENTABLE_TYPE.POLL:
				if (polls?.data?.data) {
					return (
						<Form.Layout.Field label={t('MOMENT.LABELS.POLL')}>
							<Form.Input.Select
								name="momentable_id"
								options={modelToOptions(polls?.data?.data || [], 'question')}
							/>
						</Form.Layout.Field>
					);
				}
				break;
			case MOMENT_MOMENTABLE_TYPE.ARTICLE:
				if (articles?.data?.items) {
					return (
						<Form.Layout.Field label={t('MOMENT.LABELS.ARTICLE')}>
							<Form.Input.Select
								name="prepr_article_id"
								options={modelToOptions(
									articlesToModel(articles?.data?.items as any) || [],
									'title',
								)}
							/>
						</Form.Layout.Field>
					);
				}
				break;
			case MOMENT_MOMENTABLE_TYPE.TEXT:
				return (
					<Form.Layout.Field label={t('MOMENT.LABELS.TEXT')}>
						<Form.Input.Multiline name="contents" />
					</Form.Layout.Field>
				);
			case MOMENT_MOMENTABLE_TYPE.IMAGE:
				return (
					<>
						<Form.Layout.Field label={t('MOMENT.LABELS.TEXT')}>
							<Form.Input.Multiline name="contents" />
						</Form.Layout.Field>

						<Form.Layout.Field label={t('MOMENT.LABELS.IMAGE')}>
							<Form.Input.File name="image" />
						</Form.Layout.Field>
					</>
				);
			case MOMENT_MOMENTABLE_TYPE.VIDEO:
				return (
					<Form.Layout.Field label={t('MOMENT.LABELS.VIDEO')}>
						<Form.Input.Text name="contents" />
					</Form.Layout.Field>
				);
		}
	};

	return (
		<Form.Resource
			validationSchema={validationSchema}
			service={MomentService}
			label={t('MOMENT.SINGLE')}
			id={id}
			deleteConfig={{
				name: 'title',
				redirect: '/moments',
			}}>
			<Form.Layout.Field label={t('MOMENT.LABELS.TITLE')}>
				<Form.Input.Text name="title" />
			</Form.Layout.Field>

			<Form.Layout.Field label={t('MOMENT.LABELS.DESCRIPTION')}>
				<Form.Input.Text name="description" />
			</Form.Layout.Field>

			<Form.Layout.Field
				label={t('MOMENT.LABELS.TYPE')}
				onChange={(value) => {
					setType(value);
				}}>
				<Form.Input.Select
					disabled={!isCreate(id)}
					name="momentable_type"
					options={enumToOptions(MOMENT_MOMENTABLE_TYPE, 'MOMENT_MOMENTABLE_TYPE')}
				/>
			</Form.Layout.Field>

			{type && getTypeFields()}
		</Form.Resource>
	);
};
