import i18n from 'i18next';
import { get } from 'lodash';

import { BaseModel, KeyValues } from '../constants';

export const getFilterableFields = (fields: any[]) => {
	return fields.reduce((filterableFields, field) => {
		if (!field.filterable) {
			return filterableFields;
		}

		return [...filterableFields, { key: field.source, value: field.label }];
	}, []);
};

export const enumToOptions = (enumeration: any, enumName: string): KeyValues => {
	return Object.values(enumeration).reduce<any>((total, item: any) => {
		return [...total, { key: item, value: i18n.t(`ENUM.${enumName}.${item}`) }];
	}, []);
};

type ExtractFunction<T> = (record: T) => string;

export const modelToOptions = <T extends BaseModel>(
	models: T[],
	value: string | ExtractFunction<T>,
) => {
	const options = models.map((model: T) => {
		const label = typeof value === 'string' ? get(model, value) : value(model);

		return {
			key: model.id,
			value: label,
		};
	});

	return options;
};

export const keyValuesToReactSelectOptions = (options: KeyValues) => {
	const selectOptions = options.map((option) => {
		return {
			disabled: option.disabled,
			label: option.value,
			value: option.key,
		};
	});

	return selectOptions;
};
