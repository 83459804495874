import { useFormContext } from 'react-hook-form';

import { TextInputProps } from './TextInput.props';

const TextInput = ({ ...props }: TextInputProps) => {
	const { register } = useFormContext();

	return <input ref={register()} type="text" {...props} />;
};

export default TextInput;
