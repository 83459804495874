import { cloneElement, useMemo } from 'react';
import cn from 'classnames';

import { useTable } from '@socialbrothers/hooks';
import { Button } from '@socialbrothers/components/UI';
import { Resource } from '@socialbrothers/components/Containers';
import { COLOR } from '@socialbrothers/constants';

import { ResourceActionFieldProps } from './ResourceActionField.props';
import styles from './ResourceActionField.module.scss';

const ResourceActionField = ({
	editConfig,
	deleteConfig,
	record,
	children,
	className,
	...props
}: ResourceActionFieldProps) => {
	const table = useTable();
	const editConfigFired = useMemo(() => editConfig && editConfig(record), [record, editConfig]);

	return (
		<div className={cn([styles.Actions, className])} {...props}>
			{children &&
				[].concat(children).map((child: JSX.Element, key: number) => {
					return cloneElement(child, { key, record });
				})}

			{table.service && editConfig && !editConfig(record).disabled && (
				<Button icon="pencil" to={editConfigFired?.link} color={COLOR.PRIMARY} />
			)}

			{table.service && deleteConfig && !deleteConfig(record).disabled && (
				<Resource.Delete
					id={record.id}
					hasIcon
					service={table.service}
					name={deleteConfig(record).name}
				/>
			)}
		</div>
	);
};

ResourceActionField.displayName = 'ResourceActionField';

export default ResourceActionField;
