import { ChildResourceService, ResourceService } from '@socialbrothers/services';
import { BaseModel } from '@socialbrothers/constants';

import MenselyService from './MenselyService';

export enum COURSE_TYPE {
	COURSE = 'course',
	CHALLENGE = 'challenge',
}

export enum COURSE_STEP_TYPE {
	ARTICLE = 'article',
	QUESTIONNAIRE = 'questionnaire',
	VIDEO = 'video',
	TEXT = 'text',
}

export interface ICourseStep extends BaseModel {
	title: string;
	course_id: number;
	description: string;
	type: COURSE_STEP_TYPE;
	assignable_id?: number;
	contents?: string;
}
export interface ICourse extends BaseModel {
	title: string;
	introduction_text: string;
	congratulation_text: string;
	icon: string;
	type: COURSE_TYPE;
	theme: string;
}

export class CourseServiceImplementation extends ResourceService<ICourse> {}

export class CourseStepServiceImplementation extends ChildResourceService<ICourseStep, ICourse> {
	moveUp = async (courseId: number, stepId: number) => {
		const result = await this.client.post<ICourseStep>(
			`${this.parentEndpoint}/${courseId}/${this.endpoint}/${stepId}/up`,
		);

		return result.data;
	};

	moveDown = async (courseId: number, stepId: number) => {
		const result = await this.client.post<ICourseStep>(
			`${this.parentEndpoint}/${courseId}/${this.endpoint}/${stepId}/down`,
		);

		return result.data;
	};
}

export const CourseService = new CourseServiceImplementation(MenselyService, '/admin/courses');
export const CourseStepService = new CourseStepServiceImplementation(
	MenselyService,
	'/admin/courses',
	'steps',
);
