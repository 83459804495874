import { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { Form } from '@socialbrothers/components/Containers';
import { enumToOptions, getMainRoute, isCreate } from '@socialbrothers/utils';

import { QuestionService, QUESTION_TYPE } from '@Services/index';
import { shouldShowField } from '@Utils/index';

import { QuestionFormProps } from './QuestionForm.props';

export const QuestionForm = ({ module, ...props }: QuestionFormProps) => {
	const { id } = useParams<any>();
	const { t } = useTranslation();

	const [type, setType] = useState(QUESTION_TYPE.SINGLE);
	const history = useHistory();

	const currentPath = getMainRoute(history.location.pathname, -1);

	const validationSchema = Yup.object().shape({
		title: Yup.string().required(),
		text: Yup.string(),
		type: Yup.string().required(),
		scale_high: shouldShowField(type, 'scale_high') ? Yup.string().required() : Yup.string(),
		scale_low: shouldShowField(type, 'scale_low') ? Yup.string().required() : Yup.string(),
		answers_required: shouldShowField(type, 'answers_required')
			? Yup.string().required()
			: Yup.string(),
	});

	return (
		<Form.Resource
			service={QuestionService}
			label={t('QUESTION.SINGLE')}
			validationSchema={validationSchema}
			id={id}
			deleteConfig={{
				name: 'title',
				redirect: `/${currentPath}`,
			}}>
			<Form.Layout.Field label={t('QUESTION.LABELS.TITLE')}>
				<Form.Input.Text name="title" />
			</Form.Layout.Field>

			<Form.Layout.Field label={t('QUESTION.LABELS.TEXT')}>
				<Form.Input.Multiline name="text" />
			</Form.Layout.Field>

			<Form.Layout.Field label={t('QUESTION.LABELS.TYPE')} onChange={setType}>
				<Form.Input.Select disabled={!isCreate(id)} options={enumToOptions(QUESTION_TYPE, 'QUESTION_TYPE')} name="type" />
			</Form.Layout.Field>

			<Form.Input.Hidden name="module" defaultValue={module} />

			{shouldShowField(type, 'answers_required') && (
				<Form.Layout.Field label={t('QUESTION.LABELS.ANSWERS_REQUIRED')}>
					<Form.Input.Text name="answers_required" />
				</Form.Layout.Field>
			)}

			{shouldShowField(type, 'scale_low') && (
				<Form.Layout.Field label={t('QUESTION.LABELS.SCALE_LOW')}>
					<Form.Input.Text name="scale_low" />
				</Form.Layout.Field>
			)}

			{shouldShowField(type, 'scale_high') && (
				<Form.Layout.Field label={t('QUESTION.LABELS.SCALE_HIGH')}>
					<Form.Input.Text name="scale_high" />
				</Form.Layout.Field>
			)}
		</Form.Resource>
	);
};
