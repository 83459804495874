import * as Yup from 'yup';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import { Form } from '@socialbrothers/components/Containers';
import { modelToOptions } from '@socialbrothers/utils';
import { useList } from '@socialbrothers/hooks';

import { IThemeGroup, ThemeGroupService, ThemeService } from '@Services/index';
import { ThemeFormProps } from './ThemeForm.props';

export const ThemeForm = ({ ...props }: ThemeFormProps) => {
	const { id } = useParams<any>();
	const { t } = useTranslation();

	const groups = useList<IThemeGroup>(ThemeGroupService, {
		perPage: Number.MAX_SAFE_INTEGER,
	});

	const validationSchema = Yup.object().shape({
		name: Yup.string().required(),
		theme_group_id: Yup.string().required(),
	});

	return (
		<Form.Resource
			service={ThemeService}
			validationSchema={validationSchema}
			label={t('THEME.SINGLE')}
			id={id}
			deleteConfig={{
				name: 'name',
				redirect: '/themes',
			}}>
			<Form.Layout.Field label={t('THEME.LABELS.NAME')}>
				<Form.Input.Text name="name" />
			</Form.Layout.Field>

			<Form.Layout.Field label={t('THEME.LABELS.DESCRIPTION')}>
				<Form.Input.RichText name="description" />
			</Form.Layout.Field>

			{groups?.data?.data && (
				<Form.Layout.Field label={t('THEME.LABELS.THEME_GROUP')}>
					<Form.Input.Select
						options={modelToOptions(groups.data.data, 'name')}
						name="theme_group_id"
					/>
				</Form.Layout.Field>
			)}
		</Form.Resource>
	);
};
