import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import { COLOR } from '@socialbrothers/constants';
import { useResource } from '@socialbrothers/hooks';
import { ButtonWithPopup, Table } from '@socialbrothers/components/Containers';

import { CompanyService, ICompany, ICompanyContact } from '@Services/index';

import { ContactsTableProps } from './ContactsTable.props';
import { ContactsCreate } from '../ContactsCreate/ContactsCreate';

export const ContactsTable = ({ id }: ContactsTableProps) => {
	const { t } = useTranslation();
	const { data } = useResource(CompanyService, id);
	const queryClient = useQueryClient();

	const mutateDeleteContact = useMutation(
		(contactId: number) => CompanyService.deleteContact(data.id, contactId),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(CompanyService.endpoint);
				queryClient.invalidateQueries([CompanyService.endpoint, id]);

				toast.success(t('GLOBAL.DELETED_SUCCESSFUL', { name: t('COMPANY.CONTACTS.SINGLE') }));
			},
		},
	);

	return (
		<Table.Base<ICompany> data={data?.contacts}>
			<Table.Field.Text label={t('COMPANY.CONTACTS.LABELS.FIRST_NAME')} source="first_name" />
			<Table.Field.Text label={t('COMPANY.CONTACTS.LABELS.LAST_NAME')} source="last_name" />
			<Table.Field.Text label={t('COMPANY.CONTACTS.LABELS.EMAIL')} source="email" />
			<Table.Field.Text label={t('COMPANY.CONTACTS.LABELS.FUNCTION')} source="function" />
			<Table.Field.Date label={t('TABLE.COMMON.COLUMN.CREATED_AT')} source="created_at" />

			<Table.Field.BaseAction>
				<Table.Action.Field
					render={(record: ICompanyContact) => {
						return (
							<>
								<ContactsCreate id={id} record={record} />

								<ButtonWithPopup
									button={{
										icon: 'trash-alt',
										color: COLOR.DANGER,
									}}
									submit={{
										label: t('GLOBAL.DELETE'),
										color: COLOR.DANGER,
										onClick: () => {
											return mutateDeleteContact.mutateAsync(record.id);
										},
									}}
									popup={{
										title: `${record.first_name} ${record.last_name}`,
									}}>
									<p>
										{t('RESOURCE.DELETE.MODAL.DESCRIPTION', {
											name: `${record.first_name} ${record.last_name}`,
										})}
									</p>
								</ButtonWithPopup>
							</>
						);
					}}
				/>
			</Table.Field.BaseAction>
		</Table.Base>
	);
};
