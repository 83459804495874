import {
	BaseModel,
	Filter,
	ListResponse,
	OPERATORS,
	RequestConfig,
} from '@socialbrothers/constants';

import { Service } from './Service';

export interface ResourceInterface {
	endpoint: string;
	getOne: (id: number, filters?: Filter<any>) => Promise<any>;
	getList: (
		config?: Filter<any>,
		extra?: { [key: string]: string | number | boolean },
	) => Promise<ListResponse<any>>;
	create: (values: any) => Promise<any>;
	update: (id: number, values: any) => Promise<any>;
	delete: (id: number) => Promise<any>;
	validate: (field: string, value: any) => Promise<any>;
}
class ResourceService<T extends BaseModel> extends Service implements ResourceInterface {
	getOne = async (id: number) => {
		const response = await this.client.get<T>(`${this.endpoint}/${id}`);

		return response.data;
	};

	getList = async (config?: RequestConfig<T>, extra?: any) => {
		const params = {
			...config,
			...extra,
			filters: config?.filters ?? null,
		};

		const response = await this.client.get<ListResponse<T[]>>(this.endpoint, params);

		return response.data;
	};

	getMany = (ids: number[], filters?: RequestConfig<T>) => {
		return this.getList({
			...filters,
			filters: {
				...filters?.filters,
				id: {
					operator: OPERATORS.IS,
					value: ids,
				},
			},
		} as any);
	};

	create = async (data: Partial<T>) => {
		const response = await this.client.post<T>(this.endpoint, data);

		return response.data;
	};

	update = async (id: number, data: T) => {
		data.id = id;
		const response = await this.client.putWithPost<T>(`${this.endpoint}/${id}`, data);

		return response.data;
	};

	delete = async (id: number) => {
		const response = await this.client.delete(`${this.endpoint}/${id}`);

		return response.data;
	};

	deleteMany = (ids: number[]) => {
		return Promise.all(
			ids.map((id) => {
				return this.delete(id);
			}),
		);
	};

	deleteFile = async (id: number, field: string) => {
		const response = await this.client.delete(`${this.endpoint}/${id}/${field}`);

		return response.data;
	};

	validate = async (field: string, value: any) => {
		const params = {
			field: field,
			value: value,
		};

		const response = await this.client.post<T>(`${this.endpoint}/validate`, params);

		return response.data;
	};
}

export default ResourceService;
