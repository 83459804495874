import { get } from 'lodash';
import { format as formatDate } from 'date-fns';

import { DateFieldProps } from './DateField.props';

const DateField = ({ source, record, className, format, showTime, ...props }: DateFieldProps) => {
	const value = get(record, source);
	let date = 'Invalid date';

	try {
		date = value
			? formatDate(new Date(value), format ? format : `dd-MM-yyyy ${showTime ? 'HH:mm' : ''}`)
			: '-';
	} catch (e) {
		console.warn(e);
	}

	return (
		<div className={className} {...props}>
			{date}
		</div>
	);
};

DateField.displayName = 'DateField';

export default DateField;
