import { ResourceService } from '@socialbrothers/services';
import { BaseModel } from '@socialbrothers/constants';

import MenselyService from './MenselyService';

export enum ACCOUNT_TYPE {
	ADMIN = 'App\\Models\\Admin',
	USER = 'App\\Models\\User',
	POB = 'App\\Models\\Pob',
	COMPANY_DOCTOR = 'App\\Models\\CompanyDoctor',
	ACCOUNT_MANAGER = 'App\\Models\\AccountManager',
	ADVISOR = 'App\\Models\\Advisor',
	HR = 'App\\Models\\HumanResources',
}
export interface IUser extends BaseModel {
	email: string;
	first_name: string;
	last_name: string;
	account_type: ACCOUNT_TYPE;
	bio: string;
	phone_number: string;
	company_id?: number;
	last_login?: string;
	companies: BaseModel[];
	requests_invite: boolean;
}

export interface UserInviteRequest {
	account_type: ACCOUNT_TYPE;
	mail_body: string;
	emails: string[];
	companies: number[];
}

export class UserServiceImplementation extends ResourceService<IUser> {
	create = async (data: Partial<IUser>) => {
		const response = await this.client.post<IUser>(this.endpoint, data);

		if (data.account_type === ACCOUNT_TYPE.USER) {
			await this.client.post<IUser>(`${this.endpoint}/${response.data.id}/companies`, {
				company_id: data.company_id,
			});
		}

		return {
			...response.data,
			company_id: data.company_id,
		};
	};

	invite = async (data: UserInviteRequest) => {
		await this.client.post<void>(`${this.endpoint}/invite`, data);
	};

	resend = async (id: number) => {
		await this.client.post<void>(`${this.endpoint}/${id}/resend`);
	};

	update = async (id: number, data: IUser) => {
		const userResponse = await this.client.putWithPost<IUser>(`${this.endpoint}/${id}`, {
			...data,
			id: id,
		});

		return {
			...userResponse.data,
			company_id: data.company_id,
		};
	};

	assignToCompany = async (userId: number, companyId: number) => {
		const response = await this.client.post(`${this.endpoint}/${userId}/companies`, {
			company_id: companyId,
		});

		return response.data;
	};

	removeFromCompany = async (userId: number, companyId: number) => {
		const params = {
			company_id: companyId,
		};

		const response = await this.client.delete(`${this.endpoint}/${userId}/companies`, params);

		return response.data;
	};

	getOne = async (id: number) => {
		const response = await this.client.get<IUser>(`${this.endpoint}/${id}`);

		return {
			...response.data,
			company_id: response.data.companies[0] ? response.data.companies[0].id : undefined,
		};
	};
}

export const UserService = new UserServiceImplementation(MenselyService, '/admin/users');
