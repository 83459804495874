import { useTranslation } from 'react-i18next';

import { Page } from '@socialbrothers/components/Layout';
import { Card } from '@socialbrothers/components/UI';

import { AOMSettingsForm } from '@Components/forms';

export const AOMSettingsDetailPage = () => {
	const { t } = useTranslation();

	return (
		<Page title={t('GLOBAL.MODULES.AOM')}>
			<Card title={t('AOMSETTINGS.PLURAL')}>
				<AOMSettingsForm />
			</Card>
		</Page>
	);
};
