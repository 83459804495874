import { context } from '@Root/App';
import { ThemeProvider } from '@socialbrothers/hooks';
import { QueryClient, QueryClientProvider } from 'react-query';
import { StaticRouter } from 'react-router';

const queryClient = new QueryClient();

export const TestWrapper = ({ children }: any) => {
	return (
		<QueryClientProvider client={queryClient}>
			<ThemeProvider context={context}>
				<StaticRouter>{children}</StaticRouter>
			</ThemeProvider>
		</QueryClientProvider>
	);
};
