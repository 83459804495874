import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { Table } from '@socialbrothers/components/Containers';

import { PollService, IPoll } from '@Services/index';

import styles from './PollTable.module.scss';
import { PollTableProps } from './PollTable.props';

export const PollTable = ({ className }: PollTableProps) => {
	const { t } = useTranslation();

	return (
		<Table.Resource<IPoll> service={PollService} className={cn(styles.PollTable, className)}>
			<Table.Field.Text sortable source="question" label={t('POLL.LABELS.QUESTION')} />

			<Table.Field.Date
				source="created_at"
				label={t('TABLE.COMMON.COLUMN.CREATED_AT')}
				width={1}
				filterable
				sortable
			/>

			<Table.Field.ResourceAction
				deleteConfig={(record: IPoll) => ({
					name: record.question,
				})}
				editConfig={(record: IPoll) => ({
					link: `/polls/${record.id}`,
				})}
			/>
		</Table.Resource>
	);
};
