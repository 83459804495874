export enum COLOR {
	PRIMARY = 'primary',
	SECONDARY = 'secondary',
	TERTIARY = 'tertiary',
	SUCCESS = 'success',
	INFO = 'info',
	WARNING = 'warning',
	DANGER = 'danger',
	GRAY = 'gray',
}

export enum OPERATORS {
	LOWER_THAN = '<',
	GREATER_THAN = '>',
	LOWER_THAN_OR_EQUAL = '<=',
	GREATER_THAN_OR_EQUAL = '>=',
	IS_NOT = '<>',
	IS = '=',
}

export enum FIELDS {
	ACTION_FIELD = 'ActionField',
	BOOLEAN_FIELD = 'BooleanField',
	CHIP_FIELD = 'ChipField',
	DATE_FIELD = 'DateField',
	EMAIL_FIELD = 'EmailField',
	FUNCTION_FIELD = 'FunctionField',
	NUMBER_FIELD = 'NumberField',
	TEXT_FIELD = 'TextField',
	URL_FIELD = 'UrlField',
	ENUM_FIELD = 'EnumField',
}

export enum API_ERRORS {
	NOT_FOUND = 'NOT_FOUND',
	INVALID_REQUEST = 'INVALID_REQUEST',
	INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
}

export enum APIS {
	MAIN = 'main',
}

export enum IMAGE_FORMATS {
	JPG = 'image/jpg',
	JPEG = 'image/jpeg',
	GIF = 'image/gif',
	PNG = 'image/png',
}

export enum YUP_FILE_EXTENSION_STATUS {
	OK = 'ok',
	ERROR = 'error',
}

export enum SICK_LEAVE_SYSTEM {
	XPERT_SUITE = 'App\\Models\\XpertSuite\\XpertSuiteConfiguration',
	AFAS = 'App\\Models\\Afas\\AfasConfiguration',
}

export enum AFAS_IMPORTER {
	CYGNIFIC = 'App\\Importers\\AoM\\Afas\\CygnificImporter',
	ALKMAAR = 'App\\Importers\\AoM\\Afas\\AlkmaarImporter',
}
