import { useTranslation } from 'react-i18next';

import { Page } from '@socialbrothers/components/Layout';
import { Card } from '@socialbrothers/components/UI';

import { UserTable } from '@Components/tables';
import { UserImportForm, UserInviteForm } from '@Components/forms';

export const UserOverviewPage = () => {
	const { t } = useTranslation();
	const buttons = (
		<>
			<UserInviteForm />
			<UserImportForm />
		</>
	);

	return (
		<Page title={t('USERS.PLURAL')} buttons={buttons}>
			<Card title={t('USERS.PLURAL')}>
				<UserTable />
			</Card>
		</Page>
	);
};
