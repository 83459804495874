import { useTranslation } from 'react-i18next';

import { Card } from '@socialbrothers/components/UI';

import { ContactsCreate } from './internal/ContactsCreate/ContactsCreate';
import { ContactsTable } from './internal/ContactsTable/ContactsTable';

import { CompanyContactsProps } from './CompanyContacts.props';

export const CompanyContacts = ({ id }: CompanyContactsProps) => {
	const { t } = useTranslation();

	return (
		<Card title={t('COMPANY.CONTACTS.PLURAL')}>
			<div className="mb-sm text-right">
				<ContactsCreate id={id} />
			</div>

			<ContactsTable id={id} />
		</Card>
	);
};
