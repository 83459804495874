import { useTranslation } from 'react-i18next';

import { Card } from '@socialbrothers/components/UI';

import { QuestionnaireAdditionalTable } from './internal/QuestionnaireAdditionalTable/QuestionnaireAdditionalTable';
import { QuestionnaireAdditionalForm } from './internal/QuestionnaireAdditionalForm/QuestionnaireAdditionalForm';

import { QuestionnaireAdditionalProps } from './QuestionnaireAdditional.props';

export const QuestionnaireAdditional = ({ id, additional }: QuestionnaireAdditionalProps) => {
	const { t } = useTranslation();

	const label = t('QUESTIONNAIRE.ADDITIONAL.PLURAL');

	return (
		<Card title={label}>
			<div className="mb-sm text-right">
				<QuestionnaireAdditionalForm questionnaireId={id} />
			</div>

			<QuestionnaireAdditionalTable questionnaireId={id} additional={additional} />
		</Card>
	);
};
