import { forwardRef } from 'react';
import cn from 'classnames';
import { useFormContext } from 'react-hook-form';

import styles from './RadioInput.module.scss';
import { RadioInputProps } from './RadioInput.props';

const RadioInput = ({ options, inline = false, name, ...props }: RadioInputProps, ref?: any) => {
	const { register } = useFormContext();

	return (
		<div className={cn(styles.RadioInput, { [styles.Inline]: inline })} {...props}>
			{Object.entries(options).map(([key, label]) => {
				return (
					<div className={styles.Option} key={key}>
						<input type="radio" name={name} value={key} id={key} ref={register()} />
						<label htmlFor={key}>{label}</label>
					</div>
				);
			})}
		</div>
	);
};

export default forwardRef(RadioInput);
