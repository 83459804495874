import { useTranslation } from 'react-i18next';

import { Table } from '@socialbrothers/components/Containers';

import { CompanyService, ICompany } from '@Services/index';

import { CompanyTableProps } from './CompanyTable.props';

export const CompanyTable = ({ className, defaultFilters }: CompanyTableProps) => {
	const { t } = useTranslation();
	return (
		<Table.Resource<ICompany>
			service={CompanyService}
			defaultFilters={defaultFilters}
			className={className}>
			<Table.Field.Text source="name" label={t('COMPANY.TABLE.COLUMN.NAME')} filterable sortable />

			<Table.Field.Date
				source="created_at"
				label={t('TABLE.COMMON.COLUMN.CREATED_AT')}
				width={1}
				filterable
				sortable
			/>

			<Table.Field.ResourceAction
				deleteConfig={(record: ICompany) => ({ name: record.name })}
				editConfig={(record: ICompany) => ({
					link: `companies/${record.id}`,
				})}
			/>
		</Table.Resource>
	);
};
