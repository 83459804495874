import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useIsFetching } from 'react-query';
import { Spinner } from '../../UI';

import styles from './Footer.module.scss';
import { FooterProps } from './Footer.props';

const Footer = ({ ...props }: FooterProps) => {
	const { t } = useTranslation();
	const isFetching = useIsFetching();

	return (
		<div className={styles.Footer}>
			Copyright &copy; {t('GLOBAL.SITE_NAME')} {new Date().getFullYear()}
			{!!isFetching && <Spinner className={styles.Spinner} size={20} />}
		</div>
	);
};
export default observer(Footer);
