import QUESTION from './question.json';
import QUESTIONNAIRE from './questionnaire.json';
import ROLE from './role.json';
import AOMSETTINGS from './aomsettings.json';
import MOMENT from './moment.json';
import POLL from './poll.json';
import COURSE from './course.json';
import THEMEGROUP from './themegroup.json';
import GLOBAL from './global.json';
import ENUM from './enum.json';
import COMPANY from './company.json';
import VALIDATION from './validation.json';
import USERS from './users.json';
import THEME from './theme.json';
import DASHBOARD from './dashboard.json';
import MESSAGE from './message.json';
import ADMINS from './admins.json';

const data = {
	translation: {
		GLOBAL,
		AOMSETTINGS,
		ENUM,
		COMPANY,
		VALIDATION,
		USERS,
		DASHBOARD,
		THEME,
		THEMEGROUP,
		COURSE,
		MOMENT,
		POLL,
		QUESTION,
		QUESTIONNAIRE,
		ROLE,
		MESSAGE,
		ADMINS,
	},
};

export default data;
