import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';

import { useList } from '@socialbrothers/hooks';
import { articlesToModel } from '@socialbrothers/utils';
import { COLOR, OPERATORS } from '@socialbrothers/constants';
import { ButtonWithPopup, Form } from '@socialbrothers/components/Containers';
import { enumToOptions, modelToOptions } from '@socialbrothers/utils';

import { shouldShowCourseStepField } from '@Utils/QuestionUtils';

import {
	QuestionnaireService,
	ArticleService,
	COURSE_STEP_TYPE,
	IQuestionnaire,
	QUESTIONNAIRE_TYPE,
	ICourseStep,
	CourseStepService,
} from '@Services/index';

import { CourseStepsFormProps } from './CourseStepsForm.props';

export const CourseStepsForm = ({ step, courseId }: CourseStepsFormProps) => {
	const { t } = useTranslation();
	const [type, setType] = useState('');
	const queryClient = useQueryClient();

	const articles = useQuery(ArticleService.endpoint, () => ArticleService.getAll());

	const questionnaires = useList<IQuestionnaire>(QuestionnaireService, {
		perPage: Number.MAX_SAFE_INTEGER,
		filters: {
			published: [
				{
					operator: OPERATORS.IS,
					value: true,
				},
			],
			type: [
				{
					operator: OPERATORS.IS,
					value: QUESTIONNAIRE_TYPE.LNI,
				},
			],
		},
	});

	const mutateCreate = useMutation(
		(values: ICourseStep) => {
			return CourseStepService.create(courseId, values);
		},
		{
			onSuccess: () => {
				toast.success(t('GLOBAL.CREATED_SUCCESSFUL', { name: t('COURSE.STEPS.SINGLE') }));
				queryClient.invalidateQueries([CourseStepService.parentEndpoint, courseId]);
			},
		},
	);

	const mutateUpdate = useMutation(
		({ stepId, values }: { stepId: number; values: ICourseStep }) => {
			return CourseStepService.update(courseId, stepId, values);
		},
		{
			onSuccess: () => {
				toast.success(t('GLOBAL.UPDATED_SUCCESSFUL', { name: t('COURSE.STEPS.SINGLE') }));
				queryClient.invalidateQueries([CourseStepService.parentEndpoint, courseId]);
			},
		},
	);

	const validationSchema = Yup.object().shape({
		type: !!step ? Yup.string() : Yup.string().required(),
		title: Yup.string().required(),
		description: Yup.string().required(),
		prepr_article_id: shouldShowCourseStepField('prepr_article_id', type)
			? Yup.string().required()
			: Yup.string(),
		assignable_id: shouldShowCourseStepField('assignable_id', type)
			? Yup.string().required()
			: Yup.string(),
		contents: shouldShowCourseStepField('contents', type) ? Yup.string().required() : Yup.string(),
	});

	const getTypeFields = () => {
		switch (type) {
			case COURSE_STEP_TYPE.ARTICLE:
				if (articles?.data?.items) {
					return (
						<Form.Layout.Field label={t('COURSE.STEPS.LABELS.ASSIGNMENT.ARTICLE')}>
							<Form.Input.Select
								name="prepr_article_id"
								options={modelToOptions(articlesToModel(articles?.data?.items), 'title')}
							/>
						</Form.Layout.Field>
					);
				}
				break;
			case COURSE_STEP_TYPE.QUESTIONNAIRE:
				if (questionnaires?.data?.data) {
					return (
						<Form.Layout.Field label={t('COURSE.STEPS.LABELS.ASSIGNMENT.QUESTIONNAIRE')}>
							<Form.Input.Select
								name="assignable_id"
								options={modelToOptions(questionnaires?.data?.data, 'title')}
							/>
						</Form.Layout.Field>
					);
				}
				break;
			case COURSE_STEP_TYPE.TEXT:
				return (
					<Form.Layout.Field label={t('COURSE.STEPS.LABELS.ASSIGNMENT.TEXT')}>
						<Form.Input.Multiline name="contents" />
					</Form.Layout.Field>
				);
			case COURSE_STEP_TYPE.VIDEO:
				return (
					<Form.Layout.Field label={t('COURSE.STEPS.LABELS.ASSIGNMENT.VIDEO')}>
						<Form.Input.Text name="contents" />
					</Form.Layout.Field>
				);
		}
	};

	return (
		<ButtonWithPopup
			withForm
			validationSchema={validationSchema}
			button={{
				color: step ? COLOR.PRIMARY : COLOR.SECONDARY,
				icon: step ? 'pencil' : 'plus',
				label: step ? '' : t('GLOBAL.CREATE_MODEL', { name: t('COURSE.STEPS.SINGLE') }),
			}}
			submit={{
				label: step ? t('GLOBAL.UPDATE') : t('GLOBAL.CREATE'),
				color: COLOR.PRIMARY,
				onClick: (values) => {
					if (step) {
						return mutateUpdate.mutateAsync({ stepId: step.id, values });
					} else {
						return mutateCreate.mutateAsync(values);
					}
				},
			}}
			initialValues={step}
			popup={{
				title: step
					? t('GLOBAL.UPDATE_MODEL', { name: step.title })
					: t('GLOBAL.CREATE_MODEL', { name: t('COURSE.STEPS.SINGLE') }),
			}}>
			<Form.Layout.Field label={t('COURSE.STEPS.LABELS.TITLE')}>
				<Form.Input.Text name="title" />
			</Form.Layout.Field>

			<Form.Layout.Field label={t('COURSE.STEPS.LABELS.DESCRIPTION')}>
				<Form.Input.Multiline name="description" />
			</Form.Layout.Field>

			<Form.Layout.Field
				label={t('COURSE.STEPS.LABELS.TYPE')}
				onChange={(value) => {
					setType(value);
				}}>
				<Form.Input.Select
					disabled={!!step}
					options={enumToOptions(COURSE_STEP_TYPE, 'COURSE_STEP_TYPE')}
					name="type"
				/>
			</Form.Layout.Field>

			{getTypeFields()}
		</ButtonWithPopup>
	);
};
