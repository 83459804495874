import { ResourceService } from '@socialbrothers/services';
import { BaseModel } from '@socialbrothers/constants';

import MenselyService from './MenselyService';
import { IQuestionnaire } from './QuestionnaireService';

export interface IRole extends BaseModel {
	name: string;
	slug: string;
	is_default: boolean;
	base_questionnaire: IQuestionnaire;
}

export class RoleServiceImplementation extends ResourceService<IRole> {}

export const RoleService = new RoleServiceImplementation(MenselyService, '/admin/oom/roles');
