import { ResourceService } from '@socialbrothers/services';
import { BaseModel } from '@socialbrothers/constants';

import MenselyService from './MenselyService';

export interface IThemeGroup extends BaseModel {
	name: any;
}

export class ThemeGroupServiceImplementation extends ResourceService<IThemeGroup> {}

export const ThemeGroupService = new ThemeGroupServiceImplementation(
	MenselyService,
	'/admin/themegroups',
);
