import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import { Page } from '@socialbrothers/components/Layout';
import { Card } from '@socialbrothers/components/UI';

import { QuestionnaireService, QUESTIONNAIRE_TYPE } from '@Services/index';
import { QuestionnaireForm, QuestionnairePublishForm } from '@Components/forms';
import { QuestionnaireConfigurator } from '@Components/modules';

export const ONBQuestionnaireDetailPage = () => {
	const { t } = useTranslation();

	const onboarding = useQuery([QuestionnaireService.endpoint, 'onboarding'], () =>
		QuestionnaireService.getOnboarding(),
	);

	const label = t('QUESTIONNAIRE.ONBOARDING');

	return (
		<Page
			title={label}
			buttons={onboarding.data && <QuestionnairePublishForm questionnaire={onboarding.data} />}>
			{onboarding.data && (
				<>
					<Card title={label}>
						<QuestionnaireForm questionnaireId={onboarding.data.id} type={QUESTIONNAIRE_TYPE.ONB} />
					</Card>

					<Card title={t('QUESTIONNAIRE.CONFIGURATOR.TITLE')}>
						<QuestionnaireConfigurator id={onboarding.data.id} type={QUESTIONNAIRE_TYPE.ONB} />
					</Card>
				</>
			)}
		</Page>
	);
};
