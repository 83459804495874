import { COLOR } from '@Root/socialbrothers/constants';
import {
	IQuestionnaireSectionStepThreshold,
	QuestionnaireSectionStepService,
	QuestionnaireService,
} from '@Services/QuestionnaireService';
import { ButtonWithPopup, Table } from '@socialbrothers/components/Containers';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { ThresholdForm } from '../ThresholdForm/ThresholdForm';

import styles from './ThresholdView.module.scss';
import { ThresholdViewProps } from './ThresholdView.props';

export const ThresholdView = ({ id, step, section }: ThresholdViewProps) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();

	const mutateDelete = useMutation(
		(thresholdId: number) =>
			QuestionnaireSectionStepService.deleteScoreThreshold(id, section.id, step.id, thresholdId),
		{
			onSuccess: () => {
				queryClient.invalidateQueries([QuestionnaireService.endpoint, id]);

				toast.success(
					t('GLOBAL.DELETED_SUCCESSFUL', {
						name: t('QUESTIONNAIRE.CONFIGURATOR.THRESHOLD.SINGLE'),
					}),
				);
			},
		},
	);

	return (
		<ButtonWithPopup
			popup={{
				title: t('QUESTIONNAIRE.CONFIGURATOR.THRESHOLD.PLURAL'),
			}}
			button={{
				icon: 'star',
				className: styles.Button,
			}}>
			<>
				<div className="mb-sm d-flex">
					<div className="ml-auto">
						<ThresholdForm id={id} section={section} step={step} />
					</div>
				</div>

				<Table.Base data={step.score_thresholds}>
					<Table.Field.Function
						label={t('QUESTIONNAIRE.CONFIGURATOR.THRESHOLD.LABELS.THEME')}
						source="id"
						render={(record: IQuestionnaireSectionStepThreshold) => record.theme.name}
					/>

					<Table.Field.Function
						label={t('QUESTIONNAIRE.CONFIGURATOR.THRESHOLD.LABELS.MIN')}
						source="id"
						render={(record: IQuestionnaireSectionStepThreshold) => String(record.min)}
					/>

					<Table.Field.Function
						label={t('QUESTIONNAIRE.CONFIGURATOR.THRESHOLD.LABELS.MAX')}
						source="id"
						render={(record: IQuestionnaireSectionStepThreshold) => String(record.max)}
					/>

					<Table.Field.BaseAction>
						<Table.Action.Field
							render={(record: IQuestionnaireSectionStepThreshold) => (
								<ThresholdForm id={id} section={section} step={step} threshold={record} />
							)}
						/>

						<Table.Action.Button
							icon="trash-alt"
							color={COLOR.DANGER}
							onClick={(record: IQuestionnaireSectionStepThreshold) => {
								mutateDelete.mutateAsync(record.id);
							}}
						/>
					</Table.Field.BaseAction>
				</Table.Base>
			</>
		</ButtonWithPopup>
	);
};
