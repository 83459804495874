import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { Form } from '@socialbrothers/components/Containers';
import { useTheme } from '@socialbrothers/hooks';

import { ForgotPasswordFormProps } from './ForgotPasswordForm.props';
import styles from './ForgotPasswordForm.module.scss';

const validationSchema = Yup.object().shape({
	email: Yup.string().email().required(),
});

const ForgotPasswordForm = ({ ...props }: ForgotPasswordFormProps) => {
	const [hasSubmitted, setSubmitted] = useState(false);
	const { t } = useTranslation();
	const [error, setError] = useState('');
	const { authService } = useTheme();

	const handleSubmit = async (values: any) => {
		try {
			await authService.forgotPassword(values.email);
			setSubmitted(true);
		} catch (e) {
			setError(t('VALIDATION.COMMON.UNKNOWN_ERROR'));
		}
	};

	return hasSubmitted ? (
		<div className={styles.SubmitCorfirmation}>{t('FORGOT_PASSWORD.SUBMISSION_SUCCESSFUL')}</div>
	) : (
		<Form.Base
			submitLabel={t('FORGOT_PASSWORD.SUBMIT_LABEL')}
			className={styles.ForgotPasswordForm}
			onSubmit={handleSubmit}
			error={error}
			{...props}
			validationSchema={validationSchema}>
			<Form.Layout.Field label="E-mailadres">
				<Form.Input.Text name="email" />
			</Form.Layout.Field>
		</Form.Base>
	);
};

export default ForgotPasswordForm;
