import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { ButtonWithPopup, Form } from '@socialbrothers/components/Containers';
import { COLOR } from '@socialbrothers/constants';
import { articlesToModel, modelToOptions } from '@socialbrothers/utils';

import { ArticleService, IPollAnswer, PollAnswerService } from '@Services/index';

import { PollAnswerFormProps } from './PollAnswerForm.props';

export const PollAnswerForm = ({ answer, pollId }: PollAnswerFormProps) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const articles = useQuery(ArticleService.endpoint, () => ArticleService.getAll());

	const mutateCreate = useMutation(
		(values: IPollAnswer) => PollAnswerService.create(pollId, values),
		{
			onSuccess: () => {
				queryClient.invalidateQueries([PollAnswerService.parentEndpoint, pollId]);
				toast.success(t('GLOBAL.CREATED_SUCCESSFUL', { name: t('POLL.ANSWERS.SINGLE') }));
			},

			onError: () => {
				toast.error(t('GLOBAL.CREATED_UNSUCCESSFUL', { name: t('POLL.ANSWERS.SINGLE') }));
			},
		},
	);

	const mutateUpdate = useMutation(
		({ answerId, values }: { answerId: number; values: IPollAnswer }) =>
			PollAnswerService.update(pollId, answerId, values),
		{
			onSuccess: () => {
				queryClient.invalidateQueries([PollAnswerService.parentEndpoint, pollId]);
				toast.success(t('GLOBAL.UPDATED_SUCCESSFUL', { name: t('POLL.ANSWERS.SINGLE') }));
			},

			onError: () => {
				toast.error(t('GLOBAL.UPDATED_UNSUCCESSFUL', { name: t('POLL.ANSWERS.SINGLE') }));
			},
		},
	);

	const validationSchema = Yup.object().shape({
		contents: Yup.string().required(),
		articles: Yup.array()
			.min(0)
			.max(3)
			.of(
				Yup.object().shape({
					prepr_id: Yup.string().required(),
				}),
			),
	});

	return (
		<ButtonWithPopup
			withForm
			validationSchema={validationSchema}
			initialValues={answer}
			button={{
				color: answer ? COLOR.PRIMARY : COLOR.SECONDARY,
				icon: answer ? 'pencil' : 'plus',
				label: answer ? '' : t('GLOBAL.CREATE_MODEL', { name: t('POLL.ANSWERS.SINGLE') }),
			}}
			submit={{
				label: answer ? t('GLOBAL.UPDATE') : t('GLOBAL.CREATE'),
				onClick: (values) => {
					if (answer) {
						return mutateUpdate.mutateAsync({ answerId: answer.id, values });
					} else {
						return mutateCreate.mutateAsync(values);
					}
				},
			}}
			popup={{
				title: answer
					? t('GLOBAL.UPDATE_MODEL', { name: t('POLL.ANSWERS.SINGLE') })
					: t('GLOBAL.CREATE_MODEL', { name: t('POLL.ANSWERS.SINGLE') }),
			}}>
			<Form.Layout.Field label={t('POLL.ANSWERS.LABELS.CONTENTS')}>
				<Form.Input.Text name="contents" />
			</Form.Layout.Field>

			{articles?.data?.items && (
				<Form.Layout.Repeater source="articles" entryName="Artikel" label="Artikelen">
					<Form.Input.Select
						name="prepr_id"
						options={modelToOptions(articlesToModel(articles?.data?.items), 'title')}
					/>
				</Form.Layout.Repeater>
			)}
		</ButtonWithPopup>
	);
};
