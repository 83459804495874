import * as Yup from 'yup';
import { useState } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import { useList } from '@socialbrothers/hooks';
import { OPERATORS } from '@socialbrothers/constants';
import { Form } from '@socialbrothers/components/Containers';
import { isCreate, modelToOptions, YupUtils } from '@socialbrothers/utils';

import {
	IQuestionnaire,
	QuestionnaireService,
	QUESTIONNAIRE_TYPE,
	RoleService,
} from '@Services/index';

import { RoleFormProps } from './RoleForm.props';

export const RoleForm = ({ ...props }: RoleFormProps) => {
	const { id } = useParams<any>();
	const [initialName, setInitialName] = useState('');
	const { t } = useTranslation();

	const questionnaire = useList<IQuestionnaire>(QuestionnaireService, {
		sortBy: 'title',
		perPage: Number.MAX_SAFE_INTEGER,
		filters: {
			published: [
				{
					operator: OPERATORS.IS,
					value: true,
				},
			],
			type: [
				{
					operator: OPERATORS.IS,
					value: QUESTIONNAIRE_TYPE.OOM,
				},
			],
		},
	});

	const validationSchema = Yup.object().shape({
		name: Yup.string()
			.test(YupUtils.FieldValidation(RoleService, t('ROLE.LABELS.NAME'), initialName))
			.required(),
		base_questionnaire_id: Yup.string().required(),
	});

	const handleDataFetched = (values: any) => {
		!isCreate(id) && setInitialName(values.name);
	};

	return (
		<Form.Resource
			service={RoleService}
			validationSchema={validationSchema}
			onDataFetched={handleDataFetched}
			label={t('ROLE.SINGLE')}
			id={id}
			deleteConfig={{
				name: 'name',
				redirect: '/roles',
			}}>
			<Form.Layout.Field label={t('ROLE.LABELS.NAME')}>
				<Form.Input.Text name="name" />
			</Form.Layout.Field>

			<Form.Layout.Field label={t('ROLE.LABELS.IS_DEFAULT')}>
				<Form.Input.Checkbox
					name="is_default"
					options={[
						{
							key: 1,
							value: '' + t('ROLE.LABELS.IS_DEFAULT_LABEL'),
						},
					]}
				/>
			</Form.Layout.Field>

			{questionnaire.data?.data && (
				<Form.Layout.Field label={t('ROLE.LABELS.QUESTIONNAIRE')}>
					<Form.Input.Select
						name="base_questionnaire_id"
						options={modelToOptions(questionnaire.data?.data, 'title')}
					/>
				</Form.Layout.Field>
			)}
		</Form.Resource>
	);
};
