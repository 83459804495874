import { useQuery } from 'react-query';
import { useEffect, useRef, useState } from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { TableProvider, useTable } from '@socialbrothers/hooks';
import { getFilterableFields } from '@socialbrothers/utils';
import { Button, DebounceInput, Pagination } from '@socialbrothers/components/UI';
import { Table } from '@socialbrothers/components/Containers';
import TableStore, { SORT_DIRECTION } from '@socialbrothers/stores/Table/TableStore';

import Filter from '../Filter/Filter';

import { ResourceTableProps } from './ResourceTable.props';
import styles from './ResourceTable.module.scss';

const TableHeader = observer(() => {
	const { t } = useTranslation();
	const table = useTable();
	const ref = useRef();

	const onChange = ({ value }: { value: string }) => {
		table.filter.setSearch(value);
	};

	return (
		<>
			<div className={styles.TableHeader}>
				<div className={styles.TableHeader__Search}>
					<DebounceInput placeholder={t('TABLE.HEADER.SEARCH')} onChange={onChange} />
				</div>

				{Object.keys(getFilterableFields(table.fields)).length > 0 && (
					<Button
						className={styles.TableHeader__Add}
						icon="plus"
						type="button"
						link
						onClick={() => (ref as any).current.append()}>
						{t('TABLE.HEADER.FILTER.ADD')}
					</Button>
				)}
			</div>

			{Object.keys(getFilterableFields(table.fields)).length > 0 && <Filter ref={ref} />}
		</>
	);
});

const TableFooter = observer(() => {
	const table = useTable();
	const { t } = useTranslation();

	const onChange = (page: number) => {
		table.filter.setPage(page);
	};

	return (
		<div className={styles.TableFooter}>
			<div className={styles.PerPage}>
				<div>{t('TABLE.FOOTER.SHOW')}</div>

				<select
					onChange={(event) => table.filter.setPerPage(parseInt(event.target.value))}
					value={table.filter.perPage}>
					<option value="10">10</option>
					<option value="25">25</option>
					<option value="50">50</option>
					<option value="100">100</option>
				</select>

				<div>{t('TABLE.FOOTER.SHOW_ROWS')}</div>

				<div className={styles.Results}>
					{t('TABLE.FOOTER.RESULTS', {
						min: (table.filter.page - 1) * table.filter.perPage + 1,
						max:
							table.filter.page * table.filter.perPage > table.filter.totalResults
								? table.filter.totalResults
								: table.filter.page * table.filter.perPage,
						total: table.filter.totalResults,
					})}
				</div>
			</div>

			<Pagination
				className={styles.Pagination}
				count={table.filter.totalResults}
				perPage={table.filter.perPage}
				onChange={(props) => onChange(props)}
			/>
		</div>
	);
});

function ResourceTable<T>({
	children,
	service,
	defaultFilters,
	defaultParams,
	className,
	...props
}: ResourceTableProps<T>) {
	const [results, setResults] = useState([]);
	const fields = children
		.filter((child: JSX.Element | boolean) => child !== false)
		.map((child: JSX.Element) => {
			return {
				...{ type: child.type.displayName },
				...child.props,
			};
		});

	const filter = useLocalObservable(() =>
		TableStore.create({
			perPage: 25,
			page: 1,
			totalResults: -1,
			defaultFilters: defaultFilters as any,
			sortBy: '',
			search: '',
			sortDirection: SORT_DIRECTION.ASCENDING,
		}),
	);

	const provider = {
		data: results,
		fields: fields,
		filter: filter,
		service: service,
	};

	const { data, isSuccess, isFetching } = useQuery(
		[service.endpoint, JSON.stringify({ ...filter.getFilter, ...defaultParams })],
		() => {
			return service.getList(filter.getFilter as any, defaultParams) as any;
		},
		{ keepPreviousData: true },
	);

	useEffect(() => {
		if (isSuccess) {
			setResults(data.data);
			filter.setTotalResults(data.total);
		}
	}, [isSuccess, data, filter]);

	return (
		<TableProvider value={provider}>
			<div className={className}>
				{isFetching}

				<TableHeader />

				<Table.Base data={results} filter={filter}>
					{children}
				</Table.Base>

				<TableFooter />
			</div>
		</TableProvider>
	);
}

export default observer(ResourceTable);
