import _ from 'lodash';

import { Icon } from '@socialbrothers/components/UI';

import styles from './DebounceInput.module.scss';
import { DebounceInputProps } from './DebounceInput.props';

const DebounceInput = ({
	icon = 'search',
	debounce = 500,
	placeholder = '',
	onChange,
	...props
}: DebounceInputProps) => {
	const handleChange = _.debounce(async (event) => {
		onChange({ value: event.target.value });
	}, debounce);

	return (
		<div className={styles.DebounceInput} {...props}>
			<input type="text" placeholder={placeholder} onChange={handleChange} />
			<Icon className={styles.Icon} icon={icon} />
		</div>
	);
};

export default DebounceInput;
