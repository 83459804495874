import i18next from 'i18next';

import { ForgotPasswordPage, LoginPage, ResetPasswordPage } from '@socialbrothers/pages';

const routes = [
	{
		key: 'LoginPage',
		label: 'Login',
		icon: 'user',

		path: '/',
		component: LoginPage,
		exact: true,
		hidden: () => false,
	},
	{
		key: 'ForgotPasswordPage',
		label: i18next.t('FORGOT_PASSWORD.LABEL'),
		icon: 'user',

		path: '/forgot-password',
		component: ForgotPasswordPage,
		exact: true,
		hidden: () => false,
	},
	{
		key: 'ResetPasswordPage',
		label: i18next.t('RESET_PASSWORD.LABEL'),
		icon: 'user',
		path: '/reset-password',
		component: ResetPasswordPage,
		exact: true,
	},
];
export default routes;
