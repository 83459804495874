import { useTranslation } from 'react-i18next';

import { Page } from '@socialbrothers/components/Layout';
import { Button, Card } from '@socialbrothers/components/UI';
import { COLOR } from '@socialbrothers/constants';

import { CompanyTable } from '@Components/tables/index';

export const CompanyOverviewPage = () => {
	const { t } = useTranslation();

	const buttons = (
		<Button icon="plus" to="companies/create" color={COLOR.SECONDARY}>
			{t('COMPANY.CREATE')}
		</Button>
	);

	return (
		<Page title={t('COMPANY.PLURAL')} buttons={buttons}>
			<Card title={t('COMPANY.PLURAL')}>
				<CompanyTable />
			</Card>
		</Page>
	);
};
