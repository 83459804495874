import { useRef } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import { useOnClickOutside, useToggle } from '@socialbrothers/hooks';

import Icon from '../Icon/Icon';

import styles from './DropdownButton.module.scss';
import { DropdownButtonProps } from './DropdownButton.props';

const DropdownButton = ({ render, groups, className, ...props }: DropdownButtonProps) => {
	const { toggle, isToggle, setToggle } = useToggle(false);
	const ref = useRef<any>();

	useOnClickOutside(ref, () => {
		setToggle(false);
	});

	return (
		<div ref={ref} className={styles.DropdownButton} {...props}>
			<div onClick={toggle} className={styles['DropdownButton__Button']}>
				{render()}
				<Icon className={styles['DropdownButton__Chevron']} type="regular" icon="chevron-down" />
			</div>
			{isToggle && (
				<div className={styles['DropdownButton__Dropdown']}>
					{groups.map((group) => {
						return (
							<div className={styles['DropdownButton__Group']}>
								{group.map((item) => {
									return item.onPress ? (
										<span
											className={cn(
												styles['DropdownButton__Item'],
												styles['DropdownButton__Item--Clickable'],
											)}
											onClick={item.onPress}>
											{item.label}
										</span>
									) : (
										<Link to={item.link as string} className={styles['DropdownButton__Item']}>
											{item.label}
										</Link>
									);
								})}
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
};
export default DropdownButton;
