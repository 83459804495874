import cn from 'classnames';

import { COLOR } from '@socialbrothers/constants';

import styles from './KPI.module.scss';
import { KPIProps } from './KPI.props';
import { Icon, Progress } from '@socialbrothers/components/UI';

const KPI = ({
	title,
	value,
	progress,
	icon,
	color = COLOR.PRIMARY,
	className,
	...props
}: KPIProps) => {
	return (
		<div
			className={cn([styles.KPI, className], {
				[`border-left-${color}`]: color,
			})}
			{...props}>
			<div className={styles.Body}>
				<div className={cn([styles.Title, `color-${color}`])}>{title}</div>

				<div className={styles.Value}>
					{`${value}${!!progress ? '%' : ''}`}

					{progress && (
						<Progress
							className={styles.Progress}
							value={parseInt(value.toString())}
							color={color}
						/>
					)}
				</div>
			</div>

			<Icon className={styles.Icon} icon={icon} />
		</div>
	);
};

export default KPI;
