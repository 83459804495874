import { ResourceService } from '@socialbrothers/services';
import { BaseModel } from '@socialbrothers/constants';

import MenselyService from './MenselyService';
import { IThemeGroup } from './ThemeGroupService';

export interface ITheme extends BaseModel {
	name: string;
	description?: string;
	theme_group_id: number;
	theme: IThemeGroup;
}

export class ThemeServiceImplementation extends ResourceService<ITheme> {}

export const ThemeService = new ThemeServiceImplementation(MenselyService, '/admin/themes');
