import { useCallback, useState } from 'react';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import { API_ERRORS, COLOR, KeyValue } from '@socialbrothers/constants';
import { ButtonWithPopup, Form } from '@socialbrothers/components/Containers';
import { enumToOptions, modelToOptions, parseMails } from '@socialbrothers/utils';
import { useList, useResource } from '@socialbrothers/hooks';

import styles from './UserInviteForm.module.scss';

import {
	UserService,
	ACCOUNT_TYPE,
	CompanyService,
	UserInviteRequest,
	ICompany,
} from '@Services/index';

import { UserInviteFormProps, UserInviteFormValues } from './UserInviteForm.props';
import { get } from 'lodash';

export const UserInviteForm = ({ companyId }: UserInviteFormProps) => {
	const isUser = !!companyId;
	const { t } = useTranslation();
	const [mails, setMails] = useState<string>();
	const [usedMails, setUsedMails] = useState<string[]>([]);
	const queryClient = useQueryClient();
	const { data: company } = useResource<ICompany>(CompanyService, companyId);

	const companies = useList<ICompany>(
		CompanyService,
		{
			sortBy: 'name',
			perPage: Number.MAX_SAFE_INTEGER,
		},
		!isUser,
	);

	const mutateInvite = useMutation(
		(values: UserInviteRequest) => {
			setUsedMails([]);

			if (isUser) {
				return CompanyService.inviteSupervisors(companyId!, values);
			} else {
				return UserService.invite(values);
			}
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(UserService.endpoint);
				toast.success(t('GLOBAL.INVITED_SUCCESSFUL', { name: t('USERS.SINGLE') }));
			},

			onError: (error: any, data) => {
				if (
					error.response.data.code === API_ERRORS.INVALID_REQUEST &&
					error.response.data.additional
				) {
					const mails = Object.keys(error.response.data.additional)
						.map((e) => get(data, e))
						.filter(Boolean);

					if (mails) {
						setUsedMails(mails);
					}
				}

				toast.error(t('GLOBAL.INVITED_UNSUCCESSFUL', { name: t('USERS.SINGLE') }));
			},
		},
	);

	const validationSchema = Yup.object().shape({
		account_type: isUser ? Yup.string() : Yup.string().required(),
		emails: Yup.string().required(),
		companies: isUser ? Yup.string() : Yup.array().min(1).required(),
	});

	const handleUpload = useCallback(async (value: any) => {
		if (value) {
			const _mails = await parseMails(value);

			if (_mails) {
				setMails(_mails);
			}
		}
	}, []);

	return (
		<ButtonWithPopup
			withForm
			validationSchema={validationSchema}
			initialValues={{
				mail_body: company?.lni_settings?.mail_welcome_intro,
				users: mails,
			}}
			button={{
				color: isUser ? COLOR.PRIMARY : COLOR.SECONDARY,
				icon: isUser ? 'user-plus' : 'envelope',
				label: isUser ? '' : t('USERS.INVITE'),
			}}
			submit={{
				label: isUser ? t('GLOBAL.UPDATE') : t('USERS.INVITE'),
				onClick: (values: UserInviteFormValues) => {
					return mutateInvite.mutateAsync({
						...values,
						emails: values.emails?.replaceAll(' ', '').split(',') || [],
					});
				},
			}}
			popup={{
				title: t('USERS.INVITE'),
			}}>
			{isUser ? (
				<Form.Layout.Field label={t('USERS.LABELS_INVITE.MAIL_BODY')}>
					<Form.Input.RichText name="mail_body" />
				</Form.Layout.Field>
			) : (
				<Form.Layout.Field label={t('USERS.LABELS_INVITE.ACCOUNT_TYPE')}>
					<Form.Input.Select
						name="account_type"
						options={enumToOptions(ACCOUNT_TYPE, 'ACCOUNT_TYPE').filter(
							(accountType: KeyValue) =>
								![ACCOUNT_TYPE.ADMIN.toString(), ACCOUNT_TYPE.USER.toString()].includes(
									String(accountType.key),
								),
						)}
					/>
				</Form.Layout.Field>
			)}

			{companies?.data?.data && (
				<Form.Layout.Field label={t('USERS.LABELS_INVITE.COMPANIES')}>
					<Form.Input.Select
						name="companies"
						isMulti
						options={modelToOptions(companies.data.data, 'name')}
					/>
				</Form.Layout.Field>
			)}

			<Form.Layout.Group label={t('USERS.LABELS_INVITE.EMAILS_GROUP')}>
				<Form.Layout.Field onChange={handleUpload} label={t('USERS.LABELS_INVITE.CSV')}>
					<Form.Input.File name="file" thumbnail={false} accept=".csv" />
				</Form.Layout.Field>

				<Form.Layout.Field label={t('USERS.LABELS_INVITE.EMAILS')}>
					<Form.Input.Multiline name="emails" defaultValue={mails} />
				</Form.Layout.Field>

				{usedMails.map((mail, idx) => {
					return (
						<div className={styles.Error} key={idx}>
							{t('USERS.LABELS_INVITE.EMAIL_IN_USE', {
								mail,
							})}
						</div>
					);
				})}
			</Form.Layout.Group>
		</ButtonWithPopup>
	);
};
