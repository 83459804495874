import BaseService from './BaseService';

export interface ServiceInterface {
	endpoint: string;
}
export class Service implements ServiceInterface {
	client: BaseService;
	endpoint: string;

	constructor(baseService: BaseService, endpoint: string) {
		this.client = baseService;
		this.endpoint = endpoint;
	}
}
