import { Instance, types } from 'mobx-state-tree';

const SidebarModel = types
	.model('SidebarModel', {
		isOpen: false,
	})
	.actions((self) => ({
		setIsOpen(isOpen: boolean) {
			self.isOpen = isOpen;
		},

		toggleIsOpen() {
			self.isOpen = !self.isOpen;
		},
	}));

export interface SidebarInstance extends Instance<typeof SidebarModel> {}
export default SidebarModel;
