import { forwardRef } from 'react';
import { useFormContext } from 'react-hook-form';
import cn from 'classnames';

import styles from './CheckboxInput.module.scss';
import { CheckboxInputProps } from './CheckboxInput.props';

const CheckboxInput = (
	{ options, inline = false, name, ...props }: CheckboxInputProps,
	ref?: any,
) => {
	const { register } = useFormContext();

	return (
		<div className={cn(styles.CheckboxInput, { [styles.Inline]: inline })} {...props}>
			{options.map(({ key, value }) => {
				return (
					<div className={styles.Option} key={key}>
						<input
							type="checkbox"
							name={name}
							id={String(`${name}-${key}`)}
							value={key}
							ref={register()}
						/>
						<label htmlFor={String(`${name}-${key}`)}>{value}</label>
					</div>
				);
			})}
		</div>
	);
};

export default forwardRef(CheckboxInput);
