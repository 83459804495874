import { ResourceService } from '@socialbrothers/services';
import { BaseModel } from '@socialbrothers/constants';

import { ICompany } from '@Services/index';

import MenselyService from './MenselyService';

export interface IMessage extends BaseModel {
	title: string;
	message: string;
	company: ICompany;
	company_id: number;
	total_read: number;
	total_recipients: number;
}

export class MessageServiceImplementation extends ResourceService<IMessage> {
	recipients = async (companyId: string, accountType?: string) => {
		const response = await this.client.get<{ total: number }>(`${this.endpoint}/recipients`, {
			company_id: companyId,
			account_type: accountType,
		});

		return response.data.total;
	};
}

export const MessageService = new MessageServiceImplementation(
	MenselyService,
	'/admin/pni/messages',
);
