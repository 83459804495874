import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import { useList, useResource } from '@socialbrothers/hooks';
import { ButtonWithPopup, Form, Table } from '@socialbrothers/components/Containers';
import { COLOR, KeyValue, OPERATORS } from '@socialbrothers/constants';
import { modelToOptions } from '@socialbrothers/utils';

import {
	CompanyService,
	COURSE_TYPE,
	QuestionnaireService,
	IQuestionnaire,
	ICourse,
	CourseService,
	ICompanyLNIAccess,
	QUESTIONNAIRE_TYPE,
} from '@Services/index';

import {
	ModulesLNIAccessPopupProps,
	ModulesLNIAccessPopupFormProps,
	ModulesLNIAccessProps,
} from './ModulesLNIAccess.props';
import { useState } from 'react';

const ModulesLNIAccessForm = ({ id, isEdit, record }: ModulesLNIAccessPopupProps) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const company = useResource(CompanyService, id);
	const [hasAssignment, setHasAssignment] = useState();

	const courses = useList<ICourse>(CourseService, {
		sortBy: 'title',
		perPage: Number.MAX_SAFE_INTEGER,
		filters: {
			type: [
				{
					operator: OPERATORS.IS,
					value: COURSE_TYPE.COURSE,
				},
			],
		},
	});

	const questionnaire = useList<IQuestionnaire>(QuestionnaireService, {
		sortBy: 'title',
		perPage: Number.MAX_SAFE_INTEGER,
		filters: {
			published: [
				{
					operator: OPERATORS.IS,
					value: true,
				},
			],
			type: [
				{
					operator: OPERATORS.IS,
					value: QUESTIONNAIRE_TYPE.LNI,
				},
			],
		},
	});

	const mutateCreateCourse = useMutation(
		(values: ModulesLNIAccessPopupFormProps) => CompanyService.createCourse(id, values),
		{
			onSuccess: () => {
				queryClient.invalidateQueries([CompanyService.endpoint, id]);

				toast.success(
					t('GLOBAL.CREATED_SUCCESSFUL', {
						name: t('COMPANY.MODULE.COURSES.COURSE'),
					}),
				);
			},
		},
	);

	const mutateEditCourse = useMutation(
		(values: ModulesLNIAccessPopupFormProps) =>
			CompanyService.updateCourse(id, record?.id || 0, { ...values, ...{ id: record?.id } }),
		{
			onSuccess: () => {
				queryClient.invalidateQueries([CompanyService.endpoint, id]);

				toast.success(
					t('GLOBAL.UPDATED_SUCCESSFUL', {
						name: t('COMPANY.MODULE.COURSES.COURSE'),
					}),
				);
			},
		},
	);

	const validationSchema = Yup.object().shape({
		course_id: isEdit ? Yup.string() : Yup.string().required(),
		trainer_email: Yup.string().email().required(),
		company_assignment: Yup.object().when('assignment_enabled', {
			is: 'true',
			then: (s) =>
				s.shape({
					title: Yup.string().required(),
					description: Yup.string().required(),
					questionnaire: Yup.object().shape({
						id: Yup.string().required(),
					}),
				}),
		}),
	});

	return (
		<ButtonWithPopup
			withForm
			initialValues={{ ...record, assignment_enabled: !!record?.company_assignment_id && 'true' }}
			validationSchema={validationSchema}
			button={
				isEdit
					? {
							icon: 'pencil',
							color: COLOR.PRIMARY,
					  }
					: {
							icon: 'plus',
							color: COLOR.SECONDARY,
							label: t('COMPANY.MODULE.COURSES.ADD_COURSE'),
					  }
			}
			submit={{
				label: t('GLOBAL.SAVE'),
				onClick: (values: ModulesLNIAccessPopupFormProps) => {
					if (isEdit) {
						return mutateEditCourse.mutateAsync(values);
					} else {
						return mutateCreateCourse.mutateAsync(values);
					}
				},
			}}
			popup={{
				title: isEdit
					? t('COMPANY.MODULE.COURSES.EDIT_COURSE', { name: record?.course?.title })
					: t('COMPANY.MODULE.COURSES.ADD_COURSE'),
			}}>
			{!isEdit && (
				<Form.Layout.Field label={t('COMPANY.MODULE.COURSES.COURSE')}>
					<Form.Input.Select
						name="course_id"
						options={
							courses.data?.data &&
							modelToOptions(courses?.data?.data, 'title').filter((role: KeyValue) => {
								const exists = company?.data?.access?.some(
									(access: ICompanyLNIAccess) => role.key === access.course_id,
								);

								role.disabled = exists;

								return role;
							})
						}
					/>
				</Form.Layout.Field>
			)}

			<Form.Layout.Field label={t('COMPANY.MODULE.COURSES.TRAINER')}>
				<Form.Input.Text name="trainer_email" />
			</Form.Layout.Field>

			<Form.Layout.Field
				label={t('COMPANY.MODULE.COURSES.HAS_COMPANY_ASSIGNMENT')}
				onChange={(value) => {
					if (value !== hasAssignment) {
						setHasAssignment(value);
					}
				}}>
				<Form.Input.Checkbox
					name="assignment_enabled"
					options={[{ key: 'true', value: String(t('GLOBAL.YES')) }]}
				/>
			</Form.Layout.Field>

			{hasAssignment && (
				<Form.Layout.Group label={t('COMPANY.MODULE.COURSES.COMPANY_ASSIGNMENT')}>
					<Form.Layout.Field label={t('COMPANY.MODULE.COURSES.TITLE')}>
						<Form.Input.Text name="company_assignment.title" />
					</Form.Layout.Field>

					<Form.Layout.Field label={t('COMPANY.MODULE.COURSES.DESCRIPTION')}>
						<Form.Input.Text name="company_assignment.description" />
					</Form.Layout.Field>

					<Form.Layout.Field label={t('COMPANY.MODULE.COURSES.QUESTIONNAIRE')}>
						<Form.Input.Select
							name="company_assignment.questionnaire.id"
							options={
								questionnaire.data?.data && modelToOptions(questionnaire.data?.data, 'title')
							}
						/>
					</Form.Layout.Field>
				</Form.Layout.Group>
			)}
		</ButtonWithPopup>
	);
};

export const ModulesLNIAccess = ({ id }: ModulesLNIAccessProps) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const company = useResource(CompanyService, id);

	const mutateDeleteRole = useMutation(
		(courseId: number) => CompanyService.deleteCourse(id, courseId),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(CompanyService.endpoint);
				queryClient.invalidateQueries([CompanyService.endpoint, id]);

				toast.success(t('GLOBAL.DELETED_SUCCESSFUL', { name: t('COMPANY.MODULE.COURSES.COURSE') }));
			},
		},
	);

	return (
		<ButtonWithPopup
			button={{
				icon: 'graduation-cap',
			}}
			popup={{
				title: t('ENUM.MODULE.LNI'),
			}}>
			<>
				<div className="mb-sm d-flex">
					<div className="ml-auto">
						<ModulesLNIAccessForm id={id} />
					</div>
				</div>

				<Table.Base data={company?.data.access}>
					<Table.Field.Function
						label={t('COMPANY.MODULE.COURSES.COURSE')}
						source="id"
						render={(record: ICompanyLNIAccess) => record.course.title}
					/>

					<Table.Field.BaseAction>
						<Table.Action.Field
							render={(record: ICompanyLNIAccess) => (
								<ModulesLNIAccessForm id={id} record={record} isEdit />
							)}
						/>

						<Table.Action.Button
							icon="trash-alt"
							color={COLOR.DANGER}
							onClick={(record: ICompanyLNIAccess) => {
								mutateDeleteRole.mutateAsync(record.id);
							}}
						/>
					</Table.Field.BaseAction>
				</Table.Base>
			</>
		</ButtonWithPopup>
	);
};
