import * as Yup from 'yup';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import { Form } from '@socialbrothers/components/Containers';
import { ThemeGroupService } from '@Services/index';

import { ThemeGroupFormProps } from './ThemeGroupForm.props';

export const ThemeGroupForm = ({ ...props }: ThemeGroupFormProps) => {
	const { id } = useParams<any>();
	const { t } = useTranslation();

	const validationSchema = Yup.object().shape({
		name: Yup.string().required(),
	});

	return (
		<Form.Resource
			validationSchema={validationSchema}
			service={ThemeGroupService}
			label={t('THEMEGROUP.SINGLE')}
			id={id}
			deleteConfig={{
				redirect: '/themegroups',
				name: 'name',
			}}>
			<Form.Layout.Field label={t('THEMEGROUP.LABELS.NAME')}>
				<Form.Input.Text name="name" />
			</Form.Layout.Field>
		</Form.Resource>
	);
};
