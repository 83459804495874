import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import { Page } from '@socialbrothers/components/Layout';
import { useResource } from '@socialbrothers/hooks';
import { Card } from '@socialbrothers/components/UI';

import { CompanyForm } from '@Components/forms';
import { CompanyContacts, CompanyModules, CompanyUsers } from '@Components/modules';
import { CompanyService } from '@Services/index';

export const CompanyDetailPage = () => {
	const { t } = useTranslation();
	const { id } = useParams<any>();
	const { data } = useResource(CompanyService, id);

	const name = data?.name || t('COMPANY.SINGLE');

	return (
		<Page title={name}>
			<Card title={t('COMPANY.GENERAL.TITLE')}>
				<CompanyForm />
			</Card>

			{data && (
				<>
					<CompanyModules id={id} />
					<CompanyUsers id={id} />
					<CompanyContacts id={id} />
				</>
			)}
		</Page>
	);
};
