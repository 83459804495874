import i18n from 'i18next';

import { Routes, Sidebar } from '@socialbrothers/constants/interfaces';
import { getRouteByKey } from '@socialbrothers/utils';

import {
	CompanyOverviewPage,
	CompanyDetailPage,
	DashboardPage,
	UserOverviewPage,
	UserDetailPage,
	ThemeOverviewPage,
	ThemeDetailPage,
	ThemeGroupOverviewPage,
	ThemeGroupDetailPage,
	CourseOverviewPage,
	CourseDetailPage,
	ChallengeDetailPage,
	ChallengeOverviewPage,
	PollOverviewPage,
	PollDetailPage,
	AOMQuestionOverviewPage,
	AOMQuestionDetailPage,
	OOMQuestionOverviewPage,
	OOMQuestionDetailPage,
	LNIQuestionOverviewPage,
	LNIQuestionDetailPage,
	MomentOverviewPage,
	MomentDetailPage,
	AOMSettingsDetailPage,
	RoleOverviewPage,
	RoleDetailPage,
	LNIQuestionnaireOverviewPage,
	LNIQuestionnaireDetailPage,
	OOMQuestionnaireDetailPage,
	OOMQuestionnaireOverviewPage,
	AOMQuestionnaireOverviewPage,
	AOMQuestionnaireDetailPage,
	ONBQuestionnaireDetailPage,
	MessageOverviewPage,
	MessageDetailPage,
	AdminOverviewPage,
	AdminDetailPage,
} from '@Pages/index';

export const routes: Routes = [
	{
		key: 'DashboardPage',
		label: 'Dashboard',
		icon: 'tachometer-alt',

		path: '/',
		component: DashboardPage,
		exact: true,
	},

	{
		key: 'AOMQuestionOverviewPage',
		label: i18n.t('QUESTION.PLURAL'),
		icon: 'question',
		path: '/questions/aom',
		component: AOMQuestionOverviewPage,
		exact: true,
		hidden: () => false,
	},

	{
		key: 'AOMQuestionDetailPage',
		label: i18n.t('QUESTION.SINGLE'),
		icon: 'question',
		path: '/questions/aom/:id',
		component: AOMQuestionDetailPage,
		exact: true,
		hidden: () => false,
	},

	{
		key: 'LNIQuestionOverviewPage',
		label: i18n.t('QUESTION.PLURAL'),
		icon: 'question',
		path: '/questions/lni',
		component: LNIQuestionOverviewPage,
		exact: true,
		hidden: () => false,
	},

	{
		key: 'LNIQuestionDetailPage',
		label: i18n.t('QUESTION.SINGLE'),
		icon: 'question',
		path: '/questions/lni/:id',
		component: LNIQuestionDetailPage,
		exact: true,
		hidden: () => false,
	},

	{
		key: 'OOMQuestionOverviewPage',
		label: i18n.t('QUESTION.PLURAL'),
		icon: 'question',
		path: '/questions/oom',
		component: OOMQuestionOverviewPage,
		exact: true,
		hidden: () => false,
	},

	{
		key: 'OOMQuestionDetailPage',
		label: i18n.t('QUESTION.SINGLE'),
		icon: 'question',
		path: '/questions/oom/:id',
		component: OOMQuestionDetailPage,
		exact: true,
		hidden: () => false,
	},

	{
		key: 'AOMQuestionnaireOverviewPage',
		label: i18n.t('QUESTIONNAIRE.PLURAL'),
		icon: 'map-marker-question',

		path: '/questionnaires/aom',
		component: AOMQuestionnaireOverviewPage,
		exact: true,
		hidden: () => false,
	},

	{
		key: 'AOMQuestionnaireDetailPage',
		label: i18n.t('QUESTIONNAIRE.SINGLE'),
		icon: 'map-marker-question',

		path: '/questionnaires/aom/:id',
		component: AOMQuestionnaireDetailPage,
		exact: true,
		hidden: () => false,
	},

	{
		key: 'LNIQuestionnaireOverviewPage',
		label: i18n.t('QUESTIONNAIRE.PLURAL'),
		icon: 'map-marker-question',

		path: '/questionnaires/lni',
		component: LNIQuestionnaireOverviewPage,
		exact: true,
		hidden: () => false,
	},

	{
		key: 'LNIQuestionnaireDetailPage',
		label: i18n.t('QUESTIONNAIRE.SINGLE'),
		icon: 'map-marker-question',

		path: '/questionnaires/lni/:id',
		component: LNIQuestionnaireDetailPage,
		exact: true,
		hidden: () => false,
	},

	{
		key: 'OOMQuestionnaireOverviewPage',
		label: i18n.t('QUESTIONNAIRE.PLURAL'),
		icon: 'map-marker-question',

		path: '/questionnaires/oom',
		component: OOMQuestionnaireOverviewPage,
		exact: true,
		hidden: () => false,
	},

	{
		key: 'OOMQuestionnaireDetailPage',
		label: i18n.t('QUESTIONNAIRE.SINGLE'),
		icon: 'map-marker-question',

		path: '/questionnaires/oom/:id',
		component: OOMQuestionnaireDetailPage,
		exact: true,
		hidden: () => false,
	},

	{
		key: 'ONBQuestionnaireDetailPage',
		label: i18n.t('QUESTIONNAIRE.ONBOARDING'),
		icon: 'question',
		path: '/questionnaires/onb',
		component: ONBQuestionnaireDetailPage,
		exact: true,
		hidden: () => false,
	},

	{
		key: 'RoleOverviewPage',
		label: i18n.t('ROLE.PLURAL'),
		icon: 'user-tag',

		path: '/roles',
		component: RoleOverviewPage,
		exact: true,
		hidden: () => false,
	},

	{
		key: 'RoleDetailPage',
		label: i18n.t('ROLE.SINGLE'),
		icon: 'user-tag',

		path: '/roles/:id',
		component: RoleDetailPage,
		exact: true,
		hidden: () => false,
	},

	{
		key: 'AOMSettingsDetailPage',
		label: i18n.t('AOMSETTINGS.PLURAL'),
		icon: 'cogs',

		path: '/advies-op-maat',
		component: AOMSettingsDetailPage,
		exact: true,
		hidden: () => false,
	},

	{
		key: 'MomentOverviewPage',
		label: i18n.t('MOMENT.PLURAL'),
		icon: 'calendar-day',

		path: '/moments',
		component: MomentOverviewPage,
		exact: true,
		hidden: () => false,
	},

	{
		key: 'MomentDetailPage',
		label: i18n.t('MOMENT.SINGLE'),
		icon: 'calendar-day',

		path: '/moments/:id',
		component: MomentDetailPage,
		exact: true,
		hidden: () => false,
	},

	{
		key: 'PollOverviewPage',
		label: i18n.t('POLL.PLURAL'),
		icon: 'poll',

		path: '/polls',
		component: PollOverviewPage,
		exact: true,
		hidden: () => false,
	},

	{
		key: 'PollDetailPage',
		label: i18n.t('POLL.SINGLE'),
		icon: 'poll',

		path: '/polls/:id',
		component: PollDetailPage,
		exact: true,
		hidden: () => false,
	},

	{
		key: 'CourseOverviewPage',
		label: i18n.t('COURSE.COURSE.PLURAL'),
		icon: 'chalkboard-teacher',

		path: '/courses',
		component: CourseOverviewPage,
		exact: true,
		hidden: () => false,
	},

	{
		key: 'CourseDetailPage',
		label: i18n.t('COURSE.COURSE.SINGLE'),
		icon: 'chalkboard-teacher',

		path: '/courses/:id',
		component: CourseDetailPage,
		exact: true,
		hidden: () => false,
	},

	{
		key: 'ChallengeOverviewPage',
		label: i18n.t('COURSE.CHALLENGE.PLURAL'),
		icon: 'user-graduate',

		path: '/challenges',
		component: ChallengeOverviewPage,
		exact: true,
		hidden: () => false,
	},

	{
		key: 'ChallengeDetailPage',
		label: i18n.t('COURSE.CHALLENGE.SINGLE'),
		icon: 'chalkboard-teacher',

		path: '/challenges/:id',
		component: ChallengeDetailPage,
		exact: true,
		hidden: () => false,
	},

	{
		key: 'ThemeGroupOverviewPage',
		label: i18n.t('THEMEGROUP.PLURAL'),
		icon: 'folder-open',

		path: '/themegroups',
		component: ThemeGroupOverviewPage,
		exact: true,
		hidden: () => false,
	},

	{
		key: 'ThemeGroupDetailPage',
		label: i18n.t('THEMEGROUP.SINGLE'),
		icon: 'folder-open',

		path: '/themegroups/:id',
		component: ThemeGroupDetailPage,
		exact: true,
		hidden: () => false,
	},

	{
		key: 'ThemeOverviewPage',
		label: i18n.t('THEME.PLURAL'),
		icon: 'folder-open',

		path: '/themes',
		component: ThemeOverviewPage,
		exact: true,
		hidden: () => false,
	},

	{
		key: 'ThemeDetailPage',
		label: i18n.t('THEME.SINGLE'),
		icon: 'user',

		path: '/themes/:id',
		component: ThemeDetailPage,
		exact: true,
		hidden: () => false,
	},

	{
		key: 'CompanyOverviewPage',
		label: i18n.t('COMPANY.PLURAL'),
		icon: 'building',

		path: '/companies',
		component: CompanyOverviewPage,
		exact: true,
	},

	{
		key: 'CompanyDetailPage',
		label: i18n.t('COMPANY.SINGLE'),
		icon: 'building',

		path: '/companies/:id',
		component: CompanyDetailPage,
		exact: true,
	},

	{
		key: 'AdminOverviewPage',
		label: i18n.t('ADMINS.PLURAL'),
		icon: 'user',
		path: '/administrators',
		component: AdminOverviewPage,
		exact: true,
	},

	{
		key: 'AdminDetailPage',
		label: i18n.t('ADMINS.SINGLE'),
		icon: 'user',

		path: '/administrators/:id',
		component: AdminDetailPage,
		exact: true,
	},

	{
		key: 'UserOverviewPage',
		label: i18n.t('USERS.PLURAL'),
		icon: 'user',
		path: '/users',
		component: UserOverviewPage,
		exact: true,
	},

	{
		key: 'UserDetailPage',
		label: 'User',
		icon: 'user',

		path: '/users/:id',
		component: UserDetailPage,
		exact: true,
	},

	{
		key: 'MessageOverviewPage',
		label: i18n.t('MESSAGE.PLURAL'),
		icon: 'mailbox',
		path: '/messages',
		component: MessageOverviewPage,
		exact: true,
	},

	{
		key: 'MessageDetailPage',
		label: 'Message',
		icon: 'mailbox',

		path: '/messages/create',
		component: MessageDetailPage,
		exact: true,
	},
];

export const sidebar: Sidebar = [
	{
		title: i18n.t('GLOBAL.GENERAL'),
		routes: [
			getRouteByKey(routes, 'DashboardPage'),
			getRouteByKey(routes, 'UserOverviewPage'),
			getRouteByKey(routes, 'CompanyOverviewPage'),
			{
				key: i18n.t('THEME.PLURAL'),
				label: i18n.t('THEME.PLURAL'),
				icon: 'folder-open',
				children: [
					getRouteByKey(routes, 'ThemeOverviewPage'),
					getRouteByKey(routes, 'ThemeGroupOverviewPage'),
				],
			},
		],
	},
	{
		title: i18n.t('GLOBAL.MODULES.LNI'),
		routes: [
			{
				key: i18n.t('QUESTIONNAIRE.PLURAL'),
				label: i18n.t('QUESTIONNAIRE.PLURAL'),
				icon: 'map-marker-question',
				children: [
					getRouteByKey(routes, 'ONBQuestionnaireDetailPage'),
					getRouteByKey(routes, 'LNIQuestionnaireOverviewPage'),
					getRouteByKey(routes, 'LNIQuestionOverviewPage'),
				],
			},
			getRouteByKey(routes, 'CourseOverviewPage'),
			getRouteByKey(routes, 'ChallengeOverviewPage'),
			getRouteByKey(routes, 'MomentOverviewPage'),
			getRouteByKey(routes, 'PollOverviewPage'),
		],
	},
	{
		title: i18n.t('GLOBAL.MODULES.AOM'),
		routes: [
			{
				key: i18n.t('QUESTIONNAIRE.PLURAL'),
				label: i18n.t('QUESTIONNAIRE.PLURAL'),
				icon: 'map-marker-question',
				children: [
					getRouteByKey(routes, 'AOMQuestionnaireOverviewPage'),
					getRouteByKey(routes, 'AOMQuestionOverviewPage'),
				],
			},
			getRouteByKey(routes, 'AOMSettingsDetailPage'),
		],
	},
	{
		title: i18n.t('GLOBAL.MODULES.OOM'),
		routes: [
			{
				key: i18n.t('QUESTIONNAIRE.PLURAL'),
				label: i18n.t('QUESTIONNAIRE.PLURAL'),
				icon: 'map-marker-question',
				children: [
					getRouteByKey(routes, 'OOMQuestionnaireOverviewPage'),
					getRouteByKey(routes, 'OOMQuestionOverviewPage'),
				],
			},
			getRouteByKey(routes, 'RoleOverviewPage'),
		],
	},
	{
		title: i18n.t('GLOBAL.MODULES.PNI'),
		routes: [getRouteByKey(routes, 'MessageOverviewPage')],
	},
];
