import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Form } from '@socialbrothers/components/Containers';
import { useTheme } from '@socialbrothers/hooks';

import Props, { ResetPasswordFormValues } from './ResetPasswordForm.props';

const ResetPasswordForm = ({ initialValues, ...props }: Props) => {
	const { t } = useTranslation();
	const { authService, config } = useTheme();
	const location = useLocation();
	const [error, setError] = useState('');
	const history = useHistory();

	const validationSchema = Yup.object().shape({
		password: Yup.string()
			.required()
			.matches(config.passwordRegex, t('VALIDATION.COMMON.PASSWORD_NOT_STRONG')),
		password_confirmation: Yup.string()
			.required()
			.oneOf([Yup.ref('password')], t('VALIDATION.COMMON.PASSWORDS_DONT_MATCH')),
	});

	const params = new URLSearchParams(location.search);
	const token = params.get('token');
	const email = params.get('email');

	useEffect(() => {
		if (!token || !email) {
			history.replace('/');
		}
	}, [token, email, history]);

	const handleSubmit = async ({ password, password_confirmation }: ResetPasswordFormValues) => {
		if (email && token) {
			try {
				await authService.resetPassword(email, token, password, password_confirmation);
				toast.success(t('RESET_PASSWORD.RESET_SUCCESSFUL'));
				history.push('/');
			} catch (e) {
				setError(t('VALIDATION.COMMON.UNKNOWN_ERROR'));
			}
		}
	};

	return (
		<Form.Base
			submitLabel="Versturen"
			onSubmit={handleSubmit}
			validationSchema={validationSchema}
			error={error}
			{...props}>
			<Form.Layout.Field label={t('FORMS.COMMON_LABELS.NEW_PASSWORD')}>
				<Form.Input.Text type="password" name="password" placeholder="••••••••" />
			</Form.Layout.Field>

			<Form.Layout.Field label={t('FORMS.COMMON_LABELS.NEW_PASSWORD_VERIFY')}>
				<Form.Input.Text type="password" name="password_confirmation" placeholder="••••••••" />
			</Form.Layout.Field>
		</Form.Base>
	);
};

export default ResetPasswordForm;

// http://localhost:3000/reset-password?token=96c1adcd393cdf5d5080612944deefce7f07e0a75f08db733d263b0e13af2243&email=thom+admin@socialbrothers.nl
