import { useResource } from '@socialbrothers/hooks';

import { QuestionnaireConfiguratorProvider } from '@Hooks/index';
import { QuestionnaireService, QUESTIONNAIRE_TYPE } from '@Services/index';

import { SectionForm } from './internal/SectionForm/SectionForm';
import { SectionView } from './internal/SectionView/SectionView';

import { QuestionnaireConfiguratorProps } from './QuestionnaireConfigurator.props';

export const QuestionnaireConfigurator = ({ id }: QuestionnaireConfiguratorProps) => {
	const { data } = useResource(QuestionnaireService, id);

	if (data && data.type === QUESTIONNAIRE_TYPE.ONB) {
		data.type = QUESTIONNAIRE_TYPE.LNI;
	}

	return (
		<QuestionnaireConfiguratorProvider value={data}>
			{data && <SectionView id={id} sections={data.sections} />}
			<SectionForm id={id} />
		</QuestionnaireConfiguratorProvider>
	);
};
