import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Table } from '@socialbrothers/components/Containers';
import { OPERATORS } from '@socialbrothers/constants';

import { QuestionService, IQuestion, QUESTION_TYPE } from '@Services/index';

import styles from './QuestionTable.module.scss';
import { QuestionTableProps } from './QuestionTable.props';

export const QuestionTable = ({ className, module }: QuestionTableProps) => {
	const { t } = useTranslation();

	const filters = {
		module: [
			{
				operator: OPERATORS.IS,
				value: module,
			},
		],
	};

	return (
		<Table.Resource<IQuestion>
			defaultFilters={filters}
			service={QuestionService}
			className={cn(styles.QuestionTable, className)}>
			<Table.Field.Text sortable filterable source="title" label={t('QUESTION.LABELS.TITLE')} />

			<Table.Field.Enum
				sortable
				filterable
				enumeration={QUESTION_TYPE}
				name="QUESTION_TYPE"
				source="type"
				label={t('QUESTION.LABELS.TYPE')}
			/>

			<Table.Field.Date
				source="created_at"
				label={t('TABLE.COMMON.COLUMN.CREATED_AT')}
				width={1}
				filterable
				sortable
			/>

			<Table.Field.ResourceAction
				deleteConfig={(record: IQuestion) => ({
					name: record.title,
				})}
				editConfig={(record: IQuestion) => ({
					link: `/questions/${module.toLowerCase()}/${record.id}`,
				})}
			/>
		</Table.Resource>
	);
};
