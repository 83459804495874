import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { serialize } from 'object-to-formdata';

class BaseService {
	protected api: AxiosInstance;

	constructor(baseURL: string) {
		this.api = axios.create({
			baseURL,
		});
	}

	setAccessToken = (token: string) => {
		this.api.defaults.headers.Authorization = `Bearer ${token}`;
	};

	removeAccessToken = () => {
		delete this.api.defaults.headers.Authorization;
	};

	get = <T>(url: string, args?: Object) => {
		return this.api.get<T>(url, {
			params: args,
		});
	};

	post = <T>(url: string, args?: Object, options?: AxiosRequestConfig) => {
		return this.api.post<T>(url, this.format(args), {
			...options,
			headers: { 'Content-Type': 'multipart/form-data' },
		});
	};

	put = <T>(url: string, args?: Object, options?: AxiosRequestConfig) => {
		return this.api.put<T>(url, this.format(args), {
			...options,
			headers: { 'Content-Type': 'multipart/form-data' },
		});
	};

	putWithPost = <T>(url: string, args?: Object, options?: AxiosRequestConfig) => {
		return this.api.post<T>(url, this.format({ ...args, _method: 'PUT' }), {
			...options,
			headers: { 'Content-Type': 'multipart/form-data' },
		});
	};

	delete = <T>(url: string, args?: Object) => {
		return this.api.delete<T>(url, {
			data: args,
		});
	};

	private format = (args: any) => {
		return serialize(args, { booleansAsIntegers: true, nullsAsUndefineds: true });
	};
}

export default BaseService;
