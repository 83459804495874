export const serialize = (values: any) => {
	for (const [key, value] of Object.entries(values)) {
		const isFile = document.getElementById(key)?.outerHTML.includes('type="file"');

		if (isFile && value instanceof File === false) {
			delete values[key];
		}
	}

	return values;
};
