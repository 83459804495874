import { Account, AccountServiceInterface } from '@socialbrothers/constants';
import { BaseService } from '@socialbrothers/services';
import MenselyService from './MenselyService';

class AccountService implements AccountServiceInterface {
	client: BaseService;
	endpoint: string;

	constructor(client: BaseService, endpoint: string) {
		this.endpoint = endpoint;
		this.client = client;
	}

	me = async () => {
		const result = await this.client.get<Account>(`${this.endpoint}/me`);

		return result.data;
	};

	updateMe = async (data: Account) => {
		const result = await this.client.putWithPost<Account>(`${this.endpoint}/me`, data);

		return result.data;
	};
}

export default new AccountService(MenselyService, 'admin/users');
