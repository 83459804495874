import { useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import { Form } from '@socialbrothers/components/Containers';
import { enumToOptions, modelToOptions } from '@socialbrothers/utils';
import { Button } from '@socialbrothers/components/UI';

import {
	IQuestionnaireSection,
	IQuestionnaireSectionStep,
	IQuestionnaireSectionStepEffect,
	QuestionnaireSectionStepService,
	QuestionnaireService,
	QUESTIONNAIRE_SECTION_STEP_EFFECT,
} from '@Services/index';

import { useQuestionnaireConfigurator } from '@Hooks/index';

import styles from './EffectForm.module.scss';
import { EffectFormFormProps, EffectFormProps } from './EffectForm.props';

export const EffectForm = ({ id, step }: EffectFormProps) => {
	const { t } = useTranslation();
	const [type, setType] = useState<{ [id: string]: any }>({});
	const queryClient = useQueryClient();
	const questionnaire = useQuestionnaireConfigurator();

	const mutateUpdate = useMutation(
		(values: EffectFormFormProps) =>
			QuestionnaireSectionStepService.updateEffect(id, step.section.id, step.id, values),
		{
			onSuccess: () => {
				queryClient.invalidateQueries([QuestionnaireService.endpoint, id]);

				toast.success(
					t('GLOBAL.UPDATED_SUCCESSFUL', { name: t('QUESTIONNAIRE.CONFIGURATOR.EFFECT.SINGLE') }),
				);
			},

			onError: () => {
				toast.error(
					t('GLOBAL.UPDATED_UNSUCCESSFUL', { name: t('QUESTIONNAIRE.CONFIGURATOR.EFFECT.SINGLE') }),
				);
			},
		},
	);

	const getAnswer = (answerId: number) => {
		return step.question.answers.find((answer: any) => answer.id === answerId);
	};

	const getSectionsOptions = (sectionId: number) => {
		return questionnaire.sections.filter(
			(section: IQuestionnaireSection) => section.id !== sectionId,
		);
	};

	const getStepsOptions = (stepId: number) => {
		const section = questionnaire.sections.find(
			(section: IQuestionnaireSection) => section.id === step.questionnaire_section_id,
		);

		if (section) {
			return section.steps.filter((step: IQuestionnaireSectionStep) => step.id !== stepId);
		}

		return [];
	};

	return (
		<Form.Base
			initialValues={step}
			onSubmit={(values: EffectFormFormProps) => mutateUpdate.mutateAsync(values)}>
			{step.effects.map((effect: IQuestionnaireSectionStepEffect, index: number) => (
				<Form.Layout.Row key={effect.id} className={styles.Effect}>
					<div className={styles.Effect__Item}>
						{(effect.questionnaire_answer_id && getAnswer(effect.questionnaire_answer_id)?.text) ||
							step.question.title}
					</div>

					<Form.Layout.Field
						className={styles.Effect__Type}
						label={t('QUESTIONNAIRE.CONFIGURATOR.EFFECT.LABELS.TYPE')}
						onChange={(value: QUESTIONNAIRE_SECTION_STEP_EFFECT) => {
							const old = { ...type };

							if (old[effect.id] !== value) {
								old[effect.id] = value;
								setType(old);
							}
						}}>
						<Form.Input.Select
							name={`effects.${index}.type`}
							placeholder=""
							options={enumToOptions(
								QUESTIONNAIRE_SECTION_STEP_EFFECT,
								'QUESTIONNAIRE_SECTION_STEP_EFFECT',
							)}
						/>
					</Form.Layout.Field>

					{type[effect.id] === QUESTIONNAIRE_SECTION_STEP_EFFECT.SECTION && (
						<Form.Layout.Field
							className={styles.Effect__Section}
							label={t('QUESTIONNAIRE.CONFIGURATOR.EFFECT.LABELS.SECTION')}>
							<Form.Input.Select
								name={`effects.${index}.next_section_id`}
								placeholder={t('QUESTIONNAIRE.CONFIGURATOR.EFFECT.LABELS.SECTION_DEFAULT')}
								options={
									modelToOptions(getSectionsOptions(step.questionnaire_section_id), 'title') || []
								}
							/>
						</Form.Layout.Field>
					)}

					{type[effect.id] === QUESTIONNAIRE_SECTION_STEP_EFFECT.STEP && (
						<Form.Layout.Field
							className={styles.Effect__Step}
							label={t('QUESTIONNAIRE.CONFIGURATOR.EFFECT.LABELS.STEP')}>
							<Form.Input.Select
								name={`effects.${index}.next_step_id`}
								placeholder={t('QUESTIONNAIRE.CONFIGURATOR.EFFECT.LABELS.STEP_DEFAULT')}
								options={
									modelToOptions(getStepsOptions(effect.questionnaire_step_id), 'question.title') ||
									[]
								}
							/>
						</Form.Layout.Field>
					)}

					{type[effect.id] === QUESTIONNAIRE_SECTION_STEP_EFFECT.END && (
						<div className="d-flex flex-fill"></div>
					)}

					<Form.Input.Hidden name={`effects.${index}.id`} />
				</Form.Layout.Row>
			))}

			<Button type="submit" icon="save">
				{t('QUESTIONNAIRE.CONFIGURATOR.EFFECT.UPDATE')}
			</Button>
		</Form.Base>
	);
};
