import { types } from 'mobx-state-tree';

import { Storage } from '@socialbrothers/helpers';

const AuthStore = types
	.model('AuthStore', {
		isAuthenticated: Storage.getIsAccessTokenValid(),
	})
	.actions((self) => {
		const actions = {
			setIsAuthenticated: (value: boolean) => {
				self.isAuthenticated = value;
			},
		};

		return actions;
	});

export default AuthStore;
