import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import { COLOR } from '@socialbrothers/constants';
import { ButtonWithPopup, Form } from '@socialbrothers/components/Containers';

import { UserService, ImportService } from '@Services/index';

import { UserImportFormValues } from './UserImportForm.props';

export const UserImportForm = () => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();

	const mutateImport = useMutation(
		(values: UserImportFormValues) => {
			return ImportService.companyUsers(values);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(UserService.endpoint);
				toast.success(t('GLOBAL.IMPORTED_SUCCESSFUL', { name: t('USERS.LABELS_IMPORT.FILE') }));
			},

			onError: () => {
				toast.error(t('GLOBAL.IMPORTED_UNSUCCESSFUL', { name: t('USERS.LABELS_IMPORT.FILE') }));
			},
		},
	);

	const validationSchema = Yup.object().shape({
		file: Yup.mixed().required(),
	});

	return (
		<ButtonWithPopup
			withForm
			validationSchema={validationSchema}
			button={{
				color: COLOR.SECONDARY,
				icon: 'file-import',
				label: t('USERS.IMPORT'),
			}}
			submit={{
				label: t('USERS.IMPORT'),
				onClick: (values: UserImportFormValues) => {
					return mutateImport.mutateAsync(values);
				},
			}}
			popup={{
				title: t('USERS.IMPORT'),
			}}>
			<Form.Layout.Field
				label={t('USERS.LABELS_IMPORT.FILE')}
				description={t('USERS.LABELS_IMPORT.FILE_DESCRIPTION')}>
				<Form.Input.File name="file" accept=".csv" thumbnail={false} />
			</Form.Layout.Field>
		</ButtonWithPopup>
	);
};
