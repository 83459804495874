import { AuthStore } from '@socialbrothers/stores';
import { flow, Instance, types } from 'mobx-state-tree';

import ThemeStore from './Theme/ThemeStore';

const RootStoreModel = types
	.model('RootStoreModel', {
		ThemeStore: ThemeStore,
		AuthStore: AuthStore,
	})
	.actions((self) => ({
		load: flow(function* () {}),
	}));

const RootStore = RootStoreModel.create({
	ThemeStore: {
		Sidebar: {},
	},
	AuthStore: {},
});

export interface RootStoreInstance extends Instance<typeof RootStoreModel> {}
export default RootStore;
