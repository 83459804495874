import { useTranslation } from 'react-i18next';

import { Page } from '@socialbrothers/components/Layout';
import { Button, Card } from '@socialbrothers/components/UI';
import { COLOR, Filter, OPERATORS } from '@socialbrothers/constants';

import { CourseTable } from '@Components/tables';
import { COURSE_TYPE, ICourse } from '@Services/index';

export const ChallengeOverviewPage = () => {
	const { t } = useTranslation();

	const buttons = (
		<Button icon="plus" to="/challenges/create" color={COLOR.SECONDARY}>
			{t('COURSE.CHALLENGE.CREATE')}
		</Button>
	);

	const courseFilters: Filter<ICourse> = {
		type: [
			{
				operator: OPERATORS.IS,
				value: COURSE_TYPE.CHALLENGE,
			},
		],
	};

	return (
		<Page title={t('COURSE.CHALLENGE.PLURAL')} buttons={buttons}>
			<Card title={t('COURSE.CHALLENGE.PLURAL')}>
				<CourseTable type={COURSE_TYPE.CHALLENGE} defaultFilters={courseFilters} />
			</Card>
		</Page>
	);
};
